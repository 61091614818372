import { combineReducers } from 'redux';
import {
    SHOW_LOADER,
    STOP_LOADER
} from '../actions/homeAction';

const showLoader = (state = false, action) => {
    switch (action.type) {
        case SHOW_LOADER:
            return action.payload;
        case STOP_LOADER:
            return false;
        default:
            return state;
    }
}

export default combineReducers({
    showLoader
});