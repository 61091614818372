import { React } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import spinloader from '../../assets/images/loader.gif';

const Loader = () => {
    const showLoader = useSelector(state => get(state, 'home.showLoader', false));
    return (
        showLoader ?
            <div className="loader-container">
                <img className="loader-gif" src={spinloader} alt="loader" />
            </div> : null

    );
}

export default Loader;