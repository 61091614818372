import { combineReducers } from 'redux';
import {
    LOGIN_USER_REQUEST,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGOUT_USER
} from '../actions/authAction';

const initialState = {
    loading: false,
    user: {},
    error: ""
}

const userDetail = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER_REQUEST:
            return {
                ...state, loading: true
            }
        case LOGIN_USER_SUCCESS:
            return {
                ...state, user: action.payload, loading: false
            }
        case LOGIN_USER_FAILURE:
            return {
                ...state, error: action.payload, loading: false
            }
        case LOGOUT_USER:
            return { ...initialState }
        default:
            return state
    }
}

export default combineReducers({
    userDetail
});