import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/tours")
        navigate("/signin")
    }, []);

    return (
        <h1>
            Not Found
        </h1>
    );
}

export default NotFound;