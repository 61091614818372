import axios from 'axios';
import { getToken, doLogout } from '../redux/actions/authAction';
import { BASE_URL } from './globalConfig';
import { get } from 'lodash';
export const axiosServices = axios.create({ baseURL: BASE_URL });

export const httpRequest = ({ ...options }) => {
    const token = getToken();
    if (token) {
        axiosServices.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
    const onSuccess = (response) => response;
    const onError = error => {
        if (get(error, 'response.status', "") === 401 || get(error, 'code', "") == 'ERR_NETWORK' || get(error, 'code', "") == 'ERR_BAD_RESPONSE') {
            localStorage.clear();
            doLogout();
            window.location.href = "/signin";
        }
        return error;
    }

    return axiosServices(options).then(onSuccess).catch(onError);
}