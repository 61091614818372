import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { showLoader } from '../redux/actions/homeAction';
import { services } from '../utils/services';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import defaultimg from '../assets/images/default.jpg';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router-dom';
import { LIVE_IMAGE_URL } from '../utils/globalConfig';
import { useNavigate } from 'react-router-dom';

const MySwal = withReactContent(Swal)

const Tour = () => {
    const navigate = useNavigate();
    const [exteriors, setExteriors] = useState([]);
    const [levels, setLevels] = useState([]);
    const [levelareas, setLevelAreas] = useState([]);
    const [error, setError] = useState({});
    const [levelerror, setLevelError] = useState({});
    const [tourerror, setTourError] = useState({});
    const [tour, setTour] = useState({});
    const [addnewexterior, setAddNewExterior] = useState(false);
    const [addnewlevel, setAddNewLevel] = useState(false);
    const [addnewlevelarea, setAddNewLevelArea] = useState(false);
    const [shareTour, setShareTour] = useState(false);
    const [showlevels, setShowLevels] = useState(false);
    const [showedittourdetails, setShowEditTourDetails] = useState(false);
    const [areaname, setAreaName] = useState("");
    const [tourlevel, setTourLevel] = useState(0);
    const [selecttourlevel, setSelectTourLevel] = useState(0);
    const [selecttourlevelid, setSelectTourLevelID] = useState(0);
    const [areaimage, setAreaImage] = useState("");
    const [levelareaname, setLevelAreaName] = useState("");
    const [levelareaimage, setLevelAreaImage] = useState("");
    const [touraddress, setTourAddress] = useState("");
    const [tourpropsize, setTourPropSize] = useState("");
    const [tourpurchasetype, setTourPurchaseType] = useState("none");
    const [tourprice, setTourPrice] = useState("");
    const [addExteriorText, setAddExteriorText] = useState("Add New Exterior Area");
    const [exteriorID, setExteriorID] = useState(0);
    const [addLevelText, setAddLevelText] = useState("Add");
    const [levelID, setLevelID] = useState(0);
    const [addLAreaText, setAddLAreaText] = useState("Add New Level Area");
    const [levelAreaID, setLevelAreaID] = useState(0);
    const dataFetchedRef = useRef(false);
    const dispatch = useDispatch();
    const params = useParams();
    const tourlink = window.location.origin + '/tourview/' + params.slug;
    const tourlink1 = window.location.origin + '/tourveditor/' + params.slug;
    const getTour = async () => {
        dispatch(showLoader(true))
        const response = await services.getTour(params.slug);
        if (response) {
            setTour(response)
        }
        dispatch(showLoader(false))
    }
    const updateTour = async () => {
        dispatch(showLoader(true))
        const tourForm = {
            id: tour.id,
            propertyName: tour.propertyName,
            propertyAddress: touraddress,
            propertySize: tourpropsize == "" ? 0 : tourpropsize,
            purchaseType: tourpurchasetype == "none" ? "" : tourpurchasetype,
            price: tourprice == "" ? 0 : tourprice,
        }
        const response = await services.updateTour(tourForm);
        if (response) {
            setTour(response)
            setShowEditTourDetails(false)
        }
        dispatch(showLoader(false))
    }
    const getExteriors = async () => {
        dispatch(showLoader(true))
        const response = await services.getExteriors(params.slug);
        if (response) {
            setExteriors(response)
        }
        dispatch(showLoader(false))
    }
    const getLevels = async () => {
        dispatch(showLoader(true))
        const response = await services.getLevels(params.slug);
        if (response) {
            setLevels(response)
        }
        dispatch(showLoader(false))
    }
    const getLevelAreas = async (id) => {
        dispatch(showLoader(true))
        const response = await services.getLevelAreas(id);
        if (response) {
            setLevelAreas(response)
        }
        dispatch(showLoader(false))
    }
    const saveExterior = async () => {
        dispatch(showLoader(true))
        const exteriorForm = {
            areaName: areaname,
            areaImage: areaimage,
            tourID: parseInt(params.slug),
            iD: exteriorID
        }
        const response = await services.addExterior(exteriorForm);
        if (response) {
            setAddNewExterior(false)
            setAreaName('')
            setAreaImage('')
            dispatch(showLoader(false))
            getExteriors()
        }
    }
    const saveLevelArea = async () => {
        dispatch(showLoader(true))
        const levelAreaForm = {
            areaName: levelareaname,
            areaImage: levelareaimage,
            levelID: selecttourlevelid,
            tourID: parseInt(params.slug),
            iD: levelAreaID
        }
        const response = await services.addLevelArea(levelAreaForm);
        if (response) {
            getLevelAreas(selecttourlevelid)
            setAddNewLevelArea(false)
            setLevelAreaName('')
            setLevelAreaImage('')
            dispatch(showLoader(false))
        }
    }
    const saveLevel = async () => {
        dispatch(showLoader(true))
        const levelForm = {
            levelName: tourlevel,
            tourID: parseInt(params.slug),
            iD: levelID
        }
        const response = await services.addLevel(levelForm);
        if (response) {
            setAddNewLevel(false)
            dispatch(showLoader(false))
            getLevels()
        }
    }
    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getTour()
        getLevels()
        getExteriors()
    }, [])
    const addExterior = () => {
        setAddNewExterior(true)
        setAreaName("")
        setAddExteriorText("Add New Exterior Area")
        setAreaImage("");
        setExteriorID(0)
    }
    const addLevel = () => {
        setTourLevel(levels.length)
        setAddNewLevel(true)
        setAddLevelText("Add")
        setLevelID(0)
    }
    const addLevelArea = () => {
        setAddNewLevelArea(true)
        setLevelAreaName("")
        setAddLAreaText("Add New Level Area")
        setLevelAreaImage("");
        setLevelAreaID(0)
    }
    const onHideLevel = () => {
        setAddNewLevel(false)
    }
    const edit360 = () => {
        window.open(tourlink1, '_blank');
    }
    const viewin360 = (event) => {
        if (event.target.className.indexOf('st-pencil-trash') < 0)
            window.open(tourlink, '_blank');
    }
    const onHide = () => {
        setAddNewExterior(false)
        getExteriors()
    }
    const onHideLArea = () => {
        setAddNewLevelArea(false)
        getLevelAreas(selecttourlevelid)
    }
    const viewShareTour = () => {
        setShareTour(true)
    }
    const onHideShareTour = () => {
        setShareTour(false)
    }
    const validateForm = () => {
        const _error = {};

        if (isEmpty(areaname)) {
            _error["areaname"] = "This field is required";
        }
        if (isEmpty(areaimage)) {
            _error["areaimage"] = "This field is required";
        }

        setError(_error);
        if (!isEmpty(_error)) {
            return false;
        }
        return true;
    }
    const onSubmitHandler = () => {
        if (validateForm()) {
            saveExterior()
        }
    }
    const onSubmitHandlerLevel = () => {
        saveLevel()
    }
    const onChangeImage = (e, name) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            let inputData = reader.result;
            let replaceValue = inputData.split(",")[0];
            let base64String = inputData.replace(replaceValue + ".", "");
            setAreaImage(base64String);
        }
    }
    const onChangeImageLevel = (e, name) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            let inputData = reader.result;
            let replaceValue = inputData.split(",")[0];
            let base64String = inputData.replace(replaceValue + ".", "");
            setLevelAreaImage(base64String);
        }
    }
    const addtoclipboard = () => {
        navigator.clipboard.writeText(tourlink)
        setShareTour(false)
    }
    const viewlevels = (event, level) => {
        if (event.target.className.indexOf('st-pencil-trash') < 0) {
            getLevelAreas(level.id)
            setShowLevels(true)
            setSelectTourLevel(level.levelName)
            setSelectTourLevelID(level.id)
        }
    }
    const validateLevelForm = () => {
        const _error = {};

        if (isEmpty(levelareaname)) {
            _error["levelareaname"] = "This field is required";
        }
        if (isEmpty(levelareaimage)) {
            _error["levelareaimage"] = "This field is required";
        }

        setLevelError(_error);
        if (!isEmpty(_error)) {
            return false;
        }
        return true;
    }
    const onSubmitHandlerLArea = () => {
        if (validateLevelForm()) {
            saveLevelArea()
        }
    }
    const clickshowlevels = () => {
        setShowLevels(false)
        setLevelAreas([])
    }
    const validateUpdateTourForm = () => {
        const _error = {};

        if (isEmpty(touraddress)) {
            _error["touraddress"] = "This field is required";
        }
        if (isEmpty(tourpropsize)) {
            _error["tourpropsize"] = "This field is required";
        }
        if (!isEmpty(tourpropsize) && isNaN(tourpropsize)) {
            _error["tourpropsize"] = "Numeric value is required.";
        }
        if (tourpurchasetype == 'none') {
            _error["tourpurchasetype"] = "This field is required";
        }
        if (isEmpty(tourprice)) {
            _error["tourprice"] = "This field is required";
        }
        if (!isEmpty(tourprice) && isNaN(tourprice)) {
            _error["tourprice"] = "Numeric value is required.";
        }

        setTourError(_error);
        if (!isEmpty(_error)) {
            return false;
        }
        return true;
    }
    const edittour = () => {
        setShowEditTourDetails(true)
        setTourAddress(tour.propertyAddress ? tour.propertyAddress : '')
        setTourPropSize(tour.propertySize.toString())
        setTourPurchaseType(tour.purchaseType ? tour.purchaseType : 'none')
        setTourPrice(tour.price.toString())
    }
    const onHideEditTourDetails = () => {
        setShowEditTourDetails(false)
    }
    const onSubmitUpdateTourHandler = () => {
        updateTour()
    }
    const editExterior = (exterior) => {
        setAddNewExterior(true)
        setAreaName(exterior.areaName)
        setAddExteriorText("Update Exterior Area")
        setAreaImage(exterior.areaImage);
        setExteriorID(exterior.id)
    }
    const deleteExterior = (exterior) => {
        Swal.fire({
            title: 'Are you sure?',
            showCancelButton: true,
            confirmButtonColor: 'red',
            confirmButtonText: 'Delete',
            width: 300,
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await services.deleteExterior(exterior.id);
                if (response) {
                    dispatch(showLoader(false))
                    getExteriors()
                }
            }
        })
    }
    const editLevel = (level) => {
        setAddNewLevel(true)
        setTourLevel(level.levelName)
        setAddLevelText("Update")
        setLevelID(level.id)
    }
    const deleteLevel = (level) => {
        Swal.fire({
            title: 'Are you sure?',
            showCancelButton: true,
            confirmButtonColor: 'red',
            confirmButtonText: 'Delete',
            width: 300,
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await services.deleteLevel(level.id);
                if (response) {
                    dispatch(showLoader(false))
                    getLevels()
                }
            }
        })
    }
    const editLArea = (levelarea) => {
        setAddNewLevelArea(true)
        setLevelAreaName(levelarea.areaName)
        setAddLAreaText("Update")
        setLevelAreaImage(levelarea.areaImage);
        setLevelAreaID(levelarea.id)
    }
    const deleteLArea = (levelarea) => {
        Swal.fire({
            title: 'Are you sure?',
            showCancelButton: true,
            confirmButtonColor: 'red',
            confirmButtonText: 'Delete',
            width: 300,
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await services.deleteLevelArea(levelarea.id);
                if (response) {
                    dispatch(showLoader(false))
                    getLevels()
                    getLevelAreas(levelarea.levelID)
                }
            }
        })
    }
    return (
        <>
            <div className="page-content custom_1">
                <div className="container">
                    <div className="row custom_2_row ">
                        {showlevels ?
                            <div className="col-md-8 col-sm-12">

                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                            <h4 className="mb-sm-0 font-size-18"><span style={{ cursor: 'pointer', 'color': '#272727' }} onClick={() => clickshowlevels()}>Level {selecttourlevel}</span> > Areas</h4>

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {
                                        !isEmpty(levelareas) &&
                                        levelareas.map((levelarea, index) => {
                                            const _imageUrl = get(levelarea, 'areaImage') ? `${LIVE_IMAGE_URL}${get(levelarea, 'areaImage')}` : defaultimg;
                                            return <div key={get(levelarea, 'id')} className="custom_img_3" style={{
                                                backgroundImage: 'url("' + _imageUrl.replace('.png', 'thumb.png').replace('.jpeg', 'thumb.jpeg') + '")',
                                            }} onClick={(event) => viewin360(event)}>
                                                <span className="custom_img_border_span_3">{get(levelarea, 'areaName')}<i className="st-trash st-pencil-trash" aria-hidden="true" onClick={() => deleteLArea(levelarea)}></i><i className="st-pencil st-pencil-trash" aria-hidden="true" onClick={() => editLArea(levelarea)}></i></span>
                                            </div>
                                        })
                                    }

                                    <div className="custom_img_border_3_b" onClick={() => addLevelArea()}>
                                        <div className="custom_img_border_span_3_1"><p className="font_30">+</p><div>Add New Area</div></div>
                                    </div>
                                </div>
                            </div> :
                            <div className="col-md-8 col-sm-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                            <h4 className="mb-sm-0 font-size-18">Levels</h4>

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {
                                        !isEmpty(levels) &&
                                        levels.map((level, index) => {
                                            const _imageUrl = get(level, 'levelImage') ? `${LIVE_IMAGE_URL}${get(level, 'levelImage')}` : defaultimg;
                                            return <div key={get(level, 'id')} className="custom_img_2" style={{
                                                backgroundImage: 'url("' + _imageUrl.replace('.png', 'thumb.png').replace('.jpeg', 'thumb.jpeg') + '")',
                                            }} onClick={(event) => viewlevels(event, level)}>
                                                <span className="custom_img_border_span_3">Level {get(level, 'levelName')}<i className="st-trash st-pencil-trash" aria-hidden="true" onClick={() => deleteLevel(level)}></i><i className="st-pencil st-pencil-trash" aria-hidden="true" onClick={() => editLevel(level)}></i></span>
                                            </div>
                                        })
                                    }

                                    <div className="custom_img_border_1" onClick={() => addLevel()}>
                                        <span className="custom_img_border_span_1">Add new Level</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                            <h4 className="mb-sm-0 font-size-18">Exteriors</h4>

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {
                                        !isEmpty(exteriors) &&
                                        exteriors.map((exterior, index) => {
                                            const _imageUrl = get(exterior, 'areaImage') ? `${LIVE_IMAGE_URL}${get(exterior, 'areaImage')}` : defaultimg;
                                            return <div key={get(exterior, 'id')} className="custom_img_border_3" style={{
                                                backgroundImage: 'url("' + _imageUrl.replace('.png', 'thumb.png').replace('.jpeg', 'thumb.jpeg') + '")',
                                            }} onClick={(event) => viewin360(event)}>
                                                <span className="custom_img_border_span_3">{get(exterior, 'areaName')}<i className="st-trash st-pencil-trash" aria-hidden="true" onClick={() => deleteExterior(exterior)}></i><i className="st-pencil st-pencil-trash" aria-hidden="true" onClick={() => editExterior(exterior)}></i></span>
                                            </div>
                                        })
                                    }
                                    <div className="custom_img_border_3_b" onClick={() => addExterior()}>
                                        <div className="custom_img_border_span_3_1"><p className="font_30">+</p><div>Add New Exterior Area</div></div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="col-md-4 col-sm-12">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                            <div><h4 className="mb-sm-0 font-size-18">Tour Details</h4></div>
                                            <div><i className="st-pencil" aria-hidden="true" onClick={() => edittour()}></i></div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-12 bx_12">
                                {get(tour, 'propertyAddress') && <p className=""><i className="st-pin"></i> {get(tour, 'propertyAddress')}</p>}
                                {get(tour, 'propertySize') > 0 && <p className=""><i className="st-resize"></i> {get(tour, 'propertySize') + ' Sq Ft'}</p>}
                                {get(tour, 'purchaseType') && <p className=""><i className="st-flats"></i> {get(tour, 'purchaseType')}</p>}
                                {get(tour, 'price') > 0 && <p className=""><i className="st-cash"></i> {'USD ' + get(tour, 'price')}</p>}
                            </div>

                            <button onClick={() => edit360()} className="btn btn-primary  btn-block  btn-rounded mb-3">Edit Navigation</button>
                            <button onClick={(event) => viewin360(event)} className="btn btn-primary  btn-block  btn-rounded mb-3">View in 360</button>
                            <button onClick={() => viewShareTour()} className="btn mb-4 btn-outline-primary waves-effect waves-light btn-rounded btn-block">Share Tour</button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={addnewexterior} onHide={onHide}>
                <Modal.Body>
                    <h5 className="modal-title">{addExteriorText}</h5>
                    <div className="mdl-body">
                        <input type="text" maxLength="100" className="form-control rounded-bordered mb-4" placeholder="Area Name" value={areaname}
                            onChange={(e) => setAreaName(e.target.value)} />
                        {
                            get(error, 'areaname') &&
                            <span className='requiredMsg'>
                                {get(error, 'areaname')}
                            </span>

                        }
                        <div className="input-group custom-file-button  mb-3">

                            <input type="file" className="form-control rounded-bordered mb-4" type="file" accept="image/png, image/gif, image/jpeg" placeholder="Select Image"
                                onChange={e => onChangeImage(e, "picture")} />
                            {
                                get(error, 'areaimage') &&
                                <span className='requiredMsg'>
                                    {get(error, 'areaimage')}
                                </span>

                            }
                        </div>
                        <button onClick={() => onSubmitHandler()} className="btn btn-primary btn-rounded btn-block">{addExteriorText}</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={shareTour} onHide={onHideShareTour}>
                <Modal.Body>
                    <h5 className="modal-title">Share Tour</h5>
                    <div className="mdl-body">
                        <span className="tv-share">{tourlink} <i className="fa fa-clone"
                            style={{ 'float': 'right', 'cursor': 'pointer' }} aria-hidden="true" onClick={() => addtoclipboard()}></i></span>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={addnewlevel} onHide={onHideLevel}>
                <Modal.Body>
                    <h5 className="modal-title">Add a New Level</h5>
                    <div className="mdl-body">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div><button onClick={() => setTourLevel(tourlevel - 1)} className="btn btn-light btn_add_level"><strong>-</strong></button></div>
                            <div>Level {tourlevel}</div>
                            <div><button onClick={() => setTourLevel(tourlevel + 1)} className="btn btn-light btn_add_level"><strong>+</strong></button></div>
                        </div>
                        <br />
                        <button onClick={() => onSubmitHandlerLevel()} className="btn btn-primary btn-rounded btn-block">{addLevelText}</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={addnewlevelarea} onHide={onHideLArea}>
                <Modal.Body>
                    <h5 className="modal-title">Add a New Area</h5>
                    <div className="mdl-body">
                        <input type="text" maxLength="100" className="form-control rounded-bordered mb-4" placeholder="Area Name" value={levelareaname}
                            onChange={(e) => setLevelAreaName(e.target.value)} />
                        {
                            get(levelerror, 'levelareaname') &&
                            <span className='requiredMsg'>
                                {get(levelerror, 'levelareaname')}
                            </span>

                        }
                        <div className="input-group custom-file-button">
                            <input type="file" className="form-control rounded-bordered mb-4" accept="image/png, image/gif, image/jpeg" placeholder="Select Image"
                                onChange={e => onChangeImageLevel(e, "picture")} />
                            {
                                get(levelerror, 'levelareaimage') &&
                                <span className='requiredMsg'>
                                    {get(levelerror, 'levelareaimage')}
                                </span>

                            }
                        </div>
                        <button onClick={() => onSubmitHandlerLArea()} className="btn btn-primary btn-rounded btn-block">{addLAreaText}</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showedittourdetails} onHide={onHideEditTourDetails}>
                <Modal.Body>
                    <h5 className="modal-title">Edit Tour Details</h5>
                    <div className="mdl-body">
                        <input type="text" maxLength="100" className="form-control rounded-bordered mb-4" placeholder="Address" value={touraddress}
                            onChange={(e) => setTourAddress(e.target.value)} />
                        {
                            get(tourerror, 'touraddress') &&
                            <span className='requiredMsg'>
                                {get(tourerror, 'touraddress')}
                            </span>
                        }
                        <input type="text" maxLength="100" className="form-control rounded-bordered mb-4" placeholder="Property Size(in Sq. Feet)" value={tourpropsize}
                            onChange={(e) => setTourPropSize(e.target.value)} />
                        {
                            get(tourerror, 'tourpropsize') &&
                            <span className='requiredMsg'>
                                {get(tourerror, 'tourpropsize')}
                            </span>
                        }
                        <select className="form-control rounded-bordered mb-4 slttour" value={tourpurchasetype} onChange={(e) => setTourPurchaseType(e.target.value)}>
                            <option value="none">Purchase Type</option>
                            <option value="Rent">For Rent</option>
                            <option value="Sale">For Sale</option>
                        </select>
                        {
                            get(tourerror, 'tourpurchasetype') &&
                            <span className='requiredMsg'>
                                {get(tourerror, 'tourpurchasetype')}
                            </span>
                        }
                        <input type="text" maxLength="100" className="form-control rounded-bordered mb-4" placeholder="Price(in USD)" value={tourprice}
                            onChange={(e) => setTourPrice(e.target.value)} />
                        {
                            get(tourerror, 'tourprice') &&
                            <span className='requiredMsg'>
                                {get(tourerror, 'tourprice')}
                            </span>
                        }
                        <button onClick={() => onSubmitUpdateTourHandler()} className="btn btn-primary btn-rounded btn-block">Update</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Tour;