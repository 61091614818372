import React from 'react';

const Services = () => {
    return (
        <div className="container mt5">
            <h1>Our Services</h1>
            <br />
            <h3>Capture service</h3>
            <br />
            <p>With our state-of-the-art technology and skilled professionals, we offer a comprehensive suite of services designed to captivate your audience and bring your vision to life. From capturing stunning 360-degree images for your virtual tours to taking breathtaking drone videos that provide a unique perspective, we're here to transform your ideas into reality. Our expertise also extends to crafting engaging 360-degree tours from the images we capture, ensuring that your audience is fully immersed in your space, whether it's a real estate property, a tourist attraction, or an event venue. Let us be your partner in capturing the essence of your project and sharing it with the world.</p>
            <br />
            <br />
            <h3>Software development</h3>
            <br />
            <p>We specialize in tailoring solutions that align seamlessly with your business objectives and identity. Our expertise encompasses creating custom versions of our software to reflect your unique brand identity, ensuring that your digital presence resonates with your audience. We also excel in seamlessly integrating our software into your website, providing a seamless and intuitive user experience. Moreover, we offer white-labeling services, allowing you to take full ownership of the software under your brand, enhancing your brand recognition. Our dedication to customization means that we are committed to meeting your specific needs, adapting and enhancing our software with the features and functionalities you desire. We're here to empower your business with the software solutions that not only meet but exceed your expectations.</p>
            <br />
            <br />
            <h3>Virtual Reality</h3>
            <br />
            <p>Step into the future of property exploration with our Virtual Reality 360 Tours. Our platform makes it effortless to create, view, and share 360 tours, compatible with VR headsets for a fully immersive experience. Move seamlessly through properties, exploring every detail as if you were there in person. Share your tours with ease, and experience properties from the inside with a level of realism previously only possible through physical visits. Immerse yourself in the world of 360 tours and elevate your property viewing experience to new heights.</p>
            <br />
            <br />
            <br />
        </div>
    );
}

export default Services;