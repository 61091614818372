import React, { useState, useEffect, useRef } from 'react';
import { showLoader } from '../redux/actions/homeAction';
import { useDispatch } from 'react-redux';
import { services } from '../utils/services';
import { useParams } from 'react-router-dom';
import { LIVE_IMAGE_URL } from '../utils/globalConfig';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Modal from 'react-bootstrap/Modal';
import logolight from '../assets/images/logo-light.png';

const TourView = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const dataFetchedRef = useRef(false);
    const [propname, setPropName] = useState("");
    const [viewlist, setViewList] = useState([]);
    const [exteriorslist, setExteriorsList] = useState([]);
    const [levelslist, setLevelsList] = useState([]);
    const [vivetypeexterior, setViveTypeExterior] = useState(true);
    const [levelname, setLevelName] = useState(0);
    const [viewInfoDetail, setViewInfoDetail] = useState(false);
    const [tour, setTour] = useState({});
    const [isGetHotSpots, setisGetHotSpots] = useState(false);
    const [hotspotsimages, setHotSpotsImages] = useState({});
    const [selectedtouramenities, setSelectedTourAmenities] = useState([]);

    if (!isGetHotSpots) {
        services.getHotSpots(parseInt(params.slug)).then(result => {
            if (result != undefined) {
                var images = JSON.parse(result.hotspotImages);
                setHotSpotsImages(images);
            }
            setisGetHotSpots(true);
        });
    }

    useEffect(() => {
        if (dataFetchedRef.current || !isGetHotSpots) return;
        dataFetchedRef.current = true;
        //https://pannellum.org/documentation/examples/tour/

        var panoramadiv = document.getElementById("panorama");
        panoramadiv.style.height = window.innerHeight + "px";

        document.body.style.overflow = 'hidden';

        const link = document.createElement("link")
        link.type = 'text/css'
        link.rel = "stylesheet";
        link.href = "https://cdn.jsdelivr.net/npm/pannellum@2.5.6/build/pannellum.css"
        document.head.appendChild(link)
        const script1 = document.createElement("script")
        script1.type = 'text/javascript'
        script1.src = "https://cdn.jsdelivr.net/npm/pannellum@2.5.6/build/pannellum.js"
        script1.async = false
        script1.onload = function () {
            setTimeout(() => {
                dispatch(showLoader(true))
                services.getTour(parseInt(params.slug)).then(result => {
                    dispatch(showLoader(false))
                    setPropName(get(result, 'propertyName'))
                    setTour(result)
                    if (result.amenities != undefined)
                        setSelectedTourAmenities(JSON.parse(result.amenities))
                    if (result.id > 0) {
                        if (result.exteriors?.length > 0) {
                            setExteriorsList(result.exteriors)
                            setViewList(result.exteriors)
                            setViveTypeExterior(true)
                            if (result.levels?.length > 0) {
                                var resultlevels = result.levels.filter(function (el) {
                                    return el.levelAreas?.length > 0;
                                })
                                resultlevels.sort(function (a, b) {
                                    if (a.levelName < b.levelName) return -1;
                                    if (a.levelName > b.levelName) return 1;
                                    return 0;
                                }).reverse()
                                if (resultlevels.length > 0)
                                    setLevelName(resultlevels[0].levelName)
                                setLevelsList(resultlevels)
                            }
                            selectElement(result.exteriors[0].id)
                            OpenPanorama(get(result.exteriors[0], 'id'), result.exteriors, 110, -3, 117, true)
                        }
                        else if (result.levels?.length > 0) {
                            setViveTypeExterior(false)
                            var resultlevels = result.levels.filter(function (el) {
                                return el.levelAreas?.length > 0;
                            })
                            resultlevels.sort(function (a, b) {
                                if (a.levelName < b.levelName) return -1;
                                if (a.levelName > b.levelName) return 1;
                                return 0;
                            }).reverse()
                            setLevelsList(resultlevels)
                            changevivelevel(resultlevels[0])
                            setLevelName(resultlevels[0].levelName)
                        }
                    }
                })
            }, 500);
        }
        document.head.appendChild(script1)
        setTimeout(() => { if (typeof panoramaviewer === 'undefined' && exteriorslist.length > 0) { window.location.reload(); } }, 2000);
    }, [hotspotsimages, isGetHotSpots])
    const changeview = (view) => {
        selectElement(get(view, 'id'));
        window.panoramaviewer.loadScene(get(view, 'id'), -3, 117, 110)
    }
    const changevivetype = () => {
        if (vivetypeexterior) {
            setViveTypeExterior(false)
            if (levelslist != undefined && levelslist.length > 0) {
                setViewList(levelslist[levelslist.length - 1].levelAreas)
                setLevelName(levelslist[levelslist.length - 1].levelName)
                selectElement(get(levelslist[levelslist.length - 1].levelAreas[0], 'id'))
                OpenPanorama(get(levelslist[levelslist.length - 1].levelAreas[0], 'id'), levelslist[levelslist.length - 1].levelAreas, 110, -3, 117, false)
            }
        }
        else {
            setViveTypeExterior(true)
            setViewList(exteriorslist)
            selectElement(get(exteriorslist[0], 'id'))
            OpenPanorama(get(exteriorslist[0], 'id'), exteriorslist, 110, -3, 117, true)
        }
    }
    const changevivelevel = (level) => {
        if (level.levelAreas?.length > 0) {
            setViewList(level.levelAreas)
            setLevelName(level.levelName)
            selectElement(get(level.levelAreas[0], 'id'))
            OpenPanorama(get(level.levelAreas[0], 'id'), level.levelAreas, 110, -3, 117, vivetypeexterior)
        }
    }
    const viewInfo = () => {
        setViewInfoDetail(true)
    }
    const hideViewInfo = () => {
        setViewInfoDetail(false)
    }
    const selectElement = (id) => {
        setTimeout(() => {
            var elements = document.getElementsByClassName("custom_img_v");
            for (let i = 0; i < elements.length; i++) {
                elements[i].setAttribute("class", "custom_img_v");
            }
            if (document.getElementById("sv" + id) != undefined)
                document.getElementById("sv" + id).setAttribute("class", "custom_img_v active");
        }, 500)

    }
    const gethotSpots = (id, isexterior) => {
        var result = [];
        if (hotspotsimages[id] != null) {
            hotspotsimages[id].map((item, index) => {
                if (item.vivetypeexterior == undefined || isexterior == item.vivetypeexterior) {
                    result.push({
                        "pitch": item.pitch,
                        "yaw": item.yaw,
                        "type": "scene",
                        "text": item.view.areaName,
                        "sceneId": item.view.id,
                        "cssClass": "pnlm-hotspot-base custom-hotspot htslider" + item.slider,
                        "createTooltipFunc": hotspotfunc,
                        "createTooltipArgs": item.view.areaName + '|' + item.pitch + '|' + item.yaw + '|' + id + '|' + item.view.id + '|' + item.view.levelID + '|' + item.destrotpitch + '|' + item.destrotyaw + '|' + item.destrotHof
                    })
                }
            })
        }
        return result;
    }
    const hotspotfunc = (hotSpotDiv, args) => {
        hotSpotDiv.classList.add('custom-tooltip');
        hotSpotDiv.setAttribute('sceneid', args.split('|')[4]);
        if (args.split('|')[5] != 'undefined' && args.split('|')[5] != undefined)
            hotSpotDiv.setAttribute('levelid', args.split('|')[5]);
        else
            hotSpotDiv.setAttribute('levelid', '-1000');

        if (args.split('|')[6] != 'undefined' && args.split('|')[6] != undefined)
            hotSpotDiv.setAttribute('destrotpitch', args.split('|')[6]);

        if (args.split('|')[7] != 'undefined' && args.split('|')[7] != undefined)
            hotSpotDiv.setAttribute('destrotyaw', args.split('|')[7]);

        if (args.split('|')[8] != 'undefined' && args.split('|')[8] != undefined)
            hotSpotDiv.setAttribute('destrotHof', args.split('|')[8]);

        var span = document.createElement('span');
        span.innerHTML = args.split('|')[0];
        hotSpotDiv.appendChild(span);
        span.style.width = span.scrollWidth - 20 + 'px';
        span.style.marginLeft = -(span.scrollWidth + 35) / 2 + 'px';
        span.style.marginTop = -span.scrollHeight - 12 + 'px';
    }
    const toggleamenities = () => {
        let collapseOnediv = document.getElementById('general-collapseOne');
        if (collapseOnediv.className == 'collapse')
            collapseOnediv.className = 'collapse show';
        else
            collapseOnediv.className = 'collapse';

        let collapseOnei = document.getElementsByClassName('collapseOnei')[0];
        if (collapseOnei.className == 'collapseOnei mdi accor-plus-icon')
            collapseOnei.className = 'collapseOnei mdi mdi-minus';
        else
            collapseOnei.className = 'collapseOnei mdi accor-plus-icon';
    }
    const OpenPanorama = (image, viewlistitems, hfov, pitch, yaw, isexterior) => {

        if (image != undefined) {
            const boxes = document.querySelectorAll('.pnlm-hotspot-base');
            boxes.forEach(box => { box.remove(); });

            var scenes = {};
            for (let i = 0; i < viewlistitems.length; i++) {
                let _imageUrl = viewlistitems[i].areaImage
                if (viewlistitems[i].areaImage.indexOf('http') < 0)
                    _imageUrl = `${LIVE_IMAGE_URL}${viewlistitems[i].areaImage}`
                scenes[viewlistitems[i].id] = {
                    "type": "equirectangular",
                    "panorama": '' + _imageUrl + '',
                    "hfov": hfov,
                    "pitch": pitch,
                    "yaw": yaw,
                    "hotSpots": gethotSpots(viewlistitems[i].id, isexterior)
                }
            }
            window.panoramaviewer = window.pannellum.viewer('panorama', {
                "default": {
                    "firstScene": image,
                    "sceneFadeDuration": 1000,
                    "autoLoad": true,
                    "showControls": false
                },
                "scenes": scenes
            });
            window.panoramaviewer.on('scenechange',
                function () {
                    selectElement(window.panoramaviewer.getScene())
                }
            );

            window.panoramaviewer.on('load',
                function () {
                    const scopehotspot = document.getElementsByClassName("custom-hotspot");
                    if (scopehotspot.length > 0) {
                        services.getTour(parseInt(params.slug)).then(result => {
                            for (let i = 0; i < scopehotspot.length; i++) {
                                var v = scopehotspot[i];
                                v.addEventListener("click", (e) => {
                                    var sceneid = parseInt(e.target.getAttribute('sceneid'));
                                    var levelid = parseInt(e.target.getAttribute('levelid'));

                                    var destrotpitch = e.target.getAttribute('destrotpitch');
                                    if (destrotpitch == undefined)
                                        destrotpitch = -3;
                                    else
                                        destrotpitch = parseFloat(destrotpitch);

                                    var destrotyaw = e.target.getAttribute('destrotyaw');
                                    if (destrotyaw == undefined)
                                        destrotyaw = 117;
                                    else
                                        destrotyaw = parseFloat(destrotyaw);

                                    var destrotHof = e.target.getAttribute('destrotHof');
                                    if (destrotHof == undefined)
                                        destrotHof = 110;
                                    else
                                        destrotHof = parseFloat(destrotHof);

                                    if (result.exteriors?.length > 0) {
                                        var scenefound = false;
                                        for (let ii = 0; ii < result.exteriors.length; ii++) {
                                            if (result.exteriors[ii].id == sceneid) {
                                                scenefound = true;
                                                break;
                                            }
                                        }
                                        if ((!scenefound && levelid != -1000) || (scenefound && levelid != -1000)) {
                                            setViveTypeExterior(false)
                                            for (let i = 0; i < result.levels.length; i++) {
                                                if (result.levels[i].id == levelid) {
                                                    setViewList(result.levels[i].levelAreas)
                                                    setLevelName(result.levels[i].levelName)
                                                    selectElement(sceneid)
                                                    OpenPanorama(sceneid, result.levels[i].levelAreas, destrotHof, destrotpitch, destrotyaw, false)
                                                }
                                            }
                                        }
                                        else if (scenefound && levelid == -1000) {
                                            setViveTypeExterior(true)
                                            setViewList(result.exteriors)
                                            selectElement(sceneid)
                                            OpenPanorama(sceneid, result.exteriors, destrotHof, destrotpitch, destrotyaw, true)
                                        }
                                    }
                                })
                            }
                        })
                    }
                }
            );
        }
    }
    return (
        <>
            <div>
                <div className="tv-name"><div>{propname}</div> <div><i className="st-info" aria-hidden="true" onClick={() => viewInfo()}></i> </div></div>
                <div id="panorama"></div>
                <div className="tv-list">
                    {!vivetypeexterior ?
                        <div className="">
                            {
                                !isEmpty(levelslist) &&
                                levelslist.map((level, index) => {
                                    return <div key={get(level, 'id')} style={{ textAlign: 'center' }} className={"lvlname " + (level.levelName == levelname ? 'lvlshow' : '')} onClick={() => changevivelevel(level)}>Level {get(level, 'levelName')}</div>
                                })
                            }
                        </div>
                        : <></>}
                    <div className="">
                        {vivetypeexterior ? <i className="st-exterior viewswitch" aria-hidden="true" onClick={() => changevivetype()}></i>
                            : <i className="st-interior viewswitch" aria-hidden="true" onClick={() => changevivetype()}></i>}
                    </div>
                    <div className="custom_t">
                        <div className="custom_12r">
                            {
                                !isEmpty(viewlist) &&
                                viewlist.map((view, index) => {
                                    let _imageUrl = get(view, 'areaImage')
                                    if (get(view, 'areaImage').indexOf('http') < 0)
                                        _imageUrl = `${LIVE_IMAGE_URL}${get(view, 'areaImage')}`
                                    return <div key={get(view, 'id')} id={"sv" + get(view, "id")} className="custom_img_v" style={{
                                        backgroundImage: 'url("' + _imageUrl.replace('.png', 'thumb.png').replace('.jpeg', 'thumb.jpeg') + '")',
                                    }} onClick={() => changeview(view)}>
                                        <span className="custom_img_v_span">{get(view, 'areaName')}</span>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="tv-name-bottom">Powered by <img src={logolight} alt="stitch360" height="30" className="logo" /></div>
            </div>
            <Modal show={viewInfoDetail} onHide={hideViewInfo}>
                <Modal.Body>
                    <div className="mdl-body tv_mdlbdy">
                        <div className="stich_new_3" style={{ minHeight: '420px' }}>
                            <div className="stich_new_inner12">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="text-muted"> <p className="mb-1">TITLE</p>
                                            <h5>{get(tour, 'propertyName')}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {get(tour, 'propertyAddress') != '' && get(tour, 'propertyAddress') != null &&
                                        <div className="col-7">
                                            <div className="text-muted"> <p className="mb-1">ADDRESS</p>
                                                <h6>{get(tour, 'propertyAddress')}</h6>
                                            </div>
                                        </div>
                                    }
                                    {get(tour, 'price') != 0 &&
                                        <div className="col-5">
                                            <div className="text-muted"> <p className="mb-1">PRICE</p>
                                                {tour.purchaseType == 'Rent' && <h6>${get(tour, 'price')}/month</h6>}
                                                {tour.purchaseType == 'Sale' && <h6>${get(tour, 'price')}</h6>}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-7">
                                        <div className="text-muted"> {get(tour, 'beds') != 0 && get(tour, 'baths') != 0 && <p className="mb-1">ROOMS</p>}
                                            <div className="facilities_description ms-auto gap-2 mb-0">
                                                {get(tour, 'beds') != 0 &&
                                                    <div>
                                                        <h6>{get(tour, 'beds')} Bed</h6>
                                                    </div>
                                                }
                                                {get(tour, 'baths') != 0 &&
                                                    <div>
                                                        <h6><i className="bx bxs-circle"></i></h6>
                                                    </div>
                                                }
                                                {get(tour, 'baths') != 0 &&
                                                    <div>
                                                        <h6>{get(tour, 'baths')} Bath</h6>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {get(tour, 'propertySize') != 0 &&
                                        <div className="col-5">
                                            <div className="text-muted"> <p className="mb-1">SIZE</p>
                                                <h6>{get(tour, 'propertySize')} sqft</h6>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="">
                                    <div className="tabs_custom">
                                        <div className="tab-content">
                                            <div className="tab-pane fade show active" id="v-pills-gen-ques" role="tabpanel">
                                                <div>
                                                    <div id="gen-ques-accordion" className="accordion custom-accordion">
                                                        <div className="">
                                                            <div id="general-collapseOne" className="collapse" data-bs-parent="#gen-ques-accordion">
                                                                <div className="p-1">
                                                                    <ul>
                                                                        {selectedtouramenities.map(name => (
                                                                            <li>
                                                                                {name}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <a onClick={() => toggleamenities()} className="accordion-list collapsed" data-bs-toggle="collapse" aria-expanded="false" aria-controls="general-collapseOne">
                                                                <div>SHOW AMENITIES</div>
                                                                <i className="collapseOnei mdi accor-plus-icon"></i>
                                                            </a>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default TourView;