import { get, isEmpty } from 'lodash';
import { services } from '../../utils/services';
import { NOTIFICATION_TYPE } from "../../utils/constant";
import { getValue } from '../../utils'
import { showLoader } from "./homeAction";
import { showNotification } from "./notificationAction";

export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const LOGOUT_USER = "LOGOUT_USER";

export const loginUserRequest = () => {
    return {
        type: LOGIN_USER_REQUEST,
    }
}

export const loginUserSuccess = (userDetails) => {
    return {
        type: LOGIN_USER_SUCCESS,
        payload: userDetails
    }
}

export const loginUserFailure = (error) => {
    return {
        type: LOGIN_USER_FAILURE,
        payload: error
    }
}

export const logoutUser = () => {
    return {
        type: LOGOUT_USER
    }
}

export const doRegisterUser = (registerForm) => {
    return (dispatch) => {
        dispatch(showLoader(true))
        services.registerUser(registerForm).then((response) => {
            if (response.status < 400 || response.response.status < 400) {
                if (response.data.isSuccess) {
                    dispatch(showNotification(NOTIFICATION_TYPE.SUCCESS, response.data.message))
                    dispatch(doLoginUser({ ...registerForm }));
                }
                else
                    dispatch(showNotification(NOTIFICATION_TYPE.ERROR, response.data.message))
            } else if (response.response.data.errors) {
                dispatch(showNotification(NOTIFICATION_TYPE.ERROR, JSON.stringify(response.response.data.errors)))
            }
            else {
                dispatch(showNotification(NOTIFICATION_TYPE.ERROR, JSON.stringify(response.code)))
            }
            dispatch(showLoader(false))
        }).catch((e) => {
            dispatch(showLoader(false))
            dispatch(showNotification(NOTIFICATION_TYPE.ERROR, e))
        })
    }
}

export const setUserDTO = ({ result }) => {
    const _userId = get(result, 'userId')
    if (!isEmpty(result)) {
        localStorage.setItem("userId", _userId);
    }
}

export const doSendOtp = (email) => {
    return (dispatch) => {
        dispatch(showLoader(true))
        services.sendOtp(email).then((response) => {
            if (response.status < 400 || response.response.status < 400) {
                if (response.data.isSuccess) {
                    dispatch(showNotification(NOTIFICATION_TYPE.SUCCESS, response.data.message))
                }
                else
                    dispatch(showNotification(NOTIFICATION_TYPE.ERROR, response.data.message))
            } else if (response.response.data.errors) {
                dispatch(showNotification(NOTIFICATION_TYPE.ERROR, JSON.stringify(response.response.data.errors)))
            }
            else {
                dispatch(showNotification(NOTIFICATION_TYPE.ERROR, JSON.stringify(response.code)))
            }
            dispatch(showLoader(false))
        }).catch((e) => {
            dispatch(showLoader(false))
            dispatch(showNotification(NOTIFICATION_TYPE.ERROR, e))
        })
    }
}

export const doVerifyOtp = (loginForm) => {
    return (dispatch) => {
        dispatch(showLoader(true))
        services.verifyOtp(loginForm).then((response) => {
            if (response.status < 400 || response.response.status < 400) {
                if (response.data.isSuccess) {
                    dispatch(showNotification(NOTIFICATION_TYPE.SUCCESS, response.data.message))
                    setTimeout(window.location.href = '/', 3000)
                }
                else
                    dispatch(showNotification(NOTIFICATION_TYPE.ERROR, response.data.message))
            } else if (response.response.data.errors) {
                dispatch(showNotification(NOTIFICATION_TYPE.ERROR, JSON.stringify(response.response.data.errors)))
            }
            else {
                dispatch(showNotification(NOTIFICATION_TYPE.ERROR, JSON.stringify(response.code)))
            }
            dispatch(showLoader(false))
        }).catch((e) => {
            dispatch(showLoader(false))
            dispatch(showNotification(NOTIFICATION_TYPE.ERROR, e))
        })
    }
}

export const doLoginUser = (loginForm) => {
    return (dispatch) => {
        dispatch(loginUserRequest())
        dispatch(showLoader(true))
        services.loginUser(loginForm).then((response) => {
            if (response.status < 400 || response.response.status < 400) {
                if (response.data.isSuccess) {
                    var result = response.data.data
                    if (!isEmpty(result)) {
                        localStorage.setItem("userId", result.userID);
                        localStorage.setItem("userName", result.name);
                        localStorage.setItem("type", result.type);
                        if (!isEmpty(response.data.token)) {
                            localStorage.setItem("token", response.data.token)
                        }
                    }
                    dispatch(loginUserSuccess(result))
                    dispatch(showNotification(NOTIFICATION_TYPE.SUCCESS, response.data.message))
                    if (result.type)
                        setTimeout(window.location.href = '/admin/users', 500)
                    else if (window.location.href.indexOf('pricing') > 0)
                        setTimeout(window.location.href = '/pricing', 500)
                    else
                        setTimeout(window.location.href = '/tours', 500)
                }
                else {
                    dispatch(loginUserFailure(response.data.message))
                    dispatch(showNotification(NOTIFICATION_TYPE.ERROR, response.data.message))
                }
            } else if (response.response.data.errors) {
                var message = JSON.stringify(response.response.data.errors)
                dispatch(loginUserFailure(message))
                dispatch(showNotification(NOTIFICATION_TYPE.ERROR, message))
            }
            else {
                var message = JSON.stringify(response.code)
                dispatch(loginUserFailure(message))
                dispatch(showNotification(NOTIFICATION_TYPE.ERROR, message))
            }
            dispatch(showLoader(false))
        }).catch((e) => {
            dispatch(showLoader(false))
            dispatch(loginUserFailure(e))
            console.error("Login error ==>", e)
            dispatch(showNotification(NOTIFICATION_TYPE.ERROR, e))
        })
        dispatch(showLoader(true))
    }
}

export const getToken = () => {
    const token = localStorage.getItem("token");
    return token;
}

export const getUserDTO = () => {
    const userDTO = getValue(localStorage.getItem("userId")) || "";
    return userDTO
}

export const getUserTypeDTO = () => {
    const userDTO = getValue(localStorage.getItem("type")) || "";
    return userDTO
}

export const isUserExist = () => {
    const _userId = getUserDTO();
    const _userType = getUserTypeDTO();
    if (_userId && _userType != 'true') {
        return true;
    }
    return false;
}

export const isAdminUserExist = () => {
    const _userId = getUserDTO();
    const _userType = getUserTypeDTO();
    if (_userId && _userType == 'true') {
        return true;
    }
    return false;
}

export const doLogout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("token");
    localStorage.removeItem("type");
    return (dispatch) => {
        dispatch(logoutUser());
        dispatch(showLoader(false))
        window.location.href = "/"
    }
}



