import React, { useState, useEffect } from 'react';
import { isUserExist } from '../redux/actions/authAction';
import { useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import { showLoader } from '../redux/actions/homeAction';
import { showNotification } from "../redux/actions/notificationAction";
import { NOTIFICATION_TYPE } from "../utils/constant";
import { services } from '../utils/services';
import convert360 from '../assets/images/convert_360.png';
import rooms from '../assets/images/rooms.png';
import upload from '../assets/images/upload.png';
import arrange from '../assets/images/Arrange.png';
import viewshare from '../assets/images/view_share.png';
import facebook from '../assets/images/facebook.png';
import linkedin from '../assets/images/linkedin.png';

const Home = () => {
    const dispatch = useDispatch();
    const [error, setError] = useState({});
    const [formname, setFormName] = useState("");
    const [formemail, setFormEmail] = useState("");
    const [formsubject, setFormSubject] = useState("");
    const [formmsg, setFormMsg] = useState("");
    const navigate = useNavigate();
    const _isUserExist = isUserExist();
    useEffect(() => {
        if (_isUserExist)
            navigate("/tours")
    }, []);
    const validateForm = () => {
        const _error = {};

        if (isEmpty(formname)) {
            _error["formname"] = "This field is required";
        }
        if (isEmpty(formemail)) {
            _error["formemail"] = "This field is required";
        }
        if (isEmpty(formsubject)) {
            _error["formsubject"] = "This field is required";
        }
        if (isEmpty(formmsg)) {
            _error["formmsg"] = "This field is required";
        }

        setError(_error);
        if (!isEmpty(_error)) {
            return false;
        }
        return true;
    }
    const saveForm = async () => {
        dispatch(showLoader(true))
        const homeForm = {
            name: formname,
            email: formemail,
            subject: formsubject,
            message: formmsg
        }
        const response = await services.addContactForm(homeForm);
        if (response) {
            setFormName('')
            setFormEmail('')
            setFormSubject('')
            setFormMsg('')
            dispatch(showLoader(false))
            dispatch(showNotification(NOTIFICATION_TYPE.SUCCESS, 'Successfully submitted'))
        }
    }
    const onSubmitHandler = () => {
        if (validateForm()) {
            saveForm()
        }
    }
    return (
        <>
            <section className="section hero-section bg-ico-hero" id="home">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="text-white-50" style={{ marginTop: '45px' }}>
                                <h1 className="text-white font-weight-semibold mb-3 hero-title">
                                    MAKE<br />
                                    VIRTUAL TOURS<br />
                                    WITH <span className="ease">EASE</span>
                                </h1>
                                <div className="button-items mt-4">
                                    <a href="/signup" className="btn btn-primary btn-rounded">
                                        GET STARTED &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i className="bx bx-right-arrow-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="section bg-white" id="about">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-7">
                            <div className="text-stict">
                                <p>
                                    <span className="text-primary">360</span> <strong>Stitch</strong> is the quick and easy way to create immersive virtual walkthroughs of any space in minutes.
                                </p>
                                <p>
                                    Our cloud-based web app allows you to build, view, and share interactive tours from anywhere with an internet connection. Plus, automatic backup means your work is never lost.
                                </p>
                                <p>
                                    Whether you're looking to sell a property, promote a venue, or just share your space with the world, <span className="text-primary">360</span> <strong>Stitch</strong> is the budget-friendly tool that can instantly turn your 360 images into an engaging experience that anyone can view.
                                </p>
                                <p>
                                    <video width="95%" height="300" controls autoPlay muted style={{ border:'1px solid #eee' }}>
                                        <source src="https://360stitchstorage.blob.core.windows.net/stitch/360Stitch.mp4" type="video/mp4" />
                                        Sorry, your browser doesn't support videos.
                                    </video>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-5 ms-auto">
                            <div className="mt-5">
                                <div className="row justify-content-center mt-5">
                                    <div className="col-sm-12 text-center mb-3 mt-5">
                                        <img height="90" src={convert360} />
                                    </div>
                                    <div className="col-sm-12 text-center">
                                        <img height="250" src={rooms} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section hiw" id="features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="mb-5">
                                <h4 className="">How it works</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center pt-4">
                        <div className="col-lg-4 col-md-6 col-sm-12 mt-md-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mt-4 mb-2 text-end">
                                        <img src={upload} height="100" />
                                    </div>
                                    <div>
                                        <h3>UPLOAD</h3>
                                        <p>Use any capture method you would like. Our platform accepts most file types and can handle large file sizes for a high quality viewing experience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mt-md-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mt-4 mb-2 text-end">
                                        <img src={arrange} height="100" />
                                    </div>
                                    <div>
                                        <h3>EDIT</h3>
                                        <p>Add details about the property, create labels for each area, and arrange them by floor. Your images will then be stitched in that order.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mt-md-auto">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mt-4 mb-2 text-end">
                                        <img src={viewshare} height="100" />
                                    </div>
                                    <div>
                                        <h3>VIEW & SHARE</h3>
                                        <p>Our immersive, full-screen web viewer allows you to generate a sharable link and let anyone explore the space in their browser.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 mt-5">
                            <iframe src="https://360stitch.com/tourview/2082" height="500" width="100%" style={{ borderRadius:'30px' }} title="Iframe Example"></iframe>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section bg-white cont" id="contact">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-5">
                            <div className="text-stict">
                                <div className="mb-5">
                                    <h4 className="">Contact Us</h4>
                                </div>
                                <p>
                                    If you have any questions or special <br />requests please feel free to reach<br /> out to us. We can provide solutions<br /> tailor-made to your business needs!
                                </p>
                                <div className="cborder mb-4"></div>
                                <p><strong>Email</strong> support@360stitch.com</p>
                                {/*      <p><strong>phone</strong> +1 (267) 304-2984</p>*/}
                                <p className="d-flex flex-wrap gap-3  align-items-center">
                                    <strong>socials</strong>
                                    <a href="#"> <img src={facebook} height="20" />  </a>
                                    <a href="#"> <img src={linkedin} height="20" /></a>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 ms-auto">
                            <div className="mt-4 mt-lg-0">
                                <div className="mb-3">
                                    <label className="form-label">Name</label>
                                    <input type="text" className="form-control" value={formname}
                                        onChange={(e) => setFormName(e.target.value)} />
                                    {
                                        get(error, 'formname') &&
                                        <span className='requiredMsg'>
                                            {get(error, 'formname')}
                                        </span>
                                    }
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Email</label>
                                    <div>
                                        <input type="email" className="form-control" value={formemail}
                                            onChange={(e) => setFormEmail(e.target.value)} />
                                        {
                                            get(error, 'formemail') &&
                                            <span className='requiredMsg'>
                                                {get(error, 'formemail')}
                                            </span>
                                        }
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Subject</label>
                                    <div>
                                        <input type="text" className="form-control" value={formsubject}
                                            onChange={(e) => setFormSubject(e.target.value)} />
                                        {
                                            get(error, 'formsubject') &&
                                            <span className='requiredMsg'>
                                                {get(error, 'formsubject')}
                                            </span>
                                        }
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Message</label>
                                    <div>
                                        <textarea className="form-control" rows="3" value={formmsg}
                                            onChange={(e) => setFormMsg(e.target.value)}></textarea>
                                        {
                                            get(error, 'formmsg') &&
                                            <span className='requiredMsg'>
                                                {get(error, 'formmsg')}
                                            </span>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex flex-wrap gap-2 justify-content-end">
                                    <button onClick={() => onSubmitHandler()} className="btn btn-secondary waves-effect waves-light w-md btn-rounded">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Home;