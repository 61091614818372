import React from 'react';
import { isAdminUserExist } from '../../redux/actions/authAction';

import NotFound from '../../pages/notFound';

const AdminRoutes = ({ children }) => {
    const _isUserExist = isAdminUserExist();


    return _isUserExist ?
        children : <NotFound />;

}
export default AdminRoutes;