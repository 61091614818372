import React, { useState, useEffect, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import defaultimg from '../assets/images/default.jpg';
import homeimg from '../assets/images/home_gray.png';
import degree from '../assets/images/degree.png';
import pencil from '../assets/images/pencil.png';
import share from '../assets/images/share.png';
import close from '../assets/images/close.png';
import { services } from '../utils/services';
import { useDispatch } from 'react-redux';
import { showLoader } from '../redux/actions/homeAction';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import { LIVE_IMAGE_URL } from '../utils/globalConfig';
import { showNotification } from "../../src/redux/actions/notificationAction";
import { NOTIFICATION_TYPE } from "../../src/utils/constant";

const MySwal = withReactContent(Swal)

const Tours = () => {
    const navigate = useNavigate();
    const [tours, setTours] = useState([]);
    const [error, setError] = useState({});
    const [addnewtour, setAddNewTour] = useState(false);
    const [propertyname, setPropertyName] = useState("");
    const [touraddress, setTourAddress] = useState("");
    const [tourpropsize, setTourPropSize] = useState("");
    const [tourpurchasetype, setTourPurchaseType] = useState("none");
    const [tourprice, setTourPrice] = useState("");
    const [tourbeds, setTourBeds] = useState("");
    const [tourbaths, setTourBaths] = useState("");
    const [tourowner, setTourOwner] = useState("");
    const [tourconstructed, setTourConstructed] = useState("");
    const [touramenities, setTourAmenities] = useState([]);
    const [addText, setAddText] = useState("Add a New Tour");
    const [tourID, setTourID] = useState(0);
    const inputpropstags = { placeholder: 'Add a Amenity' };
    const [selectedtour, setSelectedTour] = useState(null);
    const [selectedtouramenities, setSelectedTourAmenities] = useState([]);
    const [shareTour, setShareTour] = useState(false);
    const [noPaymentTour, setNoPaymentTour] = useState(false);
    const [showfeedback, setShowFeedback] = useState(false);
    const [feedbackmsg, setFeedbackMsg] = useState("");    

    const dataFetchedRef = useRef(false);
    const dispatch = useDispatch();
    const getTours = async () => {
        dispatch(showLoader(true))
        const response = await services.getTours();
        if (response) {
            setTours(response)
            setTimeout(function () {
                if (document.getElementsByClassName('card stich_new active').length > 0)
                    document.getElementsByClassName('card stich_new active')[0].click();
            }, 100);
        }
        else
            setTours([])
        dispatch(showLoader(false))
    }
    const saveTour = async () => {
        dispatch(showLoader(true))
        const tourForm = {
            propertyName: propertyname,
            iD: tourID,
            propertyAddress: touraddress,
            propertySize: tourpropsize == "" ? 0 : tourpropsize,
            purchaseType: tourpurchasetype == "none" ? "" : tourpurchasetype,
            price: tourprice == "" ? 0 : tourprice,
            beds: tourbeds == "" ? 0 : tourbeds,
            baths: tourbaths == "" ? 0 : tourbaths,
            owner: tourowner,
            constructed: tourconstructed == "" ? 0 : tourconstructed,
            amenities: JSON.stringify(touramenities)
        }
        const response = await services.addTour(tourForm);
        if (response) {
            setAddNewTour(false)
            setPropertyName('')
            dispatch(showLoader(false))
            getTours()
        }
    }
    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getTours()
    }, [])
    const viewTour = (event, tour) => {
        if (event.target.className.indexOf('st-pencil-trash') < 0)
            navigate('/tour/' + tour.id)
    }
    const selectTour = (event, tour) => {
        if (event.target.className.indexOf('st-pencil-trash') < 0) {
            setSelectedTour(tour)
            if (tour.amenities != undefined)
                setSelectedTourAmenities(JSON.parse(tour.amenities))
            else
                setSelectedTourAmenities([])
            let stitchcards = document.getElementsByClassName('card stich_new');
            for (let i = 0; i < stitchcards.length; i++) {
                stitchcards[i].className = 'card stich_new';
            }
            document.querySelector("[ltid='" + tour.id + "']").className = 'card stich_new active';
        }
    }
    const addTour = async () => {
        dispatch(showLoader(true))
        const response = await services.addtourallowed();
        if (response) {
            setNoPaymentTour(false)
            setAddNewTour(true)
            setPropertyName("")
            setTourAddress("")
            setTourPropSize("")
            setTourPurchaseType("none")
            setTourPrice("")
            setTourBeds("")
            setTourBaths("")
            setTourOwner("")
            setTourConstructed("")
            setTourAmenities([])
            setAddText('Add a New Tour')
            setTourID(0)
            setError({})
        }
        else {
            setNoPaymentTour(true)
        }
        dispatch(showLoader(false))
    }
    const onHide = () => {
        setAddNewTour(false)
        getTours()
    }    
    const onHideShowFeedback = () => {
        setShowFeedback(false)
    }
    const onHideNoPaymentTour = () => {
        setNoPaymentTour(false)
    }
    const editTour = (tour) => {
        setAddNewTour(true)
        setPropertyName(tour.propertyName)
        setTourAddress(tour.propertyAddress ? tour.propertyAddress : '')
        setTourPropSize(tour.propertySize == 0 ? "" : tour.propertySize.toString())
        setTourPurchaseType(tour.purchaseType ? tour.purchaseType : 'none')
        setTourPrice(tour.price == 0 ? "" : tour.price.toString())
        setTourBeds(tour.beds == 0 ? "" : tour.beds)
        setTourBaths(tour.baths == 0 ? "" : tour.baths)
        setTourOwner(tour.owner)
        setTourConstructed(tour.constructed == 0 ? "" : tour.constructed)
        if (tour.amenities != undefined)
            setTourAmenities(JSON.parse(tour.amenities))
        setAddText('Update Tour')
        setTourID(tour.id)
    }
    const deleteTour = (tour) => {
        Swal.fire({
            title: 'Are you sure?',
            showCancelButton: true,
            confirmButtonColor: 'red',
            confirmButtonText: 'Delete',
            width: 300,
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await services.deleteTour(tour.id);
                if (response) {
                    dispatch(showLoader(false))
                    getTours()
                }
            }
        })
    }
    const validateForm = () => {
        const _error = {};

        if (isEmpty(propertyname)) {
            _error["propertyname"] = "This field is required";
        }

        if (tourpurchasetype=='none' && !isEmpty(tourprice)) {
            _error["tourpurchasetype"] = "This field is required";
        }

        setError(_error);
        if (!isEmpty(_error)) {
            return false;
        }
        return true;
    }
    const onSubmitHandler = () => {
        if (validateForm()) {
            saveTour()
        }
    }
    const onSubmitFeedback = async () => {
        dispatch(showLoader(true))
        const feedbackForm = {
            message: feedbackmsg,
        }
        const response = await services.addFeedback(feedbackForm);
        if (response) {
            dispatch(showNotification(NOTIFICATION_TYPE.SUCCESS, "Your valuable feedback received"))
            dispatch(showLoader(false))
            setShowFeedback(false)
        }
    }
    const toggleamenities = () => {
        let collapseOnediv = document.getElementById('general-collapseOne');
        if (collapseOnediv.className == 'collapse')
            collapseOnediv.className = 'collapse show';
        else
            collapseOnediv.className = 'collapse';

        let collapseOnei = document.getElementsByClassName('collapseOnei')[0];
        if (collapseOnei.className == 'collapseOnei mdi accor-plus-icon')
            collapseOnei.className = 'collapseOnei mdi mdi-minus';
        else
            collapseOnei.className = 'collapseOnei mdi accor-plus-icon';
    }
    const viewin360 = (event, tour) => {
        if (event.target.className.indexOf('st-pencil-trash') < 0)
            window.open(window.location.origin + '/tourview/' + tour.id, '_blank');
    }
    const edit360 = (tour) => {
        window.open(window.location.origin + '/tourveditor/' + tour.id, '_blank');
    }
    const viewShareTour = () => {
        setShareTour(true)
    }
    const onHideShareTour = () => {
        setShareTour(false)
    }
    const addtoclipboard = (tourlink) => {
        navigator.clipboard.writeText(tourlink)
        setShareTour(false)
    }
    const closeSelectedTour = () => {
        setSelectedTour(null)
        let stitchcards = document.getElementsByClassName('card stich_new');
        for (let i = 0; i < stitchcards.length; i++) {
            stitchcards[i].className = 'card stich_new';
        }
    }
    return (
        <>
            <div className="page-content custom_2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-sm-12 side_first">
                            {
                                !isEmpty(tours) &&
                                tours.map((tour, index) => {
                                    const _imageUrl = get(tour, 'propertyImage') ? `${LIVE_IMAGE_URL}${get(tour, 'propertyImage')}` : defaultimg;
                                    return <div key={get(tour, 'id')} className="card stich_new" ltid={get(tour, 'id')} onClick={(event) => selectTour(event, tour)}>
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-md-4 ">
                                                <div className="stich_new_inner">
                                                    <img className="card-img img-fluid" src={_imageUrl.replace('.png', 'thumb.png').replace('.jpeg', 'thumb.jpeg')} alt="Card image" />
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="stich_new_inner_1">
                                                    <div className="d-flex justify-content-between align-items-end">
                                                        <div>
                                                            <h5 className="card-title">{get(tour, 'propertyName')}</h5>
                                                        </div>
                                                        <div>
                                                            {tour.purchaseType == 'Rent' && <p className="mb-0">${get(tour, 'price')}/month</p>}
                                                            {tour.purchaseType == 'Sale' && <p className="mb-0">${get(tour, 'price')}</p>}
                                                        </div>
                                                    </div>
                                                    <div className="facilities_description ms-auto gap-2">
                                                        <div>
                                                            <p>{get(tour, 'beds')} Bed</p>
                                                        </div>
                                                        <div>
                                                            <p><small className="text-muted"><i className="bx bxs-circle"></i></small></p>
                                                        </div>
                                                        <div>
                                                            <p>{get(tour, 'baths')} Bath</p>
                                                        </div>
                                                    </div>
                                                    <p className="card-text">
                                                        {get(tour, 'propertyAddress')}
                                                        <i className="st-trash st-trash-grey st-pencil-trash" aria-hidden="true" onClick={() => deleteTour(tour)}></i><i className="st-pencil st-pencil-grey st-pencil-trash mr-2" aria-hidden="true" onClick={() => editTour(tour)}></i>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className="col-lg-4 col-sm-12 ms-auto">
                            {selectedtour == null &&
                                <div className="stich_new_2">
                                    <div className="row justify-content-center">
                                        <div className="col-sm-12 text-center">
                                            <img className="mb-3" height="50" src={homeimg} />
                                            <p>SELECT A PROPERTY TO<br />
                                                SEE DETAILS</p>
                                        </div>
                                    </div>
                                </div>
                            }
                            {selectedtour != null &&
                                <div className="stich_new_3">
                                    <div className="stich_new_inner">
                                        <img className="card-img img-fluid" src={get(selectedtour, 'propertyImage') ? `${LIVE_IMAGE_URL}${get(selectedtour, 'propertyImage').replace('.png', 'thumb.png').replace('.jpeg', 'thumb.jpeg')}` : defaultimg} alt="Card image" />
                                    </div>
                                    <div className="view_12">
                                        <div className="d-flex justify-content-between">
                                            <div className=" ">
                                            <a style={{ cursor: 'pointer', pointerEvents: selectedtour.propertyImage ? '' : 'none', backgroundColor: selectedtour.propertyImage ? '#fff' : '#ddd' }} title="360 View" onClick={(event) => viewin360(event, selectedtour)}><img src={degree} height="30" /></a>
                                            </div>
                                            <div className="">
                                                <a style={{ cursor: 'pointer' }} title="360 Edit" onClick={() => edit360(selectedtour)}><img src={pencil} height="45" /></a>
                                            </div>
                                            <div className="">
                                            <a style={{ cursor: 'pointer', pointerEvents: selectedtour.propertyImage ? '' : 'none', backgroundColor: selectedtour.propertyImage ? '#fff' : '#ddd' }} title="360 Share" onClick={() => viewShareTour()}><img src={share} height="25" /></a>
                                            </div>
                                            <div className="">
                                                <a style={{ cursor: 'pointer' }} title="Close" onClick={() => closeSelectedTour()}><img src={close} height="20" /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="stich_new_inner12">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="text-muted"> <p className="mb-1">TITLE</p>
                                                    <h5>{get(selectedtour, 'propertyName')}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {get(selectedtour, 'propertyAddress') != '' && get(selectedtour, 'propertyAddress') != null &&
                                                <div className="col-7">
                                                    <div className="text-muted"> <p className="mb-1">ADDRESS</p>
                                                        <h6>{get(selectedtour, 'propertyAddress')}</h6>
                                                    </div>
                                                </div>
                                            }
                                            {get(selectedtour, 'price') != 0 &&
                                                <div className="col-5">
                                                    <div className="text-muted"> <p className="mb-1">PRICE</p>
                                                        {selectedtour.purchaseType == 'Rent' && <h6>${get(selectedtour, 'price')}/month</h6>}
                                                        {selectedtour.purchaseType == 'Sale' && <h6>${get(selectedtour, 'price')}</h6>}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="row">
                                            <div className="col-7">
                                                <div className="text-muted"> {get(selectedtour, 'beds') != 0 && get(selectedtour, 'baths') != 0 && <p className="mb-1">ROOMS</p>}
                                                    <div className="facilities_description ms-auto gap-2 mb-0">
                                                        {get(selectedtour, 'beds') != 0 &&
                                                            <div>
                                                                <h6>{get(selectedtour, 'beds')} Bed</h6>
                                                            </div>
                                                        }
                                                        {get(selectedtour, 'baths') != 0 &&
                                                            <div>
                                                                <h6><i className="bx bxs-circle"></i></h6>
                                                            </div>
                                                        }
                                                        {get(selectedtour, 'baths') != 0 &&
                                                            <div>
                                                                <h6>{get(selectedtour, 'baths')} Bath</h6>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {get(selectedtour, 'propertySize') != 0 &&
                                                <div className="col-5">
                                                    <div className="text-muted"> <p className="mb-1">SIZE</p>
                                                        <h6>{get(selectedtour, 'propertySize')} sqft</h6>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        {/*<div className="row">*/}
                                        {/*    <div className="col-7">*/}
                                        {/*        <div className="text-muted"> <p className="mb-1">OWNER</p>*/}
                                        {/*            <h6>{get(selectedtour, 'owner')}</h6>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="col-5">*/}
                                        {/*        <div className="text-muted"> <p className="mb-1">CONSTRUCTED</p>*/}
                                        {/*            <h6>{get(selectedtour, 'constructed')}</h6>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="">
                                            <div className="tabs_custom">
                                                <div className="tab-content">
                                                    <div className="tab-pane fade show active" id="v-pills-gen-ques" role="tabpanel">
                                                        <div>
                                                            <div id="gen-ques-accordion" className="accordion custom-accordion">
                                                                <div className="">
                                                                    <div id="general-collapseOne" className="collapse" data-bs-parent="#gen-ques-accordion">
                                                                        <div className="p-1">
                                                                            <ul>
                                                                                {selectedtouramenities.map(name => (
                                                                                    <li>
                                                                                        {name}
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <a onClick={() => toggleamenities()} className="accordion-list collapsed" data-bs-toggle="collapse" aria-expanded="false" aria-controls="general-collapseOne">
                                                                        <div>SHOW AMENITIES</div>
                                                                        <i className="collapseOnei mdi accor-plus-icon"></i>
                                                                    </a>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="row" style={{ display: 'none' }}>
                        <div className="custom_img_border_addtour" onClick={() => addTour()}>
                            <div className="custom_img_border_span_3_1"><p className="font_30">+</p><div>Add New Tour</div></div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={addnewtour} onHide={onHide} size="lg" >
                <Modal.Body className="tourmodalbody">
                    <h5 className="modal-title">{addText}</h5>
                    <div className="mdl-body row">
                        <div className="col-md-6"><label>Property Name</label>
                            <input type="text" maxLength="100" className="form-control mb-4" placeholder="Property Name" value={propertyname}
                                onChange={(e) => setPropertyName(e.target.value)} />
                            {
                                get(error, 'propertyname') && isEmpty(propertyname) &&
                                <span className='requiredMsg'>
                                    {get(error, 'propertyname')}
                                </span>

                            }

                            <label>Address</label>
                            <input type="text" maxLength="100" className="form-control mb-4" placeholder="Address" value={touraddress}
                                onChange={(e) => setTourAddress(e.target.value)} />

                            <label>Property Size(in Sq. Feet)</label>
                            <input type="text" className="form-control mb-4" placeholder="Property Size(in Sq. Feet)" value={tourpropsize}
                                onChange={(e) => setTourPropSize(e.target.value.replace(/[^0-9]/g, ''))} />

                            <label>Purchase Type</label>
                            <select className="form-control mb-4 slttour" value={tourpurchasetype} onChange={(e) => setTourPurchaseType(e.target.value)}>
                                <option value="none">Purchase Type</option>
                                <option value="Rent">For Rent</option>
                                <option value="Sale">For Sale</option>
                            </select>
                            {
                                get(error, 'tourpurchasetype') && tourpurchasetype == 'none' &&
                                <span className='requiredMsg'>
                                    {get(error, 'tourpurchasetype')}
                                </span>

                            }
                            <label>Price(in USD)</label>
                            <input type="text" className="form-control mb-4" placeholder="Price(in USD)" value={tourprice}
                                onChange={(e) => setTourPrice(e.target.value.replace(/[^0-9]/g, ''))} />
                            <label>No of Beds</label>
                            <input type="text" className="form-control mb-4" placeholder="No of Beds" value={tourbeds}
                                onChange={(e) => setTourBeds(e.target.value.replace(/[^0-9]/g, ''))} />
                        </div>
                        <div className="col-md-6">
                            <label>No of Baths</label>
                            <input type="text" className="form-control mb-4" placeholder="No of Baths" value={tourbaths}
                                onChange={(e) => setTourBaths(e.target.value.replace(/[^0-9]/g, ''))} />

                            {/*<label>Owner Name</label>*/}
                            {/*<input type="text" maxLength="100" className="form-control mb-4" placeholder="Owner Name" value={tourowner}*/}
                            {/*    onChange={(e) => setTourOwner(e.target.value)} />*/}

                            <label>Constructed Year</label>
                            <input type="text" className="form-control mb-4" placeholder="Constructed Year" value={tourconstructed}
                                onChange={(e) => setTourConstructed(e.target.value.replace(/[^0-9]/g, ''))} />

                            <label>Amenities</label>
                            <TagsInput value={touramenities} inputProps={inputpropstags} onChange={setTourAmenities} />
                            <button onClick={() => onSubmitHandler()} className="btn btn-primary btn-rounded btn-block">{addText}</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={shareTour} onHide={onHideShareTour}>
                <Modal.Body>
                    <h5 className="modal-title">Share Tour</h5>
                    <div className="mdl-body">
                        <span className="tv-share">{window.location.origin + '/tourview/' + selectedtour?.id} <i className="fa fa-clone"
                            style={{ 'float': 'right', 'cursor': 'pointer' }} aria-hidden="true" onClick={() => addtoclipboard(window.location.origin + '/tourview/' + selectedtour?.id)}></i></span>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={noPaymentTour} onHide={onHideNoPaymentTour}>
                <Modal.Body>
                    <h5 className="modal-title">Need new Tours</h5>
                    <div className="mdl-body">
                        <p>Hey, Looks like you are out of your Tours quota, but nothing to worry about, you can always add more.</p>
                        <br />
                        <p><a href="/pricing" className="btn btn-primary btn-rounded btn-block">Explore Plans</a></p>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showfeedback} onHide={onHideShowFeedback}>
                <Modal.Body>
                    <h5 className="modal-title">Feedback for us</h5>
                    <div className="mdl-body">
                        <textarea className="form-control mb-4" rows="3" placeholder="Type feedback here..." value={feedbackmsg}
                            onChange={(e) => setFeedbackMsg(e.target.value)}></textarea>
                        <button onClick={() => onSubmitFeedback()} className="btn btn-primary btn-block btn-rounded mt-4">
                            Submit
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <button onClick={() => setShowFeedback(true)} className="btn btn-primary btn-rounded" id="feedbackBtn" title="Feedback">Feedback</button>
        </>
    );
}

export default Tours;