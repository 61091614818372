import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isUserExist, isAdminUserExist, doLoginUser, doSendOtp, doVerifyOtp, doRegisterUser, doLogout } from '../redux/actions/authAction';
import { NavLink } from 'react-router-dom';
import logodark from '../assets/images/logo-dark.png';
import logolight from '../assets/images/logo-light.png';
import { useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { get, isEmpty } from 'lodash';
import plus from '../assets/images/plus.png';
import setting from '../assets/images/setting.png';

const Header = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [error, setError] = useState({});
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [otp, setOtp] = useState("");
    const [name, setName] = useState("");
    var _isUserExist = isUserExist();
    const _isAdminUserExist = isAdminUserExist();
    const userName = localStorage.getItem("userName");
    const [menushow, setMenuShow] = useState(false);
    const [signin, setSignin] = useState(false);
    const [signup, setSignup] = useState(false);
    const [forgot, setForgot] = useState(false);
    const [togglecog, setToggleCog] = useState("");
    const toggleMenu = () => {
        setMenuShow(!menushow)
    };
    const addTour = () => {
        document.getElementsByClassName('custom_img_border_addtour')[0].click();
    };
    const showsignin = () => {
        setSignin(true)
        setSignup(false)
        setForgot(false)
    };
    const showforgot = () => {
        setSignin(false)
        setSignup(false)
        setForgot(true)
    };
    const showsignup = () => {
        setSignin(false)
        setSignup(true)
        setForgot(false)
    };
    const onHideSignin = () => {
        setSignin(false)
    };
    const onHideSignup = () => {
        setSignup(false)
    };
    const onHideForgot = () => {
        setForgot(false)
    };
    const validateForm = () => {
        const _error = {};

        if (isEmpty(email)) {
            _error["email"] = "This field is required";
        }
        else if (email.indexOf('@') < 0) {
            _error["email"] = "Valid email is required";
        }

        if (isEmpty(password)) {
            _error["password"] = "This field is required";
        }
        else if (password.length < 6) {
            _error["password"] = "Password (6 characters minimum)";
        }

        setError(_error);
        if (!isEmpty(_error)) {
            return false;
        }
        return true;
    };
    const validateRegisterForm = () => {
        const _error = {};

        if (isEmpty(name)) {
            _error["name"] = "This field is required";
        }

        if (isEmpty(email)) {
            _error["email"] = "This field is required";
        }
        else if (email.indexOf('@') < 0) {
            _error["email"] = "Valid email is required";
        }

        if (isEmpty(password)) {
            _error["password"] = "This field is required";
        }
        else if (password.length < 6) {
            _error["password"] = "Password (6 characters minimum)";
        }

        setError(_error);
        if (!isEmpty(_error)) {
            return false;
        }
        return true;
    };
    const validateOtpForm = (isotp) => {
        const _error = {};

        if (isEmpty(email)) {
            _error["email"] = "This field is required";
        }
        else if (email.indexOf('@') < 0) {
            _error["email"] = "Valid email is required";
        }

        if (isotp && isEmpty(otp)) {
            _error["otp"] = "This field is required";
        }

        if (isotp && isEmpty(password)) {
            _error["password"] = "This field is required";
        }
        else if (isotp && password.length < 6) {
            _error["password"] = "Password (6 characters minimum)";
        }

        setError(_error);
        if (!isEmpty(_error)) {
            return false;
        }
        return true;
    };

    const login = () => {
        if (validateForm()) {
            const loginForm = {
                email: email,
                password: password
            }
            dispatch(doLoginUser({ ...loginForm }));
        }
    };
    const sendotp = () => {
        if (validateOtpForm(false)) {
            const loginForm = {
                email: email
            }
            dispatch(doSendOtp(loginForm));
        }
    };
    const otpverify = () => {
        if (validateOtpForm(true)) {
            const loginForm = {
                email: email,
                password: password,
                otp: otp
            }
            dispatch(doVerifyOtp({ ...loginForm }));
        }
    };
    const register = () => {
        if (validateRegisterForm()) {
            const registrationForm = {
                name: name,
                email: email,
                password: password
            }
            dispatch(doRegisterUser({ ...registrationForm }));
        }
    };
    const toggleCog = () => {
        if (togglecog == '')
            setToggleCog('show');
        else
            setToggleCog('');
    };
    if (location.pathname == '/') {
        const show = (menushow) ? "show" : "";
        if (_isAdminUserExist)
            _isUserExist = _isAdminUserExist;
        return (
            <>
                <nav className="navbar navbar-expand-lg navigation fixed-top sticky">
                    <div className="container">
                        <a className="navbar-logo" href="/">
                            <img src={logodark} alt="stitch360" height="45" className="logo logo-dark" />
                            <img src={logolight} alt="stitch360" height="45" className="logo logo-light" />
                        </a>
                        <button type="button" className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light" onClick={toggleMenu}>
                            <i className="fa fa-fw fa-bars"></i>
                        </button>
                        <div className={"collapse navbar-collapse justify-content-center nvbar " + show} >
                            <ul className="navbar-nav" id="topnav-menu" >
                                <li className="nav-item">
                                    <a className="nav-link active" href="#about">About</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#features">Features</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/pricing">Pricing</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/services">Services</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link cntct" href="#contact">Contact</a>
                                </li>
                                {_isAdminUserExist && <li className="nav-item">
                                    <a className="nav-link cntct" href="/admin/users">Admin Panel</a>
                                </li>}
                                {show && <>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/signin">Sign In</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/signup">Sign Up</NavLink>
                                    </li></>}
                            </ul>
                            {!show && <div className="my-2 ms-lg-2 sign_button" >
                                <div className="d-flex flex-wrap gap-3 align-items-center">
                                    {_isUserExist && <span>{userName} | <span className='logout-btn' onClick={() => dispatch(doLogout())}>Sign Out</span></span>}
                                    {!_isUserExist && <><a className="text-binary stlink" onClick={() => showsignin()}>Sign In</a><a className="btn btn-primary waves-effect btn-rounded w-md" onClick={() => showsignup()}>Sign Up</a></>}
                                </div>
                            </div>}
                        </div>
                    </div>
                </nav>
                <Modal show={signin} onHide={onHideSignin}>
                    <Modal.Body>
                        <div className="mdl-body">
                            <div className="account-pages">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="login_heading"><img src={logodark} height="45" className="logo logo-dark" /></div>
                                        <input type="email" className="form-control rounded-bordered" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        {get(error, 'email') && <span className='requiredMsg'>{get(error, 'email')}</span>}
                                        <br />
                                        <input type="password" className="form-control rounded-bordered" placeholder="Password" minLength="6" value={password} onChange={(e) => setPassword(e.target.value)} />
                                        {get(error, 'password') && <span className='requiredMsg'>{get(error, 'password')}</span>}
                                        <div className="mt-1 text-right"><a className="text-binary stlink" onClick={() => showforgot()}>Forgot Password?</a></div>
                                        <button className="btn btn-primary btn-block btn-rounded mt-4 stlinkbtn" onClick={login}>SIGN IN</button>
                                        <br />
                                        <br />
                                        <div className="new_acc"><span style={{ color: '#c5c2c2' }}>Do not have an account?</span> <a className="text-binary stlink" onClick={() => showsignup()}>Sign Up</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={forgot} onHide={onHideForgot}>
                    <Modal.Body>
                        <div className="mdl-body">
                            <div className="account-pages">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="login_heading"><img src={logodark} height="45" className="logo logo-dark" /></div>
                                        <input type="email" className="form-control rounded-bordered" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        {get(error, 'email') && <span className='requiredMsg'>{get(error, 'email')}</span>}
                                        <div className="mt-3 mb-3 text-center"><a className="text-binary stlink" onClick={() => sendotp()}>Send Otp</a></div>
                                        <input type="text" className="form-control rounded-bordered" placeholder="Otp" minLength="6" value={otp} onChange={(e) => setOtp(e.target.value)} />
                                        {get(error, 'otp') && <span className='requiredMsg'>{get(error, 'otp')}</span>}
                                        <br />
                                        <input type="password" className="form-control rounded-bordered" placeholder="Password" minLength="6" value={password} onChange={(e) => setPassword(e.target.value)} />
                                        {get(error, 'password') && <span className='requiredMsg'>{get(error, 'password')}</span>}
                                        <br />
                                        <button className="btn btn-primary btn-block btn-rounded mt-4 stlinkbtn" onClick={otpverify}>Reset Password</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={signup} onHide={onHideSignup}>
                    <Modal.Body>
                        <div className="mdl-body">
                            <div className="account-pages">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="login_heading"><img src={logodark} height="45" className="logo logo-dark" /></div>
                                        <input type="text" className="form-control rounded-bordered" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                                        {get(error, 'name') && <span className='requiredMsg'>{get(error, 'name')}</span>}
                                        <br />
                                        <input type="email" className="form-control rounded-bordered" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        {get(error, 'email') && <span className='requiredMsg'>{get(error, 'email')}</span>}
                                        <br />
                                        <input type="password" className="form-control rounded-bordered" placeholder="Password" minLength="6" value={password} onChange={(e) => setPassword(e.target.value)} />
                                        {get(error, 'password') && <span className='requiredMsg'>{get(error, 'password')}</span>}
                                        <br />
                                        <button className="btn btn-primary btn-block btn-rounded mt-4 stlinkbtn" onClick={register}>SIGN UP</button>
                                        <br />
                                        <br />
                                        <div className="new_acc"><span style={{ color: '#c5c2c2' }}>Already have an account?</span> <a className="text-binary stlink" onClick={() => showsignin()}>Sign In</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
    else {
        const show = (menushow) ? "show" : "";
        if (_isAdminUserExist)
            _isUserExist = _isAdminUserExist;
        return <>
            {location.pathname.startsWith('/tours') &&
                <header className="">
                    <nav className="navbar navbar-light nav_custom">
                        <div className="container">
                            <a className="navbar-logo" href="/">
                                <img src={logodark} alt="stitch360" height="50" className="logo logo-dark" />
                            </a>
                            <form className="d-flex">
                                {_isUserExist &&
                                    <>
                                        <ul className="navbarSt ms-auto gap-5" id="topnav-menu">
                                            <li className="">
                                                <a className="active addtourhead" title="Add Tour" onClick={() => addTour()}><img src={plus} height="25" /></a>
                                            </li>
                                            <li className=" ">
                                                <div className="dropdown d-inline-block">
                                                    <a className="btn header-item waves-effect" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => toggleCog()}><img src={setting} height="30" /></a>
                                                    <div className={`dropdown-menu dropdown-menu-end ${togglecog}`}>
                                                        <a className="dropdown-item"><i className="bx bx-user font-size-16 align-middle me-1"></i> <span key="t-profile">{userName}</span></a>
                                                        <div className="dropdown-divider"></div>
                                                        <a className="dropdown-item text-danger" style={{ cursor: 'pointer' }} onClick={() => dispatch(doLogout())}><i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i> <span key="t-logout">Logout</span></a>
                                                    </div>
                                                </div>

                                            </li>
                                        </ul>
                                    </>
                                }
                                {!_isUserExist &&
                                    <>
                                        <div className="my-2 ms-lg-2 sign_button" style={{ display: 'none' }}>
                                            <div className="d-flex flex-wrap gap-3 align-items-center">
                                                {_isUserExist && <span>{userName} | <span className='logout-btn' onClick={() => dispatch(doLogout())}>Sign Out</span></span>}
                                                {!_isUserExist && <><a className="text-binary stlink" onClick={() => showsignin()}>Sign In</a><a className="btn btn-primary waves-effect btn-rounded w-md" onClick={() => showsignup()}>Sign Up</a></>}
                                            </div>
                                        </div>
                                    </>
                                }
                            </form>
                        </div>
                    </nav>
                </header>
            }
            {!location.pathname.startsWith('/tours') &&
                <header className="">
                    <nav className="navbar navbar-expand-lg navigation fixed-top" style={{ backgroundColor: '#eee' }}>
                        <div className="container">
                            <a className="" href="/">
                                <img src={logodark} alt="stitch360" height="45" className="logo logo-dark" />
                            </a>
                            <button type="button" className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light" onClick={toggleMenu}>
                                <i className="fa fa-fw fa-bars"></i>
                            </button>
                            <div className={"collapse navbar-collapse justify-content-center nvbar " + show} >
                                <ul className="navbar-nav" id="topnav-menu" >
                                    <li className="nav-item">
                                        <a className="nav-link" href="#about" style={{ color: '#212529' }}>About</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#features" style={{ color: '#212529' }}>Features</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/pricing" style={{ color: '#212529' }}>Pricing</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/services" style={{ color: '#212529' }}>Services</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link cntct" href="#contact" style={{ color: '#212529' }}>Contact</a>
                                    </li>
                                    {_isAdminUserExist &&
                                        <li className="nav-item">
                                            <a className="nav-link cntct" href="/admin/users" style={{ color: '#212529' }}>Admin Panel</a>
                                        </li>
                                    }
                                    {show && <>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/signin">Sign In</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/signup">Sign Up</NavLink>
                                        </li></>}
                                </ul>
                                {!show && <div className="my-2 ms-lg-2 sign_button" >
                                    <div className="d-flex flex-wrap gap-3 align-items-center">
                                        {_isUserExist && <span>{userName} | <span className='logout-btn' onClick={() => dispatch(doLogout())}>Sign Out</span></span>}
                                        {!_isUserExist && <><a className="text-binary stlink" onClick={() => showsignin()}>Sign In</a><a className="btn btn-primary waves-effect btn-rounded w-md" onClick={() => showsignup()}>Sign Up</a></>}
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </nav>
                </header>
            }
            <Modal show={signin} onHide={onHideSignin}>
                <Modal.Body>
                    <div className="mdl-body">
                        <div className="account-pages">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="login_heading"><img src={logodark} height="45" className="logo logo-dark" /></div>
                                    <input type="email" className="form-control rounded-bordered" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    {get(error, 'email') && <span className='requiredMsg'>{get(error, 'email')}</span>}
                                    <br />
                                    <input type="password" className="form-control rounded-bordered" placeholder="Password" minLength="6" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    {get(error, 'password') && <span className='requiredMsg'>{get(error, 'password')}</span>}
                                    <div className="mt-1 text-right"><a className="text-binary stlink" onClick={() => showforgot()}>Forgot Password?</a></div>
                                    <button className="btn btn-primary btn-block btn-rounded mt-4 stlinkbtn" onClick={login}>SIGN IN</button>
                                    <br />
                                    <br />
                                    <div className="new_acc"><span style={{ color: '#c5c2c2' }}>Do not have an account?</span> <a className="text-binary stlink" onClick={() => showsignup()}>Sign Up</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={forgot} onHide={onHideForgot}>
                <Modal.Body>
                    <div className="mdl-body">
                        <div className="account-pages">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="login_heading"><img src={logodark} height="45" className="logo logo-dark" /></div>
                                    <input type="email" className="form-control rounded-bordered" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    {get(error, 'email') && <span className='requiredMsg'>{get(error, 'email')}</span>}
                                    <div className="mt-3 mb-3 text-center"><a className="text-binary stlink" onClick={() => sendotp()}>Send Otp</a></div>
                                    <input type="text" className="form-control rounded-bordered" placeholder="Otp" minLength="6" value={otp} onChange={(e) => setOtp(e.target.value)} />
                                    {get(error, 'otp') && <span className='requiredMsg'>{get(error, 'otp')}</span>}
                                    <br />
                                    <input type="password" className="form-control rounded-bordered" placeholder="Password" minLength="6" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    {get(error, 'password') && <span className='requiredMsg'>{get(error, 'password')}</span>}
                                    <br />
                                    <button className="btn btn-primary btn-block btn-rounded mt-4 stlinkbtn" onClick={otpverify}>Reset Password</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={signup} onHide={onHideSignup}>
                <Modal.Body>
                    <div className="mdl-body">
                        <div className="account-pages">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="login_heading"><img src={logodark} alt="stitch360" height="45" className="logo logo-dark" /></div>
                                    <input type="text" className="form-control rounded-bordered" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                                    {get(error, 'name') && <span className='requiredMsg'>{get(error, 'name')}</span>}
                                    <br />
                                    <input type="email" className="form-control rounded-bordered" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    {get(error, 'email') && <span className='requiredMsg'>{get(error, 'email')}</span>}
                                    <br />
                                    <input type="password" className="form-control rounded-bordered" placeholder="Password" minLength="6" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    {get(error, 'password') && <span className='requiredMsg'>{get(error, 'password')}</span>}
                                    <br />
                                    <button className="btn btn-primary btn-block btn-rounded mt-4 stlinkbtn" onClick={register}>SIGN UP</button>
                                    <br />
                                    <br />
                                    <div className="new_acc"><span style={{ color: '#c5c2c2' }}>Already have an account?</span> <a className="text-binary stlink" onClick={() => showsignin()}>Sign In</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    }
}

export default Header;