export const registerUserApi = {
    method: "POST",
    url: "user/register"
}
export const loginUserApi = {
    method: "POST",
    url: "user/login"
}
export const getToursApi = {
    method: "GET",
    url: "tours/getall"
}
export const addTourApi = {
    method: "POST",
    url: "tours/add"
}
export const updateTourApi = {
    method: "POST",
    url: "tours/update"
}
export const addTourAllowedApi = {
    method: "GET",
    url: "orders/allowed"
}
export const getUserPlanApi = {
    method: "GET",
    url: "tours/userplan"
}
export const getTourApi = {
    method: "GET"
}
export const deleteTourApi = {
    method: "DELETE"
}
export const getExteriorsApi = {
    method: "GET"
}
export const addExteriorApi = {
    method: "POST",
    url: "tours/addexterior"
}
export const deleteExteriorApi = {
    method: "DELETE"
}
export const addLevelApi = {
    method: "POST",
    url: "tours/addlevel"
}
export const getLevelsApi = {
    method: "GET"
}
export const deleteLevelApi = {
    method: "DELETE"
}
export const addLevelAreaApi = {
    method: "POST",
    url: "tours/addlevelarea"
}
export const getLevelAreasApi = {
    method: "GET"
}
export const deleteLevelAreaApi = {
    method: "DELETE"
}
export const addContactApi = {
    method: "POST",
    url: "home/addcontact"
}
export const getHotSpotsApi = {
    method: "GET"
}
export const updateHotSpotApi = {
    method: "POST",
    url: "tours/updatehotspot"
}
export const getImagesApi = {
    method: "GET",
    url: "user/images"
}
export const feedbackApi = {
    method: "Post",
    url: "user/feedback"
}
export const deleteFeedbackApi = {
    method: "DELETE"
}
export const deleteContactApi = {
    method: "DELETE"
}
export const sendOtpApi = {
    method: "Post",
    url: "user/sendotp"
}
export const verifyOtpApi = {
    method: "Post",
    url: "user/verifyotp"
}

