export const MODAL_TYPE = {
    LOGIN: 'LOGIN',
    REGISTRATION: 'REGISTRATION',
    NONE: 'NONE'
}

export const ROUTES_TYPE = {
    PRIVATE_ROUTES: "PRIVATE_ROUTES",
    PUBLIC_ROUTES: "PUBLIC_ROUTES"
}

export const NOTIFICATION_TYPE = {
    SUCCESS: "success",
    ERROR: "danger"
}