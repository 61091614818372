import React, { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { getToken } from '../redux/actions/authAction';
import Modal from 'react-bootstrap/Modal';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { showLoader } from '../redux/actions/homeAction';
import { services } from '../utils/services';
import { showNotification } from "../redux/actions/notificationAction";
import { NOTIFICATION_TYPE } from "../utils/constant";

function Message({ content }) {
    return <p style={{ fontWeight: 'bold' }}>{content}</p>;
}

const Pricing = () => {
    setTimeout(function () { document.getElementsByClassName('addtourhead')[0].style.visibility = 'hidden'; }, 100);
    const initialOptions = {
        'client-id': 'AY7gUJPYMKN0CCk3NqdAWk7VbmUE_E8GawJ4uNDoF06jgJoLxL-vwxCEAPPXTjvrFPgTwAb6FNGmH9pW',
        'disable-funding': 'credit,card',
        'vault': true,
        'intent': 'subscription'
    };
    const token = getToken();
    const [message, setMessage] = useState('');
    const [showpay, setShowpay] = useState('none');
    const [notShowpay, setNotShowpay] = useState('flex');
    const [formError, setFormError] = useState({});
    const [formname, setFormName] = useState("");
    const [formemail, setFormEmail] = useState("");
    const [formsubject, setFormSubject] = useState("");
    const [formmsg, setFormMsg] = useState("");
    const [showcontact, setShowcontact] = useState(false);
    const dispatch = useDispatch();
    const selectPrice = async (price) => {
        document.getElementById("sltplan").value = price;
        if (price == 0) {
            if (token)
                window.location.href = '/tours';
            else
                document.getElementsByClassName('stlink')[0].click();
        }
        else if (price == 3) {
            setShowcontact(true);
        }
        else {
            if (!token) {
                document.getElementsByClassName('stlink')[0].click();
            }
            else {
                setShowpay('inline-block');
                setNotShowpay('none');
            }
        }
    }
    const validateForm = () => {
        const _error = {};

        if (isEmpty(formname)) {
            _error["formname"] = "This field is required";
        }
        if (isEmpty(formemail)) {
            _error["formemail"] = "This field is required";
        }
        if (isEmpty(formsubject)) {
            _error["formsubject"] = "This field is required";
        }
        if (isEmpty(formmsg)) {
            _error["formmsg"] = "This field is required";
        }

        setFormError(_error);
        if (!isEmpty(_error)) {
            return false;
        }
        return true;
    }
    const saveForm = async () => {
        dispatch(showLoader(true))
        const homeForm = {
            name: formname,
            email: formemail,
            subject: formsubject,
            message: formmsg
        }
        const response = await services.addContactForm(homeForm);
        if (response) {
            setFormName('')
            setFormEmail('')
            setFormSubject('')
            setFormMsg('')
            dispatch(showLoader(false))
            dispatch(showNotification(NOTIFICATION_TYPE.SUCCESS, 'Successfully submitted'))
            setShowcontact(false)
        }
    }
    const onSubmitHandler = () => {
        if (validateForm()) {
            saveForm()
        }
    }
    const onHideShowContact = () => {
        setShowcontact(false)
    }
    return (
        <>
            <div className="container mt5 text-center">
                <p><h3>Flexible Plans & Pricing</h3></p>
                <p>Scale as your business grows, Pick a plan that's best for you.</p>
                <br />
                <div className="row text-left mt-5" style={{ display: notShowpay }}>
                    <div className="col-md-3" style={{ borderRight: '1px solid #ccc' }}>
                        <h4>Free</h4>
                        <h5 className="mt-4">Free Forever</h5>
                        <p>Easiest way to try 360Stitch.<br />No credit card needed.</p>
                        <p className="mt-5"><strong>USD $0</strong>/ forever<br />1 Property</p>
                        <button className="btn btn-primary btn-block btn-rounded mt-4" onClick={() => selectPrice(0)}>Get Started Free</button>
                    </div>
                    <div className="col-md-3" style={{ borderRight: '1px solid #ccc' }}>
                        <h4>Starter</h4>
                        <h5 className="mt-4">Perfect for</h5>
                        <p>Individual or small<br />businesses that need all of the<br />essential features.</p>
                        <p className="mt-4"><strong>USD $9.99</strong>/ month<br />10 Property</p>
                        <button className="btn btn-primary btn-block btn-rounded mt-4" onClick={() => selectPrice(1)}>Select</button>
                    </div>
                    <div className="col-md-3" style={{ borderRight: '1px solid #ccc' }}>
                        <h4>Pro</h4>
                        <h5 className="mt-4">Perfect for</h5>
                        <p>Businesses that need more spaces<br />and enhanced collaboration and<br />integration capabilities.</p>
                        <p className="mt-4"><strong>USD $14.99</strong>/ month<br />50 Property</p>
                        <button className="btn btn-primary btn-block btn-rounded mt-4" onClick={() => selectPrice(2)}>Select</button>
                    </div>
                    <div className="col-md-3">
                        <h4>Enterprise</h4>
                        <h5 className="mt-4">Perfect for</h5>
                        <p>Organizations that needs<br />custom features & full scale<br />customised solution</p>
                        <p className="mt-4"><strong>let's talk and figure out</strong><br />Unlimited Properties</p>
                        <button className="btn btn-primary btn-block btn-rounded mt-4" onClick={() => selectPrice(3)}>Contact Sales</button>
                        <input type="hidden" id="sltplan" name="sltplan" />
                    </div>
                </div>
                <div style={{ display: showpay, height: '100px', width:'400px' }}>
                    <PayPalScriptProvider options={initialOptions}>
                        <PayPalButtons
                            style={{
                                shape: 'rect',
                                color: 'gold',
                                layout: 'horizontal', //default value. Can be changed to horizontal
                            }}
                            createSubscription={(data, actions) => {
                                try {
                                    let sltplani = parseInt(document.getElementById("sltplan").value);
                                    let plan_id = 'P-55Y20073XN8499627MU66S5Y';
                                    if (sltplani == 2)
                                        plan_id = 'P-4KR46379FT848060YMU66TQI';
                                    return actions.subscription.create({
                                        'plan_id': plan_id // Creates the subscription
                                    });
                                } catch (error) {
                                    setMessage(
                                        `Could not initiate PayPal Subscription...${error}`,
                                    );
                                }
                            }}
                            onApprove={async (data, actions) => {
                                try {
                                    const response = await fetch(
                                        `/api/orders/capture?pid=${parseInt(document.getElementById("sltplan").value)}&sid=${data.subscriptionID}`,
                                        {
                                            method: 'POST',
                                            headers: {
                                                'Authorization': 'Bearer ' + token,
                                                'Content-Type': 'application/json',
                                            },
                                        },
                                    );

                                    const orderData = await response.json();
                                    const responsedetail = orderData?.details?.[0];
                                    if (responsedetail?.description == 'success') {
                                        setMessage(
                                            `You have successfully subscribed to ${data.subscriptionID}. Redirecting in 5 seconds....`,
                                        );
                                        setTimeout(function () { window.location.href = '/tours' }, 5000);
                                    }
                                    else {
                                        const errorMessage = responsedetail
                                            ? `${responsedetail.issue} ${responsedetail.description} (${orderData.debug_id})`
                                            : JSON.stringify(orderData);

                                        throw new Error(errorMessage);
                                    }
                                } catch (error) {
                                    setMessage(
                                        `Sorry, your transaction could not be processed...${error}`,
                                    );
                                }
                            }}
                        />
                    </PayPalScriptProvider>
                    <Message content={message} />
                </div>
                <div className="mt-5">
                    <p>360 Stitch is the quick and easy way to create immersive virtual walkthroughs of any spaces in minutes.</p>
                    <p>Our cloud-based web app allows you to build, view and share interactive tours from anywhere with an <br />internet connection. Plus, automatic backup means your work is never lost.</p>
                    <p>Whether you're looking to sell a property, promote a venue , or just share your space with the world, 360 Stitch<br /> is the budget-friendly tool that can instantly turn your panoramic images into an engaging experience that<br /> anyone can view.</p>
                </div>
            </div>
            <br />
            <br />
            <Modal show={showcontact} onHide={onHideShowContact}>
                <Modal.Body>
                    <h5 className="modal-title">Contact Us</h5>
                    <div className="mdl-body">
                        <input type="text" className="form-control rounded-bordered mb-4" placeholder="Name" value={formname}
                            onChange={(e) => setFormName(e.target.value)} />
                        {
                            get(formError, 'formname') &&
                            <span className='requiredMsg'>
                                {get(formError, 'formname')}
                            </span>
                        }
                        <input type="email" className="form-control rounded-bordered mb-4" placeholder="Email" value={formemail}
                            onChange={(e) => setFormEmail(e.target.value)} />
                        {
                            get(formError, 'formemail') &&
                            <span className='requiredMsg'>
                                {get(formError, 'formemail')}
                            </span>
                        }
                        <input type="text" className="form-control rounded-bordered mb-4" placeholder="Subject line" value={formsubject}
                            onChange={(e) => setFormSubject(e.target.value)} />
                        {
                            get(formError, 'formsubject') &&
                            <span className='requiredMsg'>
                                {get(formError, 'formsubject')}
                            </span>
                        }
                        <textarea className="form-control rounded-bordered mb-4" rows="3" placeholder="Type message here..." value={formmsg}
                            onChange={(e) => setFormMsg(e.target.value)}></textarea>
                        {
                            get(formError, 'formmsg') &&
                            <span className='requiredMsg'>
                                {get(formError, 'formmsg')}
                            </span>
                        }
                        <button onClick={() => onSubmitHandler()} className="btn btn-primary btn-block btn-rounded mt-4">
                            Submit
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Pricing;