import { React, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Login from '../pages/login';
import Register from '../pages/register';
import Home from '../pages/home';
import Tours from '../pages/tours';
import Tour from '../pages/tour';
import Privacy from '../pages/privacy';
import Services from '../pages/services';
import Cookies from '../pages/cookies';
import Copyright from '../pages/copyright';
import Terms from '../pages/terms';
import UserAgreement from '../pages/useragreement';
import Pricing from '../pages/pricing';
import TourView from '../pages/tourview';
import TourvEditor from '../pages/tourveditor';
import TourvEditor1 from '../pages/tourveditor1';
import PrivateRoutes from '../routes/privateRoutes';
import AdminRoutes from '../routes/adminRoutes';
import Users from '../pages/admin/users';
import FeedBacks from '../pages/admin/feedbacks';
import Contacts from '../pages/admin/contacts';

const routes = [
    {
        path: "/signin",
        component: Login,
        exact: true
    },
    {
        path: "/signup",
        component: Register,
        exact: true
    },
    {
        path: "/tourview/:slug",
        component: TourView,
        exact: true
    },
    {
        path: "/tourveditor1/:slug",
        component: TourvEditor1,
        exact: true
    },
    {
        path: "/privacy",
        component: Privacy,
        exact: true
    },
    {
        path: "/services",
        component: Services,
        exact: true
    },
    {
        path: "/cookies",
        component: Cookies,
        exact: true
    },
    {
        path: "/copyright",
        component: Copyright,
        exact: true
    },
    {
        path: "/terms",
        component: Terms,
        exact: true
    },
    {
        path: "/useragreement",
        component: UserAgreement,
        exact: true
    },
    {
        path: "/pricing",
        component: Pricing,
        exact: true
    },
    {
        path: "/",
        component: Home,
        exact: true
    }
];

const privateRoutesContant = [
    {
        path: "/tours",
        component: Tours,
        exact: true
    },
    {
        path: "/tour/:slug",
        component: Tour,
        exact: true
    },
    {
        path: "/tourveditor/:slug",
        component: TourvEditor,
        exact: true
    }
];

const adminRoutesContant = [
    {
        path: "/admin/users",
        component: Users,
        exact: true
    },
    {
        path: "/admin/feedbacks",
        component: FeedBacks,
        exact: true
    },
    {
        path: "/admin/contacts",
        component: Contacts,
        exact: true
    }
];

const AppRoutes = () => {
    const location = useLocation();
    useEffect(() => {
        window.scroll(0, 0)
    }, [location]);
    return (
        <Routes>
            {
                privateRoutesContant.map((route, index) => {
                    return (
                        <Route
                            key={index}
                            path={route.path}
                            exact={true}
                            element={<PrivateRoutes>
                                <route.component />
                            </PrivateRoutes>
                            } />
                    )
                })
            }
            {
                adminRoutesContant.map((route, index) => {
                    return (
                        <Route
                            key={index}
                            path={route.path}
                            exact={true}
                            element={<AdminRoutes>
                                <route.component />
                            </AdminRoutes>
                            } />
                    )
                })
            }
            {
                routes.map((route, index) => {
                    return (
                        <Route
                            key={index}
                            path={route.path}
                            exact={true}
                            element={<route.component />} />
                    )
                })
            }
            <Route
                path="*"
                element={<Navigate to="/" />}
            />
        </Routes>
    )
}

export default AppRoutes;