import { httpRequest } from '../utils/axios';
import {
    registerUserApi,
    loginUserApi,
    getToursApi,
    addTourApi,
    updateTourApi,
    getTourApi,
    addTourAllowedApi,
    deleteTourApi,
    getExteriorsApi,
    addExteriorApi,
    deleteExteriorApi,
    addLevelApi,
    getLevelsApi,
    deleteLevelApi,
    addLevelAreaApi,
    getLevelAreasApi,
    deleteLevelAreaApi,
    addContactApi,
    getHotSpotsApi,
    updateHotSpotApi,
    getImagesApi,
    getUserPlanApi,
    feedbackApi,
    deleteFeedbackApi,
    deleteContactApi,
    sendOtpApi,
    verifyOtpApi
} from './repository';

class Services {
    registerUser(param) {
        registerUserApi.data = param;
        return httpRequest(registerUserApi).then(response => {
            return response;
        }).catch((e) => {
            return e;
        });
    }
    loginUser(param) {
        loginUserApi.data = param;
        return httpRequest(loginUserApi).then(response => {
            return response;
        }).catch((e) => {
            return e;
        });
    }
    getTours(param) {
        return httpRequest(getToursApi).then(response => {
            if (response.status) {
                return response.data.data;
            }
            return [];
        }).catch(e => {
            return [];
        })
    }
    getTour(tid) {
        getTourApi.url = `tours/gettour?tourid=${tid}`;
        return httpRequest(getTourApi).then(response => {
            if (response.status) {
                return response.data.data;
            }
            return [];
        }).catch(e => {
            return [];
        })
    }
    addTour(param) {
        addTourApi.data = param;
        return httpRequest(addTourApi).then(response => {
            if (response.status) {
                return response.data.data;
            }
            return [];
        }).catch(e => {
            return [];
        })
    }
    addtourallowed() {
        return httpRequest(addTourAllowedApi).then(response => {
            if (response.status) {
                return response.data.data;
            }
            return [];
        }).catch(e => {
            return [];
        })
    }    
    updateTour(param) {
        updateTourApi.data = param;
        return httpRequest(updateTourApi).then(response => {
            if (response.status) {
                return response.data.data;
            }
            return [];
        }).catch(e => {
            return [];
        })
    }
    deleteTour(tid) {
        deleteTourApi.url = `tours/delete?tourid=${tid}`;
        return httpRequest(deleteTourApi).then(response => {
            if (response.status) {
                return response.data.data;
            }
            return [];
        }).catch(e => {
            return [];
        })
    }
    getExteriors(tid) {
        getExteriorsApi.url = `tours/getallexteriors?tourid=${tid}`;
        return httpRequest(getExteriorsApi).then(response => {
            if (response.status) {
                return response.data.data;
            }
            return [];
        }).catch(e => {
            return [];
        })
    }
    addExterior(param) {
        addExteriorApi.data = param;
        return httpRequest(addExteriorApi).then(response => {
            if (response.status) {
                return response.data.data;
            }
            return [];
        }).catch(e => {
            return [];
        })
    }
    deleteExterior(tid) {
        deleteExteriorApi.url = `tours/deleteexterior?id=${tid}`;
        return httpRequest(deleteExteriorApi).then(response => {
            if (response.status) {
                return response.data.data;
            }
            return [];
        }).catch(e => {
            return [];
        })
    }
    addLevel(param) {
        addLevelApi.data = param;
        return httpRequest(addLevelApi).then(response => {
            if (response.status) {
                return response.data.data;
            }
            return [];
        }).catch(e => {
            return [];
        })
    }
    getLevels(tid) {
        getLevelsApi.url = `tours/getalllevels?tourid=${tid}`;
        return httpRequest(getLevelsApi).then(response => {
            if (response.status) {
                return response.data.data;
            }
            return [];
        }).catch(e => {
            return [];
        })
    }
    deleteLevel(tid) {
        deleteLevelApi.url = `tours/deletelevel?id=${tid}`;
        return httpRequest(deleteLevelApi).then(response => {
            if (response.status) {
                return response.data.data;
            }
            return [];
        }).catch(e => {
            return [];
        })
    }
    addLevelArea(param) {
        addLevelAreaApi.data = param;
        return httpRequest(addLevelAreaApi).then(response => {
            if (response.status) {
                return response.data.data;
            }
            return [];
        }).catch(e => {
            return [];
        })
    }
    getLevelAreas(lid) {
        getLevelAreasApi.url = `tours/getalllevelareas?levelid=${lid}`;
        return httpRequest(getLevelAreasApi).then(response => {
            if (response.status) {
                return response.data.data;
            }
            return [];
        }).catch(e => {
            return [];
        })
    }
    deleteLevelArea(tid) {
        deleteLevelAreaApi.url = `tours/deletelevelarea?id=${tid}`;
        return httpRequest(deleteLevelAreaApi).then(response => {
            if (response.status) {
                return response.data.data;
            }
            return [];
        }).catch(e => {
            return [];
        })
    }
    addContactForm(param) {
        addContactApi.data = param;
        return httpRequest(addContactApi).then(response => {
            if (response.status) {
                return response.data.data;
            }
            return [];
        }).catch(e => {
            return [];
        })
    }
    getHotSpots(id) {
        getHotSpotsApi.url = `tours/gethotspots?id=${id}`;
        return httpRequest(getHotSpotsApi).then(response => {
            if (response.status) {
                return response.data.data;
            }
            return [];
        }).catch(e => {
            return [];
        })
    }
    updateHotSpot(param) {
        updateHotSpotApi.data = param;
        return httpRequest(updateHotSpotApi).then(response => {
            if (response.status) {
                return response.data.data;
            }
            return [];
        }).catch(e => {
            return [];
        })
    }
    getImages() {
        return httpRequest(getImagesApi).then(response => {
            if (response.status) {
                return response.data.data;
            }
            return [];
        }).catch(e => {
            return [];
        })
    }
    getUserPlan() {
        return httpRequest(getUserPlanApi).then(response => {
            if (response.status) {
                return response.data.data;
            }
            return [];
        }).catch(e => {
            return [];
        })
    }
    addFeedback(param) {
        feedbackApi.data = param;
        return httpRequest(feedbackApi).then(response => {
            return response;
        }).catch((e) => {
            return e;
        });
    }
    deleteFeedback(id) {
        deleteFeedbackApi.url = `admin/deletefeedback?id=${id}`;
        return httpRequest(deleteFeedbackApi).then(response => {
            if (response.status) {
                return response.data.data;
            }
            return [];
        }).catch(e => {
            return [];
        })
    }
    deleteContact(id) {
        deleteContactApi.url = `admin/deletecontact?id=${id}`;
        return httpRequest(deleteContactApi).then(response => {
            if (response.status) {
                return response.data.data;
            }
            return [];
        }).catch(e => {
            return [];
        })
    }
    sendOtp(param) {
        sendOtpApi.data = param;
        return httpRequest(sendOtpApi).then(response => {
            return response;
        }).catch((e) => {
            return e;
        });
    }
    verifyOtp(param) {
        verifyOtpApi.data = param;
        return httpRequest(verifyOtpApi).then(response => {
            return response;
        }).catch((e) => {
            return e;
        });
    }
}

export const services = new Services();