import React, { useState, useEffect, useRef } from 'react';
import { showLoader } from '../redux/actions/homeAction';
import { useDispatch } from 'react-redux';
import { services } from '../utils/services';
import { useParams } from 'react-router-dom';
import { LIVE_IMAGE_URL } from '../utils/globalConfig';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Modal from 'react-bootstrap/Modal';
import TourExterior from '../pages/tourexterior';
import TourInterior from '../pages/tourinterior';
import TourLevel from '../pages/tourlevel';
import logolight from '../assets/images/logo-light.png';

const TourvEditor = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const dataFetchedRef = useRef(false);
    const [propname, setPropName] = useState("");
    const [viewlist, setViewList] = useState([]);
    const [viewlist1, setViewList1] = useState([]);
    const [exteriorslist, setExteriorsList] = useState([]);
    const [levelslist, setLevelsList] = useState([]);
    const [vivetypeexterior, setViveTypeExterior] = useState(true);
    const [levelname, setLevelName] = useState(0);
    const [level, setLevel] = useState({});
    const [viewInfoDetail, setViewInfoDetail] = useState(false);
    const [tour, setTour] = useState({});
    const [destpitch, setDestPitch] = useState(0);
    const [destyaw, setDestYaw] = useState(0);
    const [destRotpitch, setDestRotPitch] = useState(0);
    const [destRotyaw, setDestRotYaw] = useState(0);
    const [destRotHof, setDestRotHof] = useState(0);
    const [slidervalue, setSliderValue] = useState(1);
    const [selectdestimg, setSelectDestImg] = useState(false);
    const [destimgvalue, setDestImgValue] = useState({});
    const [isGetHotSpots, setisGetHotSpots] = useState(false);
    const [hotspotsimages, setHotSpotsImages] = useState({});
    const [destFloor, setDestFloor] = useState("-1");
    const [destFloorList, setDestFloorList] = useState([]);
    const [isedit, setIsEdit] = useState(false);
    const [currentview, setCurrentView] = useState(0);
    const [rightevent, setRightEvent] = useState({});
    const [dorefresh, setDoRefresh] = useState(false);
    const [destinationRotation, setDestinationRotation] = useState(false);

    if (!isGetHotSpots) {
        services.getHotSpots(parseInt(params.slug)).then(result => {
            if (result != undefined) {
                var images = JSON.parse(result.hotspotImages);
                setHotSpotsImages(images);
            }
            setisGetHotSpots(true);
        });
    }

    useEffect(() => {
        if (dataFetchedRef.current || !isGetHotSpots) return;
        dataFetchedRef.current = true;
        //https://pannellum.org/documentation/examples/tour/

        var panoramadiv = document.getElementById("panorama");
        panoramadiv.style.height = window.innerHeight + "px";

        document.body.style.overflow = 'hidden';

        const link = document.createElement("link")
        link.type = 'text/css'
        link.rel = "stylesheet";
        link.href = "https://cdn.jsdelivr.net/npm/pannellum@2.5.6/build/pannellum.css"
        document.head.appendChild(link)
        const script1 = document.createElement("script")
        script1.type = 'text/javascript'
        script1.src = "https://cdn.jsdelivr.net/npm/pannellum@2.5.6/build/pannellum.js"
        script1.async = false
        script1.onload = function () {
            setTimeout(() => {
                dispatch(showLoader(true))
                services.getTour(parseInt(params.slug)).then(result => {
                    dispatch(showLoader(false))
                    setPropName(get(result, 'propertyName'))
                    setTour(result)
                    var floorsList = [];
                    if (result.id > 0) {
                        if (result.exteriors?.length > 0) {
                            floorsList.push({ id: '-1', name: 'Exterior' });
                            setExteriorsList(result.exteriors)
                            setViewList(result.exteriors)
                            setViveTypeExterior(true)
                            if (result.levels?.length > 0) {
                                var resultlevels = result.levels;
                                resultlevels.sort(function (a, b) {
                                    if (a.levelName < b.levelName) return -1;
                                    if (a.levelName > b.levelName) return 1;
                                    return 0;
                                }).reverse()
                                if (resultlevels.length > 0)
                                    setLevelName(resultlevels[0].levelName)
                                setLevelsList(resultlevels)
                                for (let i = 0; i < resultlevels.length; i++) {
                                    floorsList.push({ id: '' + resultlevels[i].id + '', name: 'Level ' + resultlevels[i].levelName });
                                }
                            }
                            selectElement(result.exteriors[0].id)
                            OpenPanorama(get(result.exteriors[0], 'id'), result.exteriors, 110, -3, 117, true)
                        }
                        else if (result.levels?.length > 0) {
                            setViveTypeExterior(false)
                            var resultlevels = result.levels;
                            resultlevels.sort(function (a, b) {
                                if (a.levelName < b.levelName) return -1;
                                if (a.levelName > b.levelName) return 1;
                                return 0;
                            }).reverse()
                            setLevelsList(resultlevels)
                            changevivelevel(null, resultlevels[0])
                            setLevelName(resultlevels[0].levelName)
                            for (let i = 0; i < resultlevels.length; i++) {
                                floorsList.push({ id: '' + resultlevels[i].id + '', name: 'Level ' + resultlevels[i].levelName });
                            }
                        }
                        setDestFloorList(floorsList)
                    }
                })
            }, 500);
        }
        document.head.appendChild(script1)
        setTimeout(() => { if (typeof panoramaviewer === 'undefined' && exteriorslist.length > 0) { window.location.reload(); } }, 2000);

        const contextMenu = document.getElementById("context-menu");
        const scope = document.querySelector("body");

        scope.addEventListener("contextmenu", (event) => {
            event.preventDefault();

            const { clientX: mouseX, clientY: mouseY } = event;

            contextMenu.style.top = `${mouseY}px`;
            contextMenu.style.left = `${mouseX}px`;

            contextMenu.classList.add("visible");

            setRightEvent(event);
        });
        scope.addEventListener("click", (e) => {
            contextMenu.classList.remove("visible");
        });
    }, [hotspotsimages, isGetHotSpots])
    const changeview = (event, view) => {
        if (event.target.className.indexOf('st-pencil-trash') < 0) {
            selectElement(get(view, 'id'));
            window.panoramaviewer.loadScene(get(view, 'id'), -3, 117, 110)
        }
    }
    const changevivetype = () => {
        if (vivetypeexterior) {
            setViveTypeExterior(false)
            if (levelslist != undefined && levelslist.length > 0) {
                setLevel(levelslist[levelslist.length - 1]);
                setViewList(levelslist[levelslist.length - 1].levelAreas)
                setLevelName(levelslist[levelslist.length - 1].levelName)
                selectElement(get(levelslist[levelslist.length - 1].levelAreas[0], 'id'))
                OpenPanorama(get(levelslist[levelslist.length - 1].levelAreas[0], 'id'), levelslist[levelslist.length - 1].levelAreas, 110, -3, 117, false)
            }
            else {
                setLevel({});
                setViewList([]);
                setLevelName(0);
            }
        }
        else {
            setViveTypeExterior(true)
            setViewList(exteriorslist)
            selectElement(get(exteriorslist[0], 'id'))
            OpenPanorama(get(exteriorslist[0], 'id'), exteriorslist, 110, -3, 117, true)
        }
    }
    const changevivelevel = (event, level) => {
        if (event == null || event.target.className.indexOf('st-pencil-trash') < 0) {
            setLevel(level);
            setViewList(level.levelAreas)
            setLevelName(level.levelName)
            selectElement(get(level.levelAreas[0], 'id'))
            OpenPanorama(get(level.levelAreas[0], 'id'), level.levelAreas, 110, -3, 117, vivetypeexterior)
        }
    }
    const viewInfo = () => {
        setViewInfoDetail(true)
    }
    const hideViewInfo = () => {
        setViewInfoDetail(false)
    }
    const selectElement = (id) => {
        setCurrentView(id);
        setTimeout(() => {
            var elements = document.getElementsByClassName("custom_img_v");
            for (let i = 0; i < elements.length; i++) {
                elements[i].setAttribute("class", "custom_img_v");
            }
            if (document.getElementById("sv" + id) != undefined)
                document.getElementById("sv" + id).setAttribute("class", "custom_img_v active");
        }, 500)

    }
    const gethotSpots = (id, isexterior) => {
        var result = [];
        if (hotspotsimages[id] != null) {
            hotspotsimages[id].map((item, index) => {
                if (item.vivetypeexterior == undefined || isexterior == item.vivetypeexterior) {
                    result.push({
                        "pitch": item.pitch,
                        "yaw": item.yaw,
                        "type": "scene",
                        "text": item.view.areaName,
                        "sceneId": item.view.id,
                        "cssClass": "pnlm-hotspot-base custom-hotspot htslider" + item.slider,
                        "createTooltipFunc": hotspotfunc,
                        "createTooltipArgs": item.view.areaName + '|' + item.pitch + '|' + item.yaw + '|' + id + '|' + item.view.id + '|' + item.view.levelID + '|' + item.destrotpitch + '|' + item.destrotyaw + '|' + item.destrotHof
                    })
                }
            })
        }
        return result;
    }
    const hotspotfunc = (hotSpotDiv, args) => {
        hotSpotDiv.classList.add('custom-tooltip');
        hotSpotDiv.setAttribute('sceneid', args.split('|')[4]);
        if (args.split('|')[5] != 'undefined' && args.split('|')[5] != undefined)
            hotSpotDiv.setAttribute('levelid', args.split('|')[5]);
        else
            hotSpotDiv.setAttribute('levelid', '-1000');

        if (args.split('|')[6] != 'undefined' && args.split('|')[6] != undefined)
            hotSpotDiv.setAttribute('destrotpitch', args.split('|')[6]);

        if (args.split('|')[7] != 'undefined' && args.split('|')[7] != undefined)
            hotSpotDiv.setAttribute('destrotyaw', args.split('|')[7]);

        if (args.split('|')[8] != 'undefined' && args.split('|')[8] != undefined)
            hotSpotDiv.setAttribute('destrotHof', args.split('|')[8]);

        var span = document.createElement('span');
        span.innerHTML = args.split('|')[0] + '<div><div class="editarrow" pitch="' + args.split('|')[1] + '" yaw="' + args.split('|')[2] + '" view="' + args.split('|')[3] + '">Edit</div></div>';
        hotSpotDiv.appendChild(span);
        span.style.width = span.scrollWidth - 20 + 'px';
        span.style.marginLeft = -(span.scrollWidth + 35) / 2 + 'px';
        span.style.marginTop = -span.scrollHeight - 12 + 'px';
    }
    const OpenPanorama = (image, viewlistitems, hfov, pitch, yaw, isexterior) => {

        if (image != undefined) {
            const boxes = document.querySelectorAll('.pnlm-hotspot-base');
            boxes.forEach(box => { box.remove(); });

            var scenes = {};
            for (let i = 0; i < viewlistitems.length; i++) {
                let _imageUrl = viewlistitems[i].areaImage
                if (viewlistitems[i].areaImage.indexOf('http') < 0)
                    _imageUrl = `${LIVE_IMAGE_URL}${viewlistitems[i].areaImage}`
                scenes[viewlistitems[i].id] = {
                    "type": "equirectangular",
                    "panorama": '' + _imageUrl + '',
                    "hfov": hfov,
                    "pitch": pitch,
                    "yaw": yaw,
                    "hotSpots": gethotSpots(viewlistitems[i].id, isexterior)
                }
            }
            window.panoramaviewer = window.pannellum.viewer('panorama', {
                "default": {
                    "firstScene": image,
                    "sceneFadeDuration": 1000,
                    "autoLoad": true,
                    "showControls": false
                },
                "scenes": scenes
            });
            window.panoramaviewer.on('scenechange',
                function () {
                    selectElement(window.panoramaviewer.getScene())
                }
            );
            window.panoramaviewer.on('load',
                function () {
                    const scope = document.getElementsByClassName("editarrow");
                    if (scope.length > 0) {
                        services.getTour(parseInt(params.slug)).then(result => {
                            for (let i = 0; i < scope.length; i++) {
                                var v = scope[i];
                                v.addEventListener("click", (e) => {
                                    e.stopPropagation();
                                    var pitch = e.target.getAttribute("pitch");
                                    var yaw = e.target.getAttribute("yaw");
                                    var cview = e.target.getAttribute("view");
                                    setDestPitch(pitch);
                                    setDestYaw(yaw);
                                    setSelectDestImg(true);
                                    setIsEdit(true);
                                    hotspotsimages[cview].map((vv, kk) => {
                                        if (vv.pitch == pitch && vv.yaw == yaw) {
                                            setTimeout(() => { setSlider(vv.slider); }, 100);
                                            selectDestImage(vv.view);
                                            if (vv.view.levelID == undefined) {
                                                setViewList1(result.exteriors);
                                                setDestFloor('-1');
                                            }
                                            else {
                                                for (let i = 0; i < result.levels.length; i++) {
                                                    if (result.levels[i].id == vv.view.levelID) {
                                                        setViewList1(result.levels[i].levelAreas);
                                                        setDestFloor(result.levels[i].id);
                                                    }
                                                }
                                            }
                                        }
                                    })
                                });
                            }
                            const scopehotspot = document.getElementsByClassName("custom-hotspot");
                            for (let i = 0; i < scopehotspot.length; i++) {
                                var v = scopehotspot[i];
                                v.addEventListener("click", (e) => {
                                    var sceneid = parseInt(e.target.getAttribute('sceneid'));
                                    var levelid = parseInt(e.target.getAttribute('levelid'));

                                    var destrotpitch = e.target.getAttribute('destrotpitch');
                                    if (destrotpitch == undefined)
                                        destrotpitch = -3;
                                    else
                                        destrotpitch = parseFloat(destrotpitch);

                                    var destrotyaw = e.target.getAttribute('destrotyaw');
                                    if (destrotyaw == undefined)
                                        destrotyaw = 117;
                                    else
                                        destrotyaw = parseFloat(destrotyaw);

                                    var destrotHof = e.target.getAttribute('destrotHof');
                                    if (destrotHof == undefined)
                                        destrotHof = 110;
                                    else
                                        destrotHof = parseFloat(destrotHof);

                                    if (result.exteriors?.length > 0) {
                                        var scenefound = false;
                                        for (let ii = 0; ii < result.exteriors.length; ii++) {
                                            if (result.exteriors[ii].id == sceneid) {
                                                scenefound = true;
                                                break;
                                            }
                                        }
                                        if ((!scenefound && levelid != -1000) || (scenefound && levelid != -1000)) {
                                            setViveTypeExterior(false)
                                            for (let i = 0; i < result.levels.length; i++) {
                                                if (result.levels[i].id == levelid) {
                                                    setLevel(result.levels[i]);
                                                    setViewList(result.levels[i].levelAreas)
                                                    setLevelName(result.levels[i].levelName)
                                                    selectElement(sceneid)
                                                    OpenPanorama(sceneid, result.levels[i].levelAreas, destrotHof, destrotpitch, destrotyaw, false)
                                                }
                                            }
                                        }
                                        else if (scenefound && levelid == -1000) {
                                            setViveTypeExterior(true)
                                            setViewList(result.exteriors)
                                            selectElement(sceneid)
                                            OpenPanorama(sceneid, result.exteriors, destrotHof, destrotpitch, destrotyaw, true)
                                        }
                                    }
                                })
                            }
                        })
                    }
                }
            );
        }
    }
    const setDestFloorItem = (item) => {
        setDestFloor(item);
        if (item == "-1") {
            setViewList1(exteriorslist);
            selectDestImage(exteriorslist[0]);
        }
        else {
            for (let i = 0; i < levelslist.length; i++) {
                if (levelslist[i].id == parseInt(item)) {
                    setViewList1(levelslist[i].levelAreas);
                    selectDestImage(levelslist[i].levelAreas[0]);
                }
            }
        }
    }
    const addNewArrow = (event) => {
        var coords = window.panoramaviewer.mouseEventToCoords(rightevent);
        setDestPitch(coords[0]);
        setDestYaw(coords[1]);
        if (document.getElementsByClassName('lvlshow').length == 0)
            setDestFloorItem("-1");
        else
            setDestFloorItem(document.getElementsByClassName('lvlshow')[0].classList[2]);
        setSelectDestImg(true);
    }
    const hideselectdestimg = () => {
        setIsEdit(false);
        setSelectDestImg(false);
        setDestImgValue({});
        setSliderValue(1);
        setDestinationRotation(false);
    }
    const selectDestImage = (view) => {
        setTimeout(() => {
            var elements = document.getElementsByClassName("custom_img_v1");
            for (let i = 0; i < elements.length; i++) {
                elements[i].setAttribute("class", "custom_img_v1");
            }
            if (document.getElementById("svi" + view.id) != undefined)
                document.getElementById("svi" + view.id).setAttribute("class", "custom_img_v1 active");
        }, 100)
        setDestImgValue(view);
    }
    const setSlider = (val) => {
        setSliderValue(val);
        var element = document.getElementsByClassName("custom-hotspotpop");
        element[0].className = 'custom-hotspotpop htslider' + val;
    }
    const onSubmitSetDestinationRotation = () => {
        setDestinationRotation(true);
        setTimeout(function () {
            let _imageUrl = destimgvalue.areaImage
            if (destimgvalue.areaImage.indexOf('http') < 0)
                _imageUrl = `${LIVE_IMAGE_URL}${destimgvalue.areaImage}`
            window.panoramaviewerdest = window.pannellum.viewer('panoramadest', {
                "type": "equirectangular",
                "panorama": '' + _imageUrl + '',
                "autoLoad": true
            });
        }, 500);
    }
    const onSubmitSelectDestRotateHandler = () => {
        if (window.panoramaviewerdest != undefined) {
            setDestRotHof(window.panoramaviewerdest.getHfov());
            setDestRotPitch(window.panoramaviewerdest.getPitch());
            setDestRotYaw(window.panoramaviewerdest.getYaw());
        }
        setDestinationRotation(false);
    }
    const onSubmitSelectDestHandlerDelete = () => {
        var currhotspotsimages = hotspotsimages;
        if (currhotspotsimages[currentview] != null) {
            currhotspotsimages[currentview] = currhotspotsimages[currentview].filter(v => v.pitch != parseFloat(destpitch) && v.yaw != parseFloat(destyaw));
        }
        dispatch(showLoader(true))
        const hotspotForm = {
            id: parseInt(params.slug),
            hotspotImages: JSON.stringify(currhotspotsimages)
        }
        const response = services.updateHotSpot(hotspotForm);
        if (response) {
            setHotSpotsImages(currhotspotsimages);
            hideselectdestimg();
            window.panoramaviewer.destroy();
            if (vivetypeexterior) {
                OpenPanorama(currentview, exteriorslist, window.panoramaviewer.getHfov(), window.panoramaviewer.getPitch(), window.panoramaviewer.getYaw(), vivetypeexterior);
            }
            else {
                OpenPanorama(currentview, level.levelAreas, window.panoramaviewer.getHfov(), window.panoramaviewer.getPitch(), window.panoramaviewer.getYaw(), vivetypeexterior);
            }
        }
        dispatch(showLoader(false))
    }
    const onSubmitSelectDestHandler = () => {
        var item = { 'pitch': parseFloat(destpitch), 'yaw': parseFloat(destyaw), 'view': destimgvalue, 'slider': parseInt(slidervalue), 'destrotpitch': destRotpitch, 'destrotyaw': destRotyaw, 'destrotHof': destRotHof, 'vivetypeexterior': vivetypeexterior };
        var currhotspotsimages = hotspotsimages;
        if (currhotspotsimages[currentview] != null) {
            var added = false;
            for (let i = 0; i < currhotspotsimages[currentview].length; i++) {
                var val = currhotspotsimages[currentview][i];
                if (val.pitch == item.pitch && val.yaw == item.yaw) {
                    currhotspotsimages[currentview][i] = item;
                    added = true;
                }
            }
            if (!added)
                currhotspotsimages[currentview].push(item);
        }
        else
            currhotspotsimages[currentview] = [item];

        dispatch(showLoader(true))
        const hotspotForm = {
            id: parseInt(params.slug),
            hotspotImages: JSON.stringify(currhotspotsimages)
        }
        const response = services.updateHotSpot(hotspotForm);
        if (response) {
            setHotSpotsImages(currhotspotsimages);
            hideselectdestimg();
            window.panoramaviewer.destroy();
            if (vivetypeexterior) {
                OpenPanorama(currentview, exteriorslist, window.panoramaviewer.getHfov(), window.panoramaviewer.getPitch(), window.panoramaviewer.getYaw(), vivetypeexterior);
            }
            else {
                OpenPanorama(currentview, level.levelAreas, window.panoramaviewer.getHfov(), window.panoramaviewer.getPitch(), window.panoramaviewer.getYaw(), vivetypeexterior);
            }
        }
        dispatch(showLoader(false))
    }
    const UpdateExteriors = (exterior, type) => {
        let exteriors = exteriorslist
        if (type == 'add')
            exteriors.push(exterior)
        else if (type == 'update') {
            let index = -1;
            for (let i = 0; i < exteriors.length; i++) {
                if (exteriors[i].id == exterior.id)
                    index = i;
            }
            exteriors[index] = exterior;
        }
        else
            exteriors = exteriors.filter(item => item.id != exterior.id)
        setExteriorsList(exteriors)
        setViewList(exteriors)
        setViewList1(exteriors)
        setDoRefresh(!dorefresh)
        OpenPanorama(exterior.id, exteriors, 110, -3, 117, vivetypeexterior)
        setTimeout(function () {
            let ctl = document.getElementById('sv' + exterior.id)
            if (ctl != undefined)
                ctl.click()
            else if (exteriors.length > 0)
                document.getElementById('sv' + exteriors[0].id).click()
        }, 100)
    }
    const UpdateInteriors = (levelArea, type) => {
        let levelindex = -1;
        for (let i = 0; i < levelslist.length; i++) {
            if (levelslist[i].id == levelArea.levelID)
                levelindex = i;
        }
        let level = levelslist[levelindex]
        if (level.levelAreas == null)
            level.levelAreas = []
        if (type == 'add')
            level.levelAreas.push(levelArea)
        else if (type == 'update') {
            let index = -1;
            for (let i = 0; i < level.levelAreas.length; i++) {
                if (level.levelAreas[i].id == levelArea.id)
                    index = i;
            }
            level.levelAreas[index] = levelArea;
        }
        else
            level.levelAreas = level.levelAreas.filter(item => item.id != levelArea.id)
        levelslist[levelindex] = level
        setLevelsList(levelslist)
        setViewList(level.levelAreas)
        setViewList1(level.levelAreas)
        setDoRefresh(!dorefresh)
        OpenPanorama(levelArea.id, level.levelAreas, 110, -3, 117, vivetypeexterior)
        setTimeout(function () { document.getElementById('sv' + levelArea.id).click() }, 100)
    }
    const UpdateLevels = (level, type) => {
        let floorsList = destFloorList
        let levels = levelslist
        if (type == 'add') {
            levels.push(level)
            floorsList.push({ id: '' + level.id + '', name: 'Level ' + level.levelName })
        }
        else if (type == 'update') {
            let index = -1;
            for (let i = 0; i < levels.length; i++) {
                if (levels[i].id == level.id)
                    index = i;
            }
            levels[index] = level;
            for (let i = 0; i < floorsList.length; i++) {
                if (floorsList[i].id == '' + level.id + '')
                    index = i;
            }
            floorsList[index] = { id: '' + level.id + '', name: 'Level ' + level.levelName };
        }
        else {
            levels = levels.filter(item => item.id != level.id)
            floorsList = floorsList.filter(item => item.id != '' + level.id + '')
        }
        levels.sort(function (a, b) {
            if (a.levelName < b.levelName) return -1;
            if (a.levelName > b.levelName) return 1;
            return 0;
        }).reverse()
        setLevelsList(levels)
        if (document.getElementsByClassName('lvlname').length == 1) {
            setTimeout(function () { document.getElementsByClassName('lvlname')[1].click() }, 200);
        }
        setDestFloorList(floorsList)
        setDoRefresh(!dorefresh)
    }
    return (
        <>
            <div>
                <div id="context-menu">
                    <button onClick={event => addNewArrow(event)} className="btn btn-hotspot btn-rounded">Add New Arrow</button>
                </div>
                <div className="tv-name"><div>{propname}</div></div>
                <div id="panorama"></div>
                <div className="tv-list">
                    {!vivetypeexterior ?
                        <div className="">
                            <TourLevel levelslength={levelslist.length} level={null} parentCallback={(level, type) => { UpdateLevels(level, type) }} />
                            {
                                !isEmpty(levelslist) &&
                                levelslist.map((level, index) => {
                                    return <div key={get(level, 'id')} className={"lvlname " + (level.levelName == levelname ? 'lvlshow ' + get(level, 'id') : '')} onClick={(event) => changevivelevel(event, level)}><span className="custom_img_border_span_level">Level {get(level, 'levelName')}</span> <TourLevel levelslength={levelslist.length} level={level} parentCallback={(level, type) => { UpdateLevels(level, type) }} /></div>
                                })
                            }
                        </div>
                        : <></>}
                    <div className="">
                        {vivetypeexterior ? <i className="st-exterior viewswitch" aria-hidden="true" onClick={() => changevivetype()}></i>
                            : <i className="st-interior viewswitch" aria-hidden="true" onClick={() => changevivetype()}></i>}
                    </div>
                    <div className="custom_t">
                        <div className="custom_12r">
                            {
                                !isEmpty(viewlist) &&
                                viewlist.map((view, index) => {
                                    let _imageUrl = get(view, 'areaImage')
                                    if (get(view, 'areaImage').indexOf('http') < 0)
                                        _imageUrl = `${LIVE_IMAGE_URL}${get(view, 'areaImage')}`
                                    return <div key={get(view, 'id')} id={"sv" + get(view, "id")} className="custom_img_v" style={{
                                        backgroundImage: 'url("' + _imageUrl.replace('.png', 'thumb.png').replace('.jpeg', 'thumb.jpeg') + '")',
                                    }} onClick={(event) => changeview(event, view)}>
                                        {vivetypeexterior ? <TourExterior exterior={view} parentCallback={(exterior, type) => { UpdateExteriors(exterior, type) }} />
                                            : <TourInterior interior={view} level={level} parentCallback={(interior, type) => { UpdateInteriors(interior, type) }} />}
                                    </div>
                                })
                            }
                            {vivetypeexterior ? <TourExterior exterior={null} parentCallback={(exterior, type) => { UpdateExteriors(exterior, type) }} /> : <TourInterior interior={null} level={level} parentCallback={(interior, type) => { UpdateInteriors(interior, type) }} />}
                        </div>
                    </div>
                </div>
                <div className="tv-name-bottom">Powered by <img src={logolight} alt="stitch360" height="30" className="logo" /></div>
            </div>
            <Modal show={viewInfoDetail} onHide={hideViewInfo}>
                <Modal.Body>
                    <h5 className="modal-title">Property Details</h5>
                    <div className="mdl-body">
                        <div className="bx_12">
                            <p className=""><i className="st-pin">    </i> {get(tour, 'propertyAddress') ? get(tour, 'propertyAddress') : '-'}</p>
                            <p className=""><i className="st-resize"> </i> {get(tour, 'propertySize') ? get(tour, 'propertySize') : '-'}</p>
                            <p className=""><i className="st-flats">  </i> {get(tour, 'purchaseType') ? get(tour, 'purchaseType') : '-'}</p>
                            <p className=""><i className="st-cash">   </i> {get(tour, 'price') ? get(tour, 'price') : '-'}</p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal size="lg" show={selectdestimg} onHide={hideselectdestimg}>
                <Modal.Body>
                    <div>
                        <h5 className={"modal-title " + (!destinationRotation ? 'showdv' : 'hiddendv')}>Select Destination Image</h5>
                        <h5 className={"modal-title " + (destinationRotation ? 'showdv' : 'hiddendv')}>Set Destination Rotation</h5>
                        <select className={"form-control rounded-bordered sltfloor " + (!destinationRotation ? 'showdv' : 'hiddendv')} value={destFloor} onChange={(e) => setDestFloorItem(e.target.value)}>
                            {
                                destFloorList.map((floor, index) => {
                                    return <option key={get(floor, 'id')} value={floor.id}>{floor.name}</option>
                                })
                            }
                        </select>
                        <div className="mdl-body">
                            <div className={"mdl-dest " + (!destinationRotation ? 'showdv' : 'hiddendv')}>
                                {
                                    !isEmpty(viewlist1) &&
                                    viewlist1.map((view, index) => {
                                        let _imageUrl = get(view, 'areaImage')
                                        if (get(view, 'areaImage').indexOf('http') < 0)
                                            _imageUrl = `${LIVE_IMAGE_URL}${get(view, 'areaImage')}`
                                        return <div key={get(view, 'id')} id={"svi" + get(view, "id")} className="custom_img_v1" style={{
                                            backgroundImage: 'url("' + _imageUrl.replace('.png', 'thumb.png').replace('.jpeg', 'thumb.jpeg') + '")',
                                        }} onClick={() => selectDestImage(view)}>
                                            <span className="custom_img_v1_span">{get(view, 'areaName')}</span>
                                        </div>
                                    })
                                }
                            </div>
                            <div className={" " + (destinationRotation ? 'showdv' : 'hiddendv')} id="panoramadest" style={{ height: '400px' }}></div>
                            <br className={" " + (!destinationRotation ? 'showdv' : 'hiddendv')} />
                            <button onClick={() => onSubmitSetDestinationRotation()} className={"btn btn-primary btn-rounded " + (!destinationRotation ? 'showdv' : 'hiddendv')}>Set Destination Rotation</button>
                            <br className={" " + (!destinationRotation ? 'showdv' : 'hiddendv')} />
                            <br className={" " + (!destinationRotation ? 'showdv' : 'hiddendv')} />
                            <h5 className={"modal-title " + (!destinationRotation ? 'showdv' : 'hiddendv')}>Set Arrow Rotation</h5>
                            <br className={" " + (!destinationRotation ? 'showdv' : 'hiddendv')} />
                            <div className={"htslider1 custom-hotspotpop " + (!destinationRotation ? 'showdvi' : 'hiddendv')}></div>
                            <br className={" " + (!destinationRotation ? 'showdv' : 'hiddendv')} />
                            <input type="range" min="1" max="8" className={"slider " + (!destinationRotation ? 'showdv' : 'hiddendv')} value={slidervalue} onChange={(e) => setSlider(e.target.value)} />
                            <br className={" " + (!destinationRotation ? 'showdv' : 'hiddendv')} />
                            <div style={{ float: 'right', marginTop: '50px' }}>
                                {isedit && <button onClick={() => onSubmitSelectDestHandlerDelete()} className={"btn btn-danger btn-rounded " + (!destinationRotation ? 'showdvi' : 'hiddendv')} style={{ marginRight: '5px' }}>Delete</button>}
                                <button onClick={() => onSubmitSelectDestHandler()} className={"btn btn-primary btn-rounded " + (!destinationRotation ? 'showdvi' : 'hiddendv')}>Update</button>
                                <button onClick={() => onSubmitSelectDestRotateHandler()} className={"btn btn-primary btn-rounded " + (destinationRotation ? 'showdvi' : 'hiddendv')}>Update</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default TourvEditor;