import React from 'react';

const Copyright = () => {
    return (
        <div className="container mt5">
            <h1>LLC Copyright Policy</h1>
            <>
                <p
                    style={{
                        color: "rgb(0, 0, 0)",
                        background: "transparent",
                        lineHeight: "100%",
                        marginTop: "0.08in",
                        marginBottom: "0in",
                        textAlign: "justify"
                    }}
                >
                    <strong>Reporting Claims of Copyright Infringement</strong>
                </p>
                <p
                    style={{
                        color: "rgb(0, 0, 0)",
                        background: "transparent",
                        lineHeight: "100%",
                        marginTop: "0.08in",
                        marginBottom: "0in",
                        textAlign: "justify"
                    }}
                >
                    We take claims of copyright infringement seriously. We will respond to
                    notices of alleged copyright infringement that comply with applicable law.
                    If you believe any materials accessible on or from this site (the "
                    <strong>Website</strong>") infringe your copyright, you may request removal
                    of those materials (or access to them) from the Website by submitting
                    written notification to our copyright agent designated below. In accordance
                    with the Online Copyright Infringement Liability Limitation Act of the
                    Digital Millennium Copyright Act (17 U.S.C. § 512) ("<strong>DMCA</strong>
                    "), the written notice (the "<strong>DMCA Notice</strong>") must include
                    substantially the following:
                </p>
                <ul>
                    <li>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                background: "transparent",
                                lineHeight: "100%",
                                marginTop: "0.08in",
                                marginBottom: "0.08in",
                                textAlign: "justify"
                            }}
                        >
                            Your physical or electronic signature.
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                background: "transparent",
                                lineHeight: "100%",
                                marginTop: "0.08in",
                                marginBottom: "0.08in",
                                textAlign: "justify"
                            }}
                        >
                            Identification of the copyrighted work you believe to have been
                            infringed or, if the claim involves multiple works on the Website, a
                            representative list of such works.
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                background: "transparent",
                                lineHeight: "100%",
                                marginTop: "0.08in",
                                marginBottom: "0.08in",
                                textAlign: "justify"
                            }}
                        >
                            Identification of the material you believe to be infringing in a
                            sufficiently precise manner to allow us to locate that material.
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                background: "transparent",
                                lineHeight: "100%",
                                marginTop: "0.08in",
                                marginBottom: "0.08in",
                                textAlign: "justify"
                            }}
                        >
                            Adequate information by which we can contact you (including your name,
                            postal address, telephone number, and, if available, email address).
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                background: "transparent",
                                lineHeight: "100%",
                                marginTop: "0.08in",
                                marginBottom: "0.08in",
                                textAlign: "justify"
                            }}
                        >
                            A statement that you have a good faith belief that use of the
                            copyrighted material is not authorized by the copyright owner, its
                            agent, or the law.
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                background: "transparent",
                                lineHeight: "100%",
                                marginTop: "0.08in",
                                marginBottom: "0.08in",
                                textAlign: "justify"
                            }}
                        >
                            A statement that the information in the written notice is accurate.
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                background: "transparent",
                                lineHeight: "100%",
                                marginTop: "0.08in",
                                marginBottom: "0.08in",
                                textAlign: "justify"
                            }}
                        >
                            A statement, under penalty of perjury, that you are authorized to act on
                            behalf of the copyright owner.
                        </p>
                    </li>
                </ul>
                <p
                    style={{
                        color: "rgb(0, 0, 0)",
                        background: "transparent",
                        lineHeight: "100%",
                        marginTop: "0.08in",
                        marginBottom: "0in",
                        textAlign: "justify"
                    }}
                >
                    Our designated copyright agent to receive DMCA Notices is:
                </p>
                <table style={{ width: "92%" }} cellPadding={7}>
                    <tbody>
                        <tr>
                            <td style={{ border: "none", padding: "0in", verticalAlign: "top" }}>
                                <p
                                    style={{
                                        color: "#000000",
                                        lineHeight: "115%",
                                        marginBottom: "0.1in",
                                        background: "transparent",
                                        marginTop: "0.08in"
                                    }}
                                >
                                    [INSERT AGENT INFORMATION]
                                    <sup>
                                        <a
                                            className="sdfootnoteanc"
                                            name="sdfootnote1anc"
                                            href="#sdfootnote1sym"
                                        >
                                            <sup>1</sup>
                                        </a>
                                    </sup>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p
                    style={{
                        color: "rgb(0, 0, 0)",
                        background: "transparent",
                        lineHeight: "100%",
                        marginTop: "0.08in",
                        marginBottom: "0in",
                        textAlign: "justify"
                    }}
                >
                    If you fail to comply with all of the requirements of Section 512(c)(3) of
                    the DMCA, your DMCA Notice may not be effective.
                </p>
                <p
                    style={{
                        color: "rgb(0, 0, 0)",
                        background: "transparent",
                        lineHeight: "100%",
                        marginTop: "0.08in",
                        marginBottom: "0in",
                        textAlign: "justify"
                    }}
                >
                    Please be aware that if you knowingly materially misrepresent that material
                    or activity on the Website is infringing your copyright, you may be held
                    liable for damages (including costs and attorneys' fees) under Section
                    512(f) of the DMCA.
                </p>
                <p
                    style={{
                        color: "rgb(0, 0, 0)",
                        background: "transparent",
                        lineHeight: "100%",
                        marginTop: "0.08in",
                        marginBottom: "0in",
                        textAlign: "justify"
                    }}
                >
                    <strong>Counter Notification Procedures</strong>
                </p>
                <p
                    style={{
                        color: "rgb(0, 0, 0)",
                        background: "transparent",
                        lineHeight: "100%",
                        marginTop: "0.08in",
                        marginBottom: "0in",
                        textAlign: "justify"
                    }}
                >
                    If you believe that material you posted on the Website was removed or access
                    to it was disabled by mistake or misidentification, you may file a counter
                    notification with us (a "<strong>Counter Notice</strong>") by submitting
                    written notification to our copyright agent designated above. Pursuant to
                    the DMCA, the Counter Notice must include substantially the following:
                </p>
                <ul>
                    <li>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                background: "transparent",
                                lineHeight: "100%",
                                marginTop: "0.08in",
                                marginBottom: "0.08in",
                                textAlign: "justify"
                            }}
                        >
                            Your physical or electronic signature.
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                background: "transparent",
                                lineHeight: "100%",
                                marginTop: "0.08in",
                                marginBottom: "0.08in",
                                textAlign: "justify"
                            }}
                        >
                            An identification of the material that has been removed or to which
                            access has been disabled and the location at which the material appeared
                            before it was removed or access disabled.&nbsp;
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                background: "transparent",
                                lineHeight: "100%",
                                marginTop: "0.08in",
                                marginBottom: "0.08in",
                                textAlign: "justify"
                            }}
                        >
                            Adequate information by which we can contact you (including your name,
                            postal address, telephone number, and, if available, email address).
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                background: "transparent",
                                lineHeight: "100%",
                                marginTop: "0.08in",
                                marginBottom: "0.08in",
                                textAlign: "justify"
                            }}
                        >
                            A statement under penalty of perjury by you that you have a good faith
                            belief that the material identified above was removed or disabled as a
                            result of a mistake or misidentification of the material to be removed
                            or disabled.
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                color: "rgb(0, 0, 0)",
                                background: "transparent",
                                lineHeight: "100%",
                                marginTop: "0.08in",
                                marginBottom: "0.08in",
                                textAlign: "justify"
                            }}
                        >
                            A statement that you will consent to the jurisdiction of the Federal
                            District Court for the judicial district in which your address is
                            located (or if you reside outside the United States for any judicial
                            district in which the Website may be found) and that you will accept
                            service from the person (or an agent of that person) who provided the
                            Website with the complaint at issue.
                        </p>
                    </li>
                </ul>
                <p
                    style={{
                        color: "rgb(0, 0, 0)",
                        background: "transparent",
                        lineHeight: "100%",
                        marginTop: "0.08in",
                        marginBottom: "0in",
                        textAlign: "justify"
                    }}
                >
                    The DMCA allows us to restore the removed content if the party filing the
                    original DMCA Notice does not file a court action against you within ten
                    business days of receiving the copy of your Counter Notice.
                </p>
                <p
                    style={{
                        color: "rgb(0, 0, 0)",
                        background: "transparent",
                        lineHeight: "100%",
                        marginTop: "0.08in",
                        marginBottom: "0in",
                        textAlign: "justify"
                    }}
                >
                    Please be aware that if you knowingly materially misrepresent that material
                    or activity on the Website was removed or disabled by mistake or
                    misidentification, you may be held liable for damages (including costs and
                    attorneys' fees) under Section 512(f) of the DMCA.
                </p>
                <p
                    style={{
                        color: "rgb(0, 0, 0)",
                        background: "transparent",
                        lineHeight: "100%",
                        marginTop: "0.08in",
                        marginBottom: "0in",
                        textAlign: "justify"
                    }}
                >
                    <strong>Repeat Infringers</strong>
                </p>
                <p
                    style={{
                        color: "rgb(0, 0, 0)",
                        background: "transparent",
                        lineHeight: "100%",
                        marginTop: "0.08in",
                        marginBottom: "0in",
                        textAlign: "justify"
                    }}
                >
                    It is our policy in appropriate circumstances to disable and/or terminate
                    the accounts of users who are repeat infringers.
                </p>
                <div id="sdfootnote1">
                    <p
                        style={{
                            color: "#000000",
                            lineHeight: "100%",
                            marginBottom: "0in",
                            background: "transparent",
                            fontFamily: '"Arial", sans-serif',
                            fontSize: 13,
                            marginTop: "0in"
                        }}
                    >
                        <a
                            name="sdfootnote1sym"
                            href="#sdfootnote1anc"
                            style={{ color: "#000000" }}
                        >
                            1
                        </a>{" "}
                        Note to Client: Please consider who should be the designated copyright
                        agent for the company. This can be an employee of the company or the
                        company can obtain the services of a DMCA agent service provider. The
                        agent would have to register with the Copyright Office and provide the
                        above information.&nbsp;
                    </p>
                    <p></p>
                </div>
            </>

        </div>
    );
}

export default Copyright;