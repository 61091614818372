import React from 'react';

const Cookies = () => {
    return (
        <div className="container mt5">
            <h1>LLC’S Cookie Policy</h1>
            <>
                <p
                    style={{
                        lineHeight: "108%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        fontFamily: '"Times New Roman", serif'
                    }}
                >
                    <span size={3} style={{ fontSize: 16 }}>
                        Last updated: September 22, 2023
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "108%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        fontFamily: '"Times New Roman", serif'
                    }}
                >
                    <span size={3} style={{ fontSize: 16 }}>
                        This cookies policy provides information regarding the use of cookies and
                        other tracking technologies by 360STITCH.COM, LLC (“360STITCH” or “our” or
                        “us” or “we”) when you visit our websites or make an inquiry or booking
                        with us or any of our affiliated brands through our websites or
                        applications (the “Online Services”).&nbsp;
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "108%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        fontFamily: '"Times New Roman", serif'
                    }}
                >
                    <span size={3} style={{ fontSize: 16 }}>
                        By using our Online Services, whether directly or indirectly through third
                        party websites, you agree to the use of cookies and other tracking
                        technologies.
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "108%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        fontFamily: '"Times New Roman", serif'
                    }}
                >
                    <span size={3} style={{ fontSize: 16 }}>
                        <u>
                            <strong>What are cookies?</strong>
                        </u>
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "108%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        fontFamily: '"Times New Roman", serif'
                    }}
                >
                    <span size={3} style={{ fontSize: 16 }}>
                        A cookie is a small file placed on the hard drive of your computer.
                        Cookies perform a variety of tasks, such as allowing you to navigate
                        efficiently between pages, remembering your preferences, and generally
                        improving the user experience. Cookies and tracking technologies may also
                        be used to help ensure that our web content, emails, and advertising
                        better suit you and your interests. There are two main types of
                        cookies:&nbsp;
                    </span>
                </p>
                <ul>
                    <li>
                        <p
                            style={{
                                lineHeight: "108%",
                                textAlign: "justify",
                                marginBottom: "0.17in",
                                background: "transparent"
                            }}
                        >
                            “
                            <span size={3} style={{ fontSize: 16 }}>
                                Session cookies” are temporary cookies that are deleted once you close
                                your browser. Session cookies are commonly used by websites to
                                maintain information about a user’s activity during a single browsing
                                session.&nbsp;
                            </span>
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                lineHeight: "108%",
                                textAlign: "justify",
                                marginBottom: "0.17in",
                                background: "transparent"
                            }}
                        >
                            “
                            <span size={3} style={{ fontSize: 16 }}>
                                Persistent cookies” are cookies that remain on your device even after
                                you close your browser. Persistent cookies can be used to store
                                information that can be accessed across multiple browsing sessions
                                such as language preferences and other customized settings.&nbsp;
                            </span>
                        </p>
                    </li>
                </ul>
                <p
                    style={{
                        lineHeight: "108%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        fontFamily: '"Times New Roman", serif'
                    }}
                >
                    <span size={3} style={{ fontSize: 16 }}>
                        <u>
                            <strong>
                                Why do we use cookies and other tracking technologies?&nbsp;
                            </strong>
                        </u>
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "108%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        fontFamily: '"Times New Roman", serif'
                    }}
                >
                    <span size={3} style={{ fontSize: 16 }}>
                        We may use cookies and other tracking technologies such as beacons, tags,
                        and scripts to collect and track information to improve and analyze our
                        Online Services, to store your preferences, to enable the delivery of
                        advertisements, or for advertising purposes as explained in our privacy
                        policy located at [
                    </span>
                    <span size={3} style={{ fontSize: 16 }}>
                        <a href="https://360stitch.com/privacy">Privacy Policy</a>
                    </span>
                    <span size={3} style={{ fontSize: 16 }}>
                        ].
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "108%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        fontFamily: '"Times New Roman", serif'
                    }}
                >
                    <span size={3} style={{ fontSize: 16 }}>
                        <u>
                            <strong>
                                Do third parties use cookies or other tracking technologies?&nbsp;
                            </strong>
                        </u>
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "108%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        fontFamily: '"Times New Roman", serif'
                    }}
                >
                    <span size={3} style={{ fontSize: 16 }}>
                        Some content or applications, including advertisements, on our Online
                        Services are served by third parties, including advertisers, ad networks
                        and servers, content providers, and application providers. These third
                        parties may use cookies alone or in conjunction with web beacons or other
                        tracking technologies to collect information about you when you use our
                        Online Services. The information they collect may be associated with your
                        personal information or they may collect information, including personal
                        information, about your online activities over time and across different
                        websites and other online services. They may use this information to
                        provide you with interest-based (behavioral) advertising or other targeted
                        content.&nbsp;
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "108%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        fontFamily: '"Times New Roman", serif'
                    }}
                >
                    <span size={3} style={{ fontSize: 16 }}>
                        We do not control these third parties' tracking technologies or how they
                        may be used. If you have any questions about an advertisement or other
                        targeted content, you should contact the responsible provider directly.
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "108%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        fontFamily: '"Times New Roman", serif'
                    }}
                >
                    <span size={3} style={{ fontSize: 16 }}>
                        <u>
                            <strong>Which cookies do we use and why do we use them?</strong>
                        </u>
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "108%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        fontFamily: '"Times New Roman", serif'
                    }}
                >
                    <span size={3} style={{ fontSize: 16 }}>
                        We may use the following types of cookies to provide our Online Services
                        to you:
                    </span>
                </p>
                <ul>
                    <li>
                        <p
                            style={{
                                lineHeight: "108%",
                                textAlign: "justify",
                                marginBottom: "0.17in",
                                background: "transparent",
                                fontFamily: '"Times New Roman", serif'
                            }}
                        >
                            <span size={3} style={{ fontSize: 16 }}>
                                <u>
                                    <strong>Essential Cookies:</strong>
                                </u>
                            </span>
                            <span size={3} style={{ fontSize: 16 }}>
                                &nbsp;These cookies are necessary for the Online Services to function
                                and cannot be switched off in our systems. They are usually only set
                                in response to actions made by you that amount to a request for
                                services, such as setting your privacy preferences, logging in, or
                                filling in forms. You may be able to set your browser to block or
                                alert you about these cookies, but without these cookies certain
                                services will not be available. These cookies do not store any
                                personally identifiable information.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                lineHeight: "108%",
                                textAlign: "justify",
                                marginBottom: "0.17in",
                                background: "transparent",
                                fontFamily: '"Times New Roman", serif'
                            }}
                        >
                            <span size={3} style={{ fontSize: 16 }}>
                                <u>
                                    <strong>Performance Cookies:</strong>
                                </u>
                            </span>
                            <span size={3} style={{ fontSize: 16 }}>
                                <strong>&nbsp;</strong>
                            </span>
                            <span size={3} style={{ fontSize: 16 }}>
                                These cookies allow us to count visits and traffic sources so we can
                                measure and improve the performance of our Online Services. They help
                                us to know which pages are the most and least popular and see how
                                visitors move around the site. These cookies do not collect
                                information that personally identifies a visitor. All information
                                collected by these cookies is aggregated and therefore anonymous. If
                                you do not allow these cookies, then we will not know when you have
                                visited our site and will not be able to monitor its performance.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                lineHeight: "108%",
                                textAlign: "justify",
                                marginBottom: "0.17in",
                                background: "transparent",
                                fontFamily: '"Times New Roman", serif'
                            }}
                        >
                            <span size={3} style={{ fontSize: 16 }}>
                                <u>
                                    <strong>Functional Cookies:</strong>
                                </u>
                            </span>
                            <span size={3} style={{ fontSize: 16 }}>
                                <strong>&nbsp;</strong>
                            </span>
                            <span size={3} style={{ fontSize: 16 }}>
                                These cookies allow our Online Services to save choices you make (such
                                as your username, language, or country you live in) and provide
                                enhanced or personalized features. These cookies can also be used to
                                save changes you make to the text size, font, or other parts of the
                                web page that you can customize. They can also be used to provide
                                services that you have requested, such as watching a video or
                                commenting on a blog. The information collected by these cookies can
                                be anonymized and cannot track your browsing behavior on other Online
                                Services. If you do not allow these cookies, some or all of these
                                services may not function properly.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                lineHeight: "108%",
                                textAlign: "justify",
                                marginBottom: "0.17in",
                                background: "transparent",
                                fontFamily: '"Times New Roman", serif'
                            }}
                        >
                            <span size={3} style={{ fontSize: 16 }}>
                                <u>
                                    <strong>Advertising Cookies:</strong>
                                </u>
                            </span>
                            <span size={3} style={{ fontSize: 16 }}>
                                <strong>&nbsp;</strong>
                            </span>
                            <span size={3} style={{ fontSize: 16 }}>
                                These cookies are used to provide advertisements that better suit you
                                and your interests or to enable you to interact with our social media
                                presence and share our content with your friends and networks. They
                                are also used to limit the number of circuits of an advertisement and
                                to measure the effectiveness of the advertising campaign. These
                                cookies may track your browser across other sites and may be used by
                                us and other companies to build a profile of your interests and show
                                you relevant content and advertisements on other sites.
                            </span>
                        </p>
                    </li>
                </ul>
                <p
                    style={{
                        lineHeight: "108%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        fontFamily: '"Times New Roman", serif'
                    }}
                >
                    <span size={3} style={{ fontSize: 16 }}>
                        <u>
                            <strong>What are your choices regarding cookies?&nbsp;</strong>
                        </u>
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "108%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        fontFamily: '"Times New Roman", serif'
                    }}
                >
                    <span size={3} style={{ fontSize: 16 }}>
                        You can set your browser to refuse all or some cookies at any time or to
                        alert you when cookies are being sent. If you disable or refuse cookies,
                        please note that some parts of our Online Services may then be
                        inaccessible or not function properly. To delete cookies or instruct your
                        web browser to delete or refuse cookies, please visit the help pages of
                        your web browser. Unless you have adjusted your browser setting so that it
                        will refuse cookies, our system will issue cookies when you direct your
                        browser to our Online Services.
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "108%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        fontFamily: '"Times New Roman", serif'
                    }}
                >
                    <span size={3} style={{ fontSize: 16 }}>
                        You can learn more about cookies at the following third-party websites
                        (note that these links will take you to other sites and we are not
                        responsible for the information on these other sites or the setting of
                        cookies and tracking by these third parties):
                    </span>
                </p>
                <ul>
                    <li>
                        <p
                            style={{
                                lineHeight: "108%",
                                textAlign: "justify",
                                marginBottom: "0.17in",
                                background: "transparent"
                            }}
                        >
                            <span size={3} style={{ fontSize: 16 }}>
                                AllAboutCookies:&nbsp;
                            </span>
                            <span style={{ color: "rgb(5, 99, 193)" }}>
                                <u>
                                    <a href="http://www.allaboutcookies.org/">
                                        http://www.allaboutcookies.org/
                                    </a>
                                </u>
                            </span>
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                lineHeight: "108%",
                                textAlign: "justify",
                                marginBottom: "0.17in",
                                background: "transparent"
                            }}
                        >
                            <span size={3} style={{ fontSize: 16 }}>
                                Network Advertising Initiative:&nbsp;
                            </span>
                            <span style={{ color: "rgb(5, 99, 193)" }}>
                                <u>
                                    <a href="http://www.networkadvertising.org/">
                                        http://www.networkadvertising.org/
                                    </a>
                                </u>
                            </span>
                        </p>
                    </li>
                </ul>
                <p
                    style={{
                        lineHeight: "108%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        fontFamily: '"Times New Roman", serif'
                    }}
                >
                    <span size={3} style={{ fontSize: 16 }}>
                        If you have any questions about our use of cookies or other tracking
                        technologies, please contact us at support@360stitch.com.
                    </span>
                </p>
                <p></p>
            </>

        </div>
    );
}

export default Cookies;