import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { getToken } from '../../redux/actions/authAction';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { services } from '../../utils/services';
import { curryRight } from 'lodash';
const MySwal = withReactContent(Swal);

const FeedBacks = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(20);
    const dataFetchedRef = useRef(false);
    const token = getToken();
    const columns = [
        {
            width: '2%',
            cell: row => (
                <i className="bx bx-trash" style={{ color: 'red', cursor: 'pointer' }} aria-hidden="true" onClick={() => deleteRow(row)}></i>
            )
        },
        {
            name: 'UserName',
            width: '20%',
            selector: row => row.userName
        },
        {
            name: 'UserEmail',
            width: '20%',
            selector: row => row.userEmail
        },
        {
            name: 'CreatedOn',
            width: '20%',
            selector: row => row.createdOn
        },
        {
            name: 'Message',
            width: '40%',
            selector: row => row.message
        }
    ];
    const fetchFeedbacks = async page => {
        setLoading(true);
        const response = await axios.create({
            baseURL: '/api/admin/',
            headers: { 'Authorization': 'Bearer ' + token }
        }).get(`feedbacks?page=${page}&per_page=${perPage}`);
        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    };
    const handlePageChange = page => {
        fetchFeedbacks(page);
    };
    const deleteRow = (row) => {
        Swal.fire({
            title: 'Are you sure?',
            showCancelButton: true,
            confirmButtonColor: 'red',
            confirmButtonText: 'Delete',
            width: 300,
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await services.deleteFeedback(row.id);
                if (response) {
                    fetchFeedbacks(1);
                }
            }
        })

    };
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);

        const response = await axios.create({
            baseURL: '/api/admin/',
            headers: { 'Authorization': 'Bearer ' + token }
        }).get(`feedbacks?page=${page}&per_page=${perPage}`);

        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };
    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetchFeedbacks(1);
    }, []);
    return (
        <div className="container mt5">
            <hr />
            <div className="row adminmenu" style={{ margin: 0 }}>
                <div className="col-lg-12"><a href="/admin/users" style={{ marginRight: 10, padding: 10 }}>Users</a><a href="/admin/feedbacks" style={{ marginRight: 10, padding: 10 }} className="active">Feedbacks</a><a href="/admin/contacts" style={{ marginRight: 10, padding: 10 }}>Contacts</a></div>
            </div>
            <hr />
            <DataTable
                title="Feedbacks"
                columns={columns}
                data={data}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
            />
            <br />
            <br />
        </div>
    );
}

export default FeedBacks;