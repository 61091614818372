import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { getToken } from '../../redux/actions/authAction';

const Users = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(20);
    const dataFetchedRef = useRef(false);
    const token = getToken();
    const columns = [
        {
            name: 'Name',
            selector: row => row.name
        },
        {
            name: 'Email',
            selector: row => row.email
        },
        {
            name: 'Plan',
            selector: row => row.plan
        },
        {
            name: 'CreatedOn',
            selector: row => row.createdOn
        },
    ];
    const fetchUsers = async page => {
        setLoading(true);
        const response = await axios.create({
            baseURL: '/api/admin/',
            headers: { 'Authorization': 'Bearer ' + token }
        }).get(`users?page=${page}&per_page=${perPage}`);
        setData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    };
    const handlePageChange = page => {
        fetchUsers(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);

        const response = await axios.create({
            baseURL: '/api/admin/',
            headers: { 'Authorization': 'Bearer ' + token }
        }).get(`users?page=${page}&per_page=${perPage}`);

        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };
    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetchUsers(1);
    }, []);
    return (
        <div className="container mt5">
            <hr />
            <div className="row adminmenu" style={{ margin: 0 }}>
                <div className="col-lg-12"><a href="/admin/users" style={{ marginRight: 10, padding: 10 }} className="active">Users</a><a href="/admin/feedbacks" style={{ marginRight: 10, padding: 10 }}>Feedbacks</a><a href="/admin/contacts" style={{ marginRight: 10, padding: 10 }}>Contacts</a></div>
            </div>
            <hr />
            <DataTable
                title="Users"
                columns={columns}
                data={data}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
            />
            <br />
            <br />
        </div>
    );
}

export default Users;