import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import { services } from '../utils/services';
import { showLoader } from '../redux/actions/homeAction';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const TourInterior = (props) => {
    const dispatch = useDispatch();
    const params = useParams();
    const [levelerror, setLevelError] = useState({});
    const [addnewlevelarea, setAddNewLevelArea] = useState(false);
    const [addnewlevelareaLocal, setAddNewLevelAreaLocal] = useState(false);
    const [addnewlevelareaCloud, setAddNewLevelAreaCloud] = useState(false);
    const [showerror, setShowError] = useState(false);
    const [levelareaname, setLevelAreaName] = useState("");
    const [addLAreaText, setAddLAreaText] = useState("Add New Interior Image");
    const [levelareaimage, setLevelAreaImage] = useState("");
    const [levelAreaID, setLevelAreaID] = useState(0);
    const [cloudimages, setCloudImages] = useState([]);
    const addLevelArea = () => {
        if (props.level.id != undefined) {
            setAddNewLevelArea(true)
            setLevelAreaName("")
            setAddLAreaText("Add New Interior Image")
            setLevelAreaImage("");
            setLevelAreaID(0)
            setLevelError({})
        }
        else
            setShowError(true);
    }
    const onChangeImageLevel = (e, name) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            let inputData = reader.result;
            let replaceValue = inputData.split(",")[0];
            let base64String = inputData.replace(replaceValue + ".", "");
            setLevelAreaImage(base64String);
        }
    }
    const onHideLArea = () => {
        setAddNewLevelArea(false)
    }
    const onHideLocal = () => {
        setAddNewLevelAreaLocal(false)
    }
    const onHideCloud = () => {
        setAddNewLevelAreaCloud(false)
    }
    const validateLevelForm = () => {
        const _error = {};

        if (isEmpty(levelareaname)) {
            _error["levelareaname"] = "This field is required";
        }
        if (isEmpty(levelareaimage)) {
            _error["levelareaimage"] = "This field is required";
        }

        setLevelError(_error);
        if (!isEmpty(_error)) {
            return false;
        }
        return true;
    }
    const saveLevelArea = async () => {
        dispatch(showLoader(true))
        const levelAreaForm = {
            areaName: levelareaname,
            areaImage: levelareaimage,
            levelID: props.level.id,
            tourID: parseInt(params.slug),
            iD: levelAreaID
        }
        const response = await services.addLevelArea(levelAreaForm);
        if (response) {
            setAddNewLevelAreaCloud(false)
            setAddNewLevelAreaLocal(false)
            setLevelAreaName('')
            setLevelAreaImage('')
            dispatch(showLoader(false))
            props.parentCallback(response, levelAreaID == 0 ? 'add' : 'update');
        }
    }
    const onSubmitHandlerLArea = () => {
        if (validateLevelForm()) {
            saveLevelArea()
        }
    }
    const onSubmitHandlerLocal = () => {
        setAddNewLevelArea(false)
        setAddNewLevelAreaCloud(false)
        setAddNewLevelAreaLocal(true)
    }
    const onSubmitHandlerCloud = () => {
        dispatch(showLoader(true))
        services.getImages().then(result => {
            if (result != undefined) {
                setCloudImages(result)
            }
            dispatch(showLoader(false))
        });
        setAddNewLevelArea(false)
        setAddNewLevelAreaLocal(false)
        setAddNewLevelAreaCloud(true)
    }
    const deleteLArea = (levelarea) => {
        Swal.fire({
            title: 'Are you sure?',
            showCancelButton: true,
            confirmButtonColor: 'red',
            confirmButtonText: 'Delete',
            width: 300,
        }).then(async (result) => {
            if (result.isConfirmed) {
                dispatch(showLoader(true))
                const response = await services.deleteLevelArea(levelarea.id);
                if (response) {
                    dispatch(showLoader(false))
                    props.parentCallback(levelarea, 'delete');
                }
            }
        })
    }
    const editLArea = (levelarea) => {
        if (levelarea.areaImage.indexOf('http') == 0)
            onSubmitHandlerCloud()
        else
            onSubmitHandlerLocal()
        setLevelAreaName(levelarea.areaName)
        setAddLAreaText("Update Interior Image")
        setLevelAreaImage(levelarea.areaImage);
        setLevelAreaID(levelarea.id)
    }
    const setAreaImageCloud = (index, image) => {
        setLevelAreaImage(image)
        setTimeout(() => {
            var elements = document.getElementsByClassName("custom_img_cld_v1");
            for (let i = 0; i < elements.length; i++) {
                elements[i].setAttribute("class", "custom_img_v1 col-md-3 custom_img_cld_v1");
            }
            if (document.getElementById("svi" + index) != undefined)
                document.getElementById("svi" + index).setAttribute("class", "custom_img_v1 col-md-3 custom_img_cld_v1 active");
        }, 100)
    }
    return (
        <>
            {props.interior == null && <div id="sv0" className="custom_img_v_new" onClick={() => addLevelArea()}><span className="custom_img_v_span_new">{addLAreaText}</span></div>}
            {props.interior != null && <span className="custom_img_border_span_3">{get(props.interior, 'areaName')}<i className="st-trash st-trash-white st-pencil-trash" aria-hidden="true" onClick={() => deleteLArea(props.interior)}></i><i className="st-pencil st-pencil-white st-pencil-trash" aria-hidden="true" onClick={() => editLArea(props.interior)}></i></span>}
            <Modal show={addnewlevelarea} onHide={onHideLArea}>
                <Modal.Body>
                    <h5 className="modal-title">Select Image</h5>
                    <div className="mdl-body">
                        <button onClick={() => onSubmitHandlerLocal()} className="btn btn-primary btn-rounded btn-block">From my Computer</button>
                        <button onClick={() => onSubmitHandlerCloud()} className="btn btn-primary btn-rounded btn-block">From my Cloud</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={addnewlevelareaLocal} onHide={onHideLocal}>
                <Modal.Body>
                    <h5 className="modal-title">{addLAreaText}</h5>
                    <div className="mdl-body">
                        <input type="text" maxLength="100" className="form-control rounded-bordered mb-4" placeholder="Area Name" value={levelareaname}
                            onChange={(e) => setLevelAreaName(e.target.value)} />
                        {
                            get(levelerror, 'levelareaname') && isEmpty(levelareaname) &&
                            <span className='requiredMsg'>
                                {get(levelerror, 'levelareaname')}
                            </span>

                        }
                        <div className="input-group custom-file-button">
                            <input type="file" className="form-control rounded-bordered mb-4" accept="image/png, image/gif, image/jpeg" placeholder="Select Image"
                                onChange={e => onChangeImageLevel(e, "picture")} />
                            {
                                get(levelerror, 'levelareaimage') && isEmpty(levelareaimage) &&
                                <span className='requiredMsg'>
                                    {get(levelerror, 'levelareaimage')}
                                </span>

                            }
                        </div>
                        <button onClick={() => onSubmitHandlerLArea()} className="btn btn-primary btn-rounded btn-block">{addLAreaText}</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={addnewlevelareaCloud} onHide={onHideCloud}>
                <Modal.Body>
                    <h5 className="modal-title">{addLAreaText}</h5>
                    <div className="mdl-body">
                        <input type="text" maxLength="100" className="form-control rounded-bordered mb-4" placeholder="Area Name" value={levelareaname}
                            onChange={(e) => setLevelAreaName(e.target.value)} />
                        {
                            get(levelerror, 'levelareaname') && isEmpty(levelareaname) &&
                            <span className='requiredMsg'>
                                {get(levelerror, 'levelareaname')}
                            </span>

                        }
                        <strong style={{ 'margin': '5px' }}>Select Image</strong>
                        <div className="row" style={{ textAlign: 'center', display: 'block', height: '350px', overflowX: 'hidden', marginTop: '10px' }}>
                            {
                                get(levelerror, 'levelareaimage') && isEmpty(levelareaimage) &&
                                <span className='requiredMsg'>
                                    {get(levelerror, 'levelareaimage')}
                                </span>

                            }
                            {
                                !isEmpty(cloudimages) &&
                                cloudimages.map((image, index) => {
                                    return <div key={index} id={"svi" + index} onClick={() => setAreaImageCloud(index, image.replace('thumb.png', '.png').replace('thumb.jpeg', '.jpeg'))} className="custom_img_v1 col-md-3 custom_img_cld_v1" style={{ padding: '0' }}><img src={image} style={{ width: 'inherit', height: 'inherit' }} /></div>
                                })
                            }
                        </div>
                        <button onClick={() => onSubmitHandlerLArea()} className="btn btn-primary btn-rounded btn-block">{addLAreaText}</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showerror}>
                <Modal.Body>
                    <div className="mdl-body">
                        <div className="interiorerrortxt">Please select or add a level!</div>
                        <div style={{ textAlign: 'center' }}><button onClick={() => setShowError(false)} className="btn btn-primary btn-rounded btn-block" style={{ display: 'inline', width: '100px' }}>OK</button></div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default TourInterior;