import { React } from 'react';
import Header from './global/header';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes';
import ToastBox from './components/toastBox';
import Loader from './components/loader';
import Footer from './global/footer';

function App() {
    return (
        <BrowserRouter>
            <ToastBox />
            <Loader />
            {
                window.location.pathname.indexOf('tourv') < 0 ? <Header /> : null
            }
            <AppRoutes />
            {
                window.location.pathname.indexOf('tourv') < 0 ? <Footer /> : null
            }
        </BrowserRouter>
    );
}

export default App;
