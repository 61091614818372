import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { services } from '../utils/services';
import { showLoader } from '../redux/actions/homeAction';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const TourLevel = (props) => {
    const dispatch = useDispatch();
    const params = useParams();
    const [tourlevel, setTourLevel] = useState(0);
    const [addnewlevel, setAddNewLevel] = useState(false);
    const [addLevelText, setAddLevelText] = useState("Add a New Level");
    const [levelID, setLevelID] = useState(0);
    const addLevel = () => {
        setTourLevel(props.levelslength)
        setAddNewLevel(true)
        setAddLevelText("Add a New Level")
        setLevelID(0)
    }
    const onHideLevel = () => {
        setAddNewLevel(false)
    }
    const onSubmitHandlerLevel = () => {
        saveLevel()
    }
    const saveLevel = async () => {
        dispatch(showLoader(true))
        const levelForm = {
            levelName: tourlevel,
            tourID: parseInt(params.slug),
            iD: levelID
        }
        const response = await services.addLevel(levelForm);
        if (response) {
            setAddNewLevel(false)
            dispatch(showLoader(false))
            props.parentCallback(response, levelID == 0 ? 'add' : 'update');
        }
    }
    const editLevel = (level) => {
        setAddNewLevel(true)
        setTourLevel(level.levelName)
        setAddLevelText("Update Level")
        setLevelID(level.id)
    }
    const deleteLevel = (level) => {
        Swal.fire({
            title: 'Are you sure?',
            showCancelButton: true,
            confirmButtonColor: 'red',
            confirmButtonText: 'Delete',
            width: 300,
        }).then(async (result) => {
            if (result.isConfirmed) {
                dispatch(showLoader(true))
                const response = await services.deleteLevel(level.id);
                if (response) {
                    dispatch(showLoader(false))
                    props.parentCallback(level, 'delete');
                }
            }
        })
    }
    return (
        <>
            {props.level == null && <div className="lvlname" style={{ textAlign: 'center' }} onClick={() => addLevel()}>Add New Level</div>}
            {props.level != null && <span className="custom_img_border_span_level1"><i className="st-trash st-trash-white st-pencil-trash" aria-hidden="true" onClick={() => deleteLevel(props.level)}></i><i className="st-pencil st-pencil-white st-pencil-trash" aria-hidden="true" onClick={() => editLevel(props.level)}></i></span>}
            <Modal show={addnewlevel} onHide={onHideLevel}>
                <Modal.Body>
                    <h5 className="modal-title">{addLevelText}</h5>
                    <div className="mdl-body">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div><button onClick={() => setTourLevel(tourlevel - 1)} className="btn btn-light btn_add_level"><strong>-</strong></button></div>
                            <div>Level {tourlevel}</div>
                            <div><button onClick={() => setTourLevel(tourlevel + 1)} className="btn btn-light btn_add_level"><strong>+</strong></button></div>
                        </div>
                        <br />
                        <button onClick={() => onSubmitHandlerLevel()} className="btn btn-primary btn-rounded btn-block">{addLevelText}</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default TourLevel;