import React, { useState, useEffect, useRef } from 'react';
import { showLoader } from '../redux/actions/homeAction';
import { useDispatch } from 'react-redux';
import { services } from '../utils/services';
import { useParams } from 'react-router-dom';
import { LIVE_IMAGE_URL } from '../utils/globalConfig';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Modal from 'react-bootstrap/Modal';

const TourvEditor1 = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const dataFetchedRef = useRef(false);
    const [propname, setPropName] = useState("");
    const [viewlist, setViewList] = useState([]);
    const [exteriorslist, setExteriorsList] = useState([]);
    const [levelslist, setLevelsList] = useState([]);
    const [vivetypeexterior, setViveTypeExterior] = useState(true);
    const [levelname, setLevelName] = useState(0);
    const [level, setLevel] = useState({});
    const [viewInfoDetail, setViewInfoDetail] = useState(false);
    const [tour, setTour] = useState({});
    const [destpitch, setDestPitch] = useState(0);
    const [destyaw, setDestYaw] = useState(0);
    const [slidervalue, setSliderValue] = useState(1);
    const [selectdestimg, setSelectDestImg] = useState(false);
    const [destimgvalue, setDestImgValue] = useState({});
    const [isGetHotSpots, setisGetHotSpots] = useState(false);
    const [hotspotsimages, setHotSpotsImages] = useState({});
    const [isedit, setIsEdit] = useState(false);
    const [currentview, setCurrentView] = useState(0);
    const [rightevent, setRightEvent] = useState({});
    const [isasset, setIsAsset] = useState(false);
    const [assetvalue, setAssetValue] = useState("");
    const assetsSample = JSON.parse('[{"Id":138189,"Identifier":"WV24 - 5199 - ASDG - 138189","Description":"BSWTP HS PUMP 3 \/ADJSPD","Manufacturer":"TOSHIBA","SAPEquipmentTypeDescription":"ADJUSTABLE SPEED DRIVE","FunctionalLocation":"WVBS-BSWTP"},{"Id":138213,"Identifier":"WV24-5199-DFBG-138213","Description":"BSWTP BLUESTONE PLANT \/AED","Manufacturer":"ZOLL","SAPEquipmentTypeDescription":"DEFIBRILLATOR","FunctionalLocation":"WVBS-BSWTP"},{"Id":138200,"Identifier":"WV24-5199-ASDG-138200","Description":"BSWTP-SUPR SUPERNATANT PUMP 2 \/ADJSPD","Manufacturer":"TOSHIBA","SAPEquipmentTypeDescription":"ADJUSTABLE SPEED DRIVE","FunctionalLocation":"WVBS-BSWTP"}]');

    if (!isGetHotSpots) {
        services.getHotSpots(parseInt(params.slug)).then(result => {
            if (result != undefined) {
                var images = JSON.parse(result.hotspotImages);
                setHotSpotsImages(images);
            }
            setisGetHotSpots(true);
        });
    }

    useEffect(() => {
        if (dataFetchedRef.current || !isGetHotSpots) return;
        dataFetchedRef.current = true;
        //https://pannellum.org/documentation/examples/tour/

        var panoramadiv = document.getElementById("panorama");
        panoramadiv.style.height = window.innerHeight + "px";

        document.body.style.overflow = 'hidden';

        const link = document.createElement("link")
        link.type = 'text/css'
        link.rel = "stylesheet";
        link.href = "https://cdn.jsdelivr.net/npm/pannellum@2.5.6/build/pannellum.css"
        document.head.appendChild(link)
        const script1 = document.createElement("script")
        script1.type = 'text/javascript'
        script1.src = "https://cdn.jsdelivr.net/npm/pannellum@2.5.6/build/pannellum.js"
        script1.async = false
        script1.onload = function () {
            setTimeout(() => {
                dispatch(showLoader(true))
                services.getTour(parseInt(params.slug)).then(result => {
                    dispatch(showLoader(false))
                    setPropName(get(result, 'propertyName'))
                    setTour(result)
                    if (result.id > 0) {
                        if (result.exteriors?.length > 0) {
                            setExteriorsList(result.exteriors)
                            setViewList(result.exteriors)
                            setViveTypeExterior(true)
                            if (result.levels?.length > 0) {
                                var resultlevels = result.levels.filter(function (el) {
                                    return el.levelAreas?.length > 0;
                                })
                                resultlevels.sort(function (a, b) {
                                    if (a.levelName < b.levelName) return -1;
                                    if (a.levelName > b.levelName) return 1;
                                    return 0;
                                }).reverse()
                                if (resultlevels.length > 0)
                                    setLevelName(resultlevels[0].levelName)
                                setLevelsList(resultlevels)
                            }
                            selectElement(result.exteriors[0].id)
                            OpenPanorama(get(result.exteriors[0], 'id'), result.exteriors, 110, -3, 117)
                        }
                        else if (result.levels?.length > 0) {
                            setViveTypeExterior(false)
                            var resultlevels = result.levels.filter(function (el) {
                                return el.levelAreas?.length > 0;
                            })
                            resultlevels.sort(function (a, b) {
                                if (a.levelName < b.levelName) return -1;
                                if (a.levelName > b.levelName) return 1;
                                return 0;
                            }).reverse()
                            setLevelsList(resultlevels)
                            changevivelevel(resultlevels[0])
                            setLevelName(resultlevels[0].levelName)
                        }
                    }
                })
            }, 500);
        }
        document.head.appendChild(script1)
        setTimeout(() => { if (typeof panoramaviewer === 'undefined' && exteriorslist.length > 0) { window.location.reload(); } }, 2000);

        const contextMenu = document.getElementById("context-menu");
        const scope = document.querySelector("body");
        scope.addEventListener("contextmenu", (event) => {
            event.preventDefault();

            const { clientX: mouseX, clientY: mouseY } = event;

            contextMenu.style.top = `${mouseY}px`;
            contextMenu.style.left = `${mouseX}px`;

            contextMenu.classList.add("visible");

            setRightEvent(event);
        });
        scope.addEventListener("click", (e) => {
            contextMenu.classList.remove("visible");
            if (e.target.className.indexOf('custom-tooltip-div') < 0) {
                var elements = document.getElementsByClassName("custom-tooltip-div-show");
                for (let i = 0; i < elements.length; i++) {
                    elements[i].setAttribute("class", "");
                }
            }
        });

    }, [hotspotsimages, isGetHotSpots])
    const changeview = (view) => {
        selectElement(get(view, 'id'));
        window.panoramaviewer.loadScene(get(view, 'id'))
    }
    const changevivetype = () => {
        if (vivetypeexterior) {
            setViveTypeExterior(false)
            if (levelslist != undefined && levelslist.length > 0) {
                setLevel(levelslist[levelslist.length - 1]);
                setViewList(levelslist[levelslist.length - 1].levelAreas)
                setLevelName(levelslist[levelslist.length - 1].levelName)
                selectElement(get(levelslist[levelslist.length - 1].levelAreas[0], 'id'))
                OpenPanorama(get(levelslist[levelslist.length - 1].levelAreas[0], 'id'), levelslist[levelslist.length - 1].levelAreas, 110, -3, 117)
            }
        }
        else {
            setViveTypeExterior(true)
            setViewList(exteriorslist)
            selectElement(get(exteriorslist[0], 'id'))
            OpenPanorama(get(exteriorslist[0], 'id'), exteriorslist, 110, -3, 117)
        }
    }
    const changevivelevel = (level) => {
        setLevel(level);
        if (level.levelAreas?.length > 0) {
            setViewList(level.levelAreas)
            setLevelName(level.levelName)
            selectElement(get(level.levelAreas[0], 'id'))
            OpenPanorama(get(level.levelAreas[0], 'id'), level.levelAreas, 110, -3, 117)
        }
    }
    const viewInfo = () => {
        setViewInfoDetail(true)
    }
    const hideViewInfo = () => {
        setViewInfoDetail(false)
    }
    const selectElement = (id) => {
        setCurrentView(id);
        setTimeout(() => {
            var elements = document.getElementsByClassName("custom_img_v");
            for (let i = 0; i < elements.length; i++) {
                elements[i].setAttribute("class", "custom_img_v");
            }
            if (document.getElementById("sv" + id) != undefined)
                document.getElementById("sv" + id).setAttribute("class", "custom_img_v active");
        }, 500)

    }
    const gethotSpots = (id) => {
        var result = [];
        if (hotspotsimages[id] != null) {
            hotspotsimages[id].map((item, index) => {
                var args = { item: item, id: id };
                var cssClass = "pnlm-hotspot-base custom-hotspot htslider" + item.slider;
                if (item.isasset != undefined && item.assetid != undefined && item.isasset == true) {
                    cssClass = "pnlm-hotspot-base custom-hotspot htasset";
                    result.push({
                        "pitch": item.pitch,
                        "yaw": item.yaw,
                        "type": "info",
                        "text": item.assetid,
                        "cssClass": cssClass,
                        "createTooltipFunc": hotspotfunc,
                        "createTooltipArgs": args
                    })
                }
                else {
                    result.push({
                        "pitch": item.pitch,
                        "yaw": item.yaw,
                        "type": "scene",
                        "text": item.view.areaName,
                        "sceneId": item.view.id,
                        "cssClass": cssClass,
                        "createTooltipFunc": hotspotfunc,
                        "createTooltipArgs": args
                    })
                }
            })
        }
        return result;
    }
    const hotspotfunc = (hotSpotDiv, args) => {
        var span = document.createElement('span');
        if (args.item.isasset != undefined && args.item.assetid != undefined && args.item.isasset == true) {
            for (let i = 0; i < assetsSample.length; i++) {
                if (assetsSample[i].Id == parseInt(args.item.assetid)) {
                    var assetItem = assetsSample[i];
                    span.innerHTML = '<div>Asset ID : ' + assetItem.Id + '</div><div>Identifier : ' + assetItem.Identifier + '</div><div>Description : ' + assetItem.Description + '</div><div>Manufacturer : ' + assetItem.Manufacturer + '</div><div>SAPEquipmentTypeDescription : ' + assetItem.SAPEquipmentTypeDescription + '</div><div>FunctionalLocation : ' + assetItem.FunctionalLocation + '</div><div><div class="editarrow" pitch="' + args.item.pitch + '" yaw="' + args.item.yaw + '" view="' + args.id + '" assetid="' + args.item.assetid + '">Edit</div></div>';
                }
            }
            hotSpotDiv.appendChild(span);
            hotSpotDiv.classList.add('custom-tooltip-div');
            span.style.width = '500px';
            span.style.maxWidth = '500px';
            span.style.marginLeft = -(span.scrollWidth + 35) / 2 + 'px';
            span.style.marginTop = -span.scrollHeight - 12 + 'px';
            hotSpotDiv.addEventListener("click", (e) => {
                var elements = document.getElementsByClassName("custom-tooltip-div-show");
                for (let i = 0; i < elements.length; i++) {
                    elements[i].setAttribute("class", "");
                }
                span.className = 'custom-tooltip-div-show';
            });
        }
        else {
            hotSpotDiv.classList.add('custom-tooltip');
            span.innerHTML = args.item.view.areaName + '<div><div class="editarrow" pitch="' + args.item.pitch + '" yaw="' + args.item.yaw + '" view="' + args.id + '">Edit</div></div>';
            hotSpotDiv.appendChild(span);
            span.style.width = span.scrollWidth - 20 + 'px';
            span.style.marginLeft = -(span.scrollWidth + 35) / 2 + 'px';
            span.style.marginTop = -span.scrollHeight - 12 + 'px';
        }
    }
    const OpenPanorama = (image, viewlistitems, hfov, pitch, yaw) => {

        if (image != undefined) {
            const boxes = document.querySelectorAll('.pnlm-hotspot-base');
            boxes.forEach(box => { box.remove(); });

            var scenes = {};
            for (let i = 0; i < viewlistitems.length; i++) {
                let _imageUrl = viewlistitems[i].areaImage
                if (viewlistitems[i].areaImage.indexOf('http') < 0)
                    _imageUrl = `${LIVE_IMAGE_URL}${viewlistitems[i].areaImage}`
                scenes[viewlistitems[i].id] = {
                    "type": "equirectangular",
                    "panorama": '' + _imageUrl + '',
                    "hfov": hfov,
                    "pitch": pitch,
                    "yaw": yaw,
                    "hotSpots": gethotSpots(viewlistitems[i].id)
                }
            }
            window.panoramaviewer = window.pannellum.viewer('panorama', {
                "default": {
                    "firstScene": image,
                    "sceneFadeDuration": 1000,
                    "autoLoad": true,
                    "showControls": false
                },
                "scenes": scenes
            });
            window.panoramaviewer.on('scenechange',
                function () {
                    selectElement(window.panoramaviewer.getScene())
                }
            );
            window.panoramaviewer.on('load',
                function () {
                    const scope = document.getElementsByClassName("editarrow");
                    if (scope.length > 0) {
                        for (let i = 0; i < scope.length; i++) {
                            var v = scope[i];
                            v.addEventListener("click", (e) => {
                                e.stopPropagation();
                                var pitch = e.target.getAttribute("pitch");
                                var yaw = e.target.getAttribute("yaw");
                                var cview = e.target.getAttribute("view");
                                var assetid = e.target.getAttribute("assetid");
                                setDestPitch(pitch);
                                setDestYaw(yaw);
                                setSelectDestImg(true);
                                setIsEdit(true);
                                if (assetid != undefined) {
                                    setIsAsset(true);
                                    setAssetValue(assetid);
                                }
                                else {
                                    setIsAsset(false);
                                    setAssetValue('');
                                }
                                hotspotsimages[cview].map((vv, kk) => {
                                    if (vv.pitch == pitch && vv.yaw == yaw) {
                                        setTimeout(() => { setSlider(vv.slider); }, 100);
                                        selectDestImage(vv.view);
                                    }
                                })
                            });
                        }
                    }
                }
            );
        }
    }
    const addNewArrow = (event) => {
        var coords = window.panoramaviewer.mouseEventToCoords(rightevent);
        setDestPitch(coords[0]);
        setDestYaw(coords[1]);
        selectDestImage(viewlist[0]);
        setIsAsset(false);
        setAssetValue('');
        setSelectDestImg(true);
    }
    const addNewAsset = (event) => {
        var coords = window.panoramaviewer.mouseEventToCoords(rightevent);
        setDestPitch(coords[0]);
        setDestYaw(coords[1]);
        selectDestImage(viewlist[0]);
        setIsAsset(true);
        setAssetValue('');
        setSelectDestImg(true);
    }
    const hideselectdestimg = () => {
        setIsEdit(false);
        setSelectDestImg(false);
        setDestImgValue({});
        setSliderValue(1);
    }
    const selectDestImage = (view) => {
        setTimeout(() => {
            var elements = document.getElementsByClassName("custom_img_v1");
            for (let i = 0; i < elements.length; i++) {
                elements[i].setAttribute("class", "custom_img_v1");
            }
            if (document.getElementById("svi" + view.id) != undefined)
                document.getElementById("svi" + view.id).setAttribute("class", "custom_img_v1 active");
        }, 100)
        setDestImgValue(view);
    }
    const setSlider = (val) => {
        setSliderValue(val);
        var element = document.getElementsByClassName("custom-hotspotpop");
        element[0].className = 'custom-hotspotpop htslider' + val;
    }

    const onSubmitSelectDestHandlerDelete = () => {
        var currhotspotsimages = hotspotsimages;
        if (currhotspotsimages[currentview] != null) {
            currhotspotsimages[currentview] = currhotspotsimages[currentview].filter(v => v.pitch != parseFloat(destpitch) && v.yaw != parseFloat(destyaw));
        }
        dispatch(showLoader(true))
        const hotspotForm = {
            id: parseInt(params.slug),
            hotspotImages: JSON.stringify(currhotspotsimages)
        }
        const response = services.updateHotSpot(hotspotForm);
        if (response) {
            setHotSpotsImages(currhotspotsimages);
            hideselectdestimg();
            window.panoramaviewer.destroy();
            if (vivetypeexterior) {
                OpenPanorama(currentview, exteriorslist, window.panoramaviewer.getHfov(), window.panoramaviewer.getPitch(), window.panoramaviewer.getYaw());
            }
            else {
                OpenPanorama(currentview, level.levelAreas, window.panoramaviewer.getHfov(), window.panoramaviewer.getPitch(), window.panoramaviewer.getYaw());
            }
        }
        dispatch(showLoader(false))
    }

    const onSubmitSelectDestHandler = () => {
        var item = { 'pitch': parseFloat(destpitch), 'yaw': parseFloat(destyaw), 'view': destimgvalue, 'slider': parseInt(slidervalue), 'isasset': isasset, 'assetid': assetvalue };
        var currhotspotsimages = hotspotsimages;
        if (currhotspotsimages[currentview] != null) {
            var added = false;
            for (let i = 0; i < currhotspotsimages[currentview].length; i++) {
                var val = currhotspotsimages[currentview][i];
                if (val.pitch == item.pitch && val.yaw == item.yaw) {
                    currhotspotsimages[currentview][i] = item;
                    added = true;
                }
            }
            if (!added)
                currhotspotsimages[currentview].push(item);
        }
        else
            currhotspotsimages[currentview] = [item];

        dispatch(showLoader(true))
        const hotspotForm = {
            id: parseInt(params.slug),
            hotspotImages: JSON.stringify(currhotspotsimages)
        }
        const response = services.updateHotSpot(hotspotForm);
        if (response) {
            setHotSpotsImages(currhotspotsimages);
            hideselectdestimg();
            window.panoramaviewer.destroy();
            if (vivetypeexterior) {
                OpenPanorama(currentview, exteriorslist, window.panoramaviewer.getHfov(), window.panoramaviewer.getPitch(), window.panoramaviewer.getYaw());
            }
            else {
                OpenPanorama(currentview, level.levelAreas, window.panoramaviewer.getHfov(), window.panoramaviewer.getPitch(), window.panoramaviewer.getYaw());
            }
        }
        dispatch(showLoader(false))
    }
    return (
        <>
            <div>
                <div id="context-menu">
                    <button onClick={event => addNewArrow(event)} className="btn btn-hotspot btn-rounded btn-ctxmen">Add New Arrow</button>
                    <button onClick={event => addNewAsset(event)} className="btn btn-hotspot btn-rounded btn-ctxmen">Add New Asset</button>
                </div>
                <div className="tv-name"><div>{propname}</div></div>
                <div id="panorama"></div>
                <div className="tv-list">
                    {!vivetypeexterior ?
                        <div className="">
                            {
                                !isEmpty(levelslist) &&
                                levelslist.map((level, index) => {
                                    return <div key={get(level, 'id')} className={"lvlname " + (level.levelName == levelname ? 'lvlshow' : '')} onClick={() => changevivelevel(level)}>{get(level, 'levelName')}</div>
                                })
                            }
                        </div>
                        : <></>}
                    <div className="">
                        {vivetypeexterior ? <i className="st-exterior viewswitch" aria-hidden="true" onClick={() => changevivetype()}></i>
                            : <i className="st-interior viewswitch" aria-hidden="true" onClick={() => changevivetype()}></i>}
                    </div>
                    <div className="custom_t">
                        <div className="custom_12r">
                            {
                                !isEmpty(viewlist) &&
                                viewlist.map((view, index) => {
                                    let _imageUrl = get(view, 'areaImage')
                                    if (get(view, 'areaImage').indexOf('http') < 0)
                                        _imageUrl = `${LIVE_IMAGE_URL}${get(view, 'areaImage')}`
                                    return <div key={get(view, 'id')} id={"sv" + get(view, "id")} className="custom_img_v" style={{
                                        backgroundImage: 'url("' + _imageUrl.replace('.png', 'thumb.png').replace('.jpeg', 'thumb.jpeg') + '")',
                                    }} onClick={() => changeview(view)}>
                                        <span className="custom_img_v_span">{get(view, 'areaName')}</span>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={viewInfoDetail} onHide={hideViewInfo}>
                <Modal.Body>
                    <h5 className="modal-title">Property Details</h5>
                    <div className="mdl-body">
                        <div className="bx_12">
                            <p className=""><i className="st-pin">    </i> {get(tour, 'propertyAddress') ? get(tour, 'propertyAddress') : '-'}</p>
                            <p className=""><i className="st-resize"> </i> {get(tour, 'propertySize') ? get(tour, 'propertySize') : '-'}</p>
                            <p className=""><i className="st-flats">  </i> {get(tour, 'purchaseType') ? get(tour, 'purchaseType') : '-'}</p>
                            <p className=""><i className="st-cash">   </i> {get(tour, 'price') ? get(tour, 'price') : '-'}</p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal size="lg" show={selectdestimg} onHide={hideselectdestimg}>
                <Modal.Body>
                    {!isasset &&
                        <><h5 className="modal-title">Select Destination Image</h5>
                            <div className="mdl-body">
                                <div className="mdl-dest">
                                    {
                                        !isEmpty(viewlist) &&
                                        viewlist.map((view, index) => {
                                            let _imageUrl = get(view, 'areaImage')
                                            if (get(view, 'areaImage').indexOf('http') < 0)
                                                _imageUrl = `${LIVE_IMAGE_URL}${get(view, 'areaImage')}`
                                            return <div key={get(view, 'id')} id={"svi" + get(view, "id")} className="custom_img_v1" style={{
                                                backgroundImage: 'url("' + _imageUrl.replace('.png', 'thumb.png').replace('.jpeg', 'thumb.jpeg') + '")',
                                            }} onClick={() => selectDestImage(view)}>
                                                <span className="custom_img_v1_span">{get(view, 'areaName')}</span>
                                            </div>
                                        })
                                    }
                                </div>
                                <h5 className="modal-title">Set Arrow Rotation</h5>
                                <br />
                                <div className="htslider1 custom-hotspotpop" style={{ display: 'inline-block' }}></div>
                                <br />
                                <input type="range" min="1" max="8" className="slider" value={slidervalue} onChange={(e) => setSlider(e.target.value)} />
                                <br />
                                <div style={{ float: 'right', marginTop: '50px' }}>
                                    {isedit && <button onClick={() => onSubmitSelectDestHandlerDelete()} className="btn btn-danger btn-rounded" style={{ marginRight: '5px' }}>Delete</button>}
                                    <button onClick={() => onSubmitSelectDestHandler()} className="btn btn-primary btn-rounded">Update</button>
                                </div>
                            </div></>}
                    {isasset &&
                        <>
                            <h5 className="modal-title">Enter Asset ID</h5>
                            <div className="mdl-body">
                                <input type="text" value={assetvalue} onChange={(e) => setAssetValue(e.target.value)} placeholder="Asset ID" />
                                <br />
                                <div style={{ float: 'right', marginTop: '50px' }}>
                                    {isedit && <button onClick={() => onSubmitSelectDestHandlerDelete()} className="btn btn-danger btn-rounded" style={{ marginRight: '5px' }}>Delete</button>}
                                    <button onClick={() => onSubmitSelectDestHandler()} className="btn btn-primary btn-rounded">Update</button>
                                </div>
                            </div>
                        </>
                    }
                </Modal.Body>
            </Modal>
        </>
    );
}

export default TourvEditor1;