import React from 'react';

const UserAgreement = () => {
    return (
        <div className="container mt5">
            <h1>Mobile Application End User License Agreement</h1>
            <>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.14in",
                        background: "transparent",
                        marginTop: "0.08in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        This Mobile Application End User License Agreement ("
                        <strong>Agreement</strong>") is a binding agreement between you ("
                        <strong>End User</strong>" or "<strong>you</strong>") and 360STITCH.COM,
                        LLC, a Delaware limited liability company ("<strong>Company</strong>").
                        This Agreement governs your use of the 360 Stitch mobile application on
                        the Android mobile platform, (including all related documentation, the "
                        <strong>Application</strong>"). The Application is licensed, not sold, to
                        you.
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.14in",
                        background: "transparent",
                        marginTop: "0.08in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        BY CLICKING THE "AGREE" BUTTON, YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND
                        UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU ARE 18 YEARS OF AGE OR
                        OLDER; AND (C) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND
                        BY ITS TERMS. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT DOWNLOAD,
                        INSTALL, OR USE THE APPLICATION AND DELETE IT FROM YOUR MOBILE DEVICE.
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.17in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        1.
                        <span style={{ textDecoration: "none" }}>
                            <u>
                                <span style={{ fontWeight: "normal" }}>License Grant</span>
                            </u>
                        </span>
                        . Subject to the terms of this Agreement, Company grants you a limited,
                        non-exclusive, and nontransferable license to: (a) download, install, and
                        use the Application for your personal, non-commercial use on a single
                        mobile device or tablet owned or otherwise controlled by you ("
                        <strong>Mobile Device</strong>") strictly in accordance with the
                        Application's documentation; and (b) access, stream, download, and use on
                        such Mobile Device the Content and Services (as defined in{" "}
                        <span style={{ background: "#c0c0c0" }}>3</span>
                        <span style={{ background: "#c0c0c0" }}>5.</span>) made available in or
                        otherwise accessible through the Application, strictly in accordance with
                        this Agreement and the Terms of Use applicable to such Content and
                        Services as set forth in <span style={{ background: "#c0c0c0" }}>3</span>
                        <span style={{ background: "#c0c0c0" }}>5.</span>.
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.17in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        <span size={3}>
                            2.
                            <span style={{ textDecoration: "none" }}>
                                <u>
                                    <span style={{ fontWeight: "normal" }}>License Restrictions</span>
                                </u>
                            </span>
                            . Except as may be expressly permitted by applicable law or expressly
                            authorized by the Application, you shall not:&nbsp;
                        </span>
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.08in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        (a)copy the Application, except as expressly permitted by the license
                        contained herein;
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.08in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        (b)modify, translate, adapt, or otherwise create derivative works or
                        improvements, whether or not patentable, of the Application;
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.08in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        (c)reverse engineer, disassemble, decompile, decode, or otherwise attempt
                        to derive or gain access to the source code of the Application or any part
                        thereof;
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.08in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        (d)remove, delete, alter, or obscure any trademarks or any copyright,
                        trademark, patent, or other intellectual property or proprietary rights
                        notices from the Application, including any copy thereof;
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.08in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        (e)rent, lease, lend, sell, sublicense, assign, distribute, publish,
                        transfer, or otherwise make available the Application, or any features or
                        functionality of the Application, to any third party for any reason,
                        including by making the Application available on a network where it is
                        capable of being accessed by more than one device at any time;
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.08in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        (f)use any robot, spider, or other automatic device, process, or means to
                        access the Application for any purpose, including monitoring or copying
                        any of the material on the Application;
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.08in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        (g)use any manual process to monitor or copy any of the material on the
                        Application, or for any other purpose not expressly authorized in this
                        Agreement, without Company's prior written consent;
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.08in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        (h)frame, mirror, or otherwise incorporate the Application or any portion
                        of the Application as part of any other mobile application, website, or
                        service;
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.08in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        (i)use the Application in any manner that could disable, overburden,
                        damage, or impair the Application or interfere with any other party's use
                        of the Application; [or]
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.08in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        <span size={3}>
                            (j)remove, disable, circumvent, or otherwise create or implement any
                            workaround to any copy protection, rights management, or security
                            features in or protecting the Application; or&nbsp;
                        </span>
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.08in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        (k)use the Application in, or in association with, the design,
                        construction, maintenance, or operation of any hazardous environments or
                        systems, including any power generation systems; aircraft navigation or
                        communication systems, air traffic control systems, or any other transport
                        management systems; safety-critical applications, including medical or
                        life-support systems, vehicle operation applications or any police, fire,
                        or other safety response systems; and military or aerospace applications,
                        weapons systems, or environments.
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.17in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        3.
                        <span style={{ textDecoration: "none" }}>
                            <u>
                                <span style={{ fontWeight: "normal" }}>Reservation of Rights</span>
                            </u>
                        </span>
                        . You acknowledge and agree that the Application is provided under
                        license, and not sold, to you. You do not acquire any ownership interest
                        in the Application under this Agreement, or any other rights thereto other
                        than to use the Application in accordance with the license granted, and
                        subject to all terms, conditions, and restrictions, under this Agreement.
                        Company and its licensors and service providers reserve and shall retain
                        their entire right, title, and interest in and to the Application,
                        including all copyrights, trademarks, and other intellectual property
                        rights therein or relating thereto, except as expressly granted to you in
                        this Agreement.
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.17in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        4.
                        <span style={{ textDecoration: "none" }}>
                            <u>
                                <span style={{ fontWeight: "normal" }}>
                                    Collection and Use of Your Information
                                </span>
                            </u>
                        </span>
                        . You acknowledge that when you download, install, or use the Application,
                        Company may use automatic means (including, for example, cookies and web
                        beacons) to collect information about your Mobile Device and about your
                        use of the Application. You also may be required to provide certain
                        information about yourself as a condition to downloading, installing, or
                        using the Application or certain of its features or functionality, and the
                        Application may provide you with opportunities to share information about
                        yourself with others. All information we collect through or in connection
                        with this Application is subject to our <a href="https://360stitch.com/privacy">Privacy Policy</a>. By downloading, installing, using, and providing information to or
                        through this Application, you consent to all actions taken by us with
                        respect to your information in compliance with the Privacy Policy.
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.17in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        5.
                        <span style={{ textDecoration: "none" }}>
                            <u>
                                <span style={{ fontWeight: "normal" }}>Content and Services</span>
                            </u>
                        </span>
                        . The Application may provide you with access to Company's website located
                        at https://www.360stitch.com/ (the "<strong>Website</strong>") and
                        products and services accessible thereon, and certain features,
                        functionality, and content accessible on or through the Application may be
                        hosted on the Website (collectively, "
                        <strong>Content and Services</strong>"). Your access to and use of such
                        Content and Services are governed by Website's Terms of Use and Privacy
                        Policy located at [<a href="https://360stitch.com/terms">Terms of Use</a>] and [<a href="https://360stitch.com/privacy">Privacy Policy</a>], which are
                        incorporated herein by this reference. Your access to and use of such
                        Content and Services may require you to acknowledge your acceptance of
                        such Terms of Use and Privacy Policy and/or to register with the Website,
                        and your failure to do so may restrict you from accessing or using certain
                        of the Application's features and functionality. Any violation of such
                        Terms of Use will also be deemed a violation of this Agreement.
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.17in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        6.
                        <span style={{ textDecoration: "none" }}>
                            <u>
                                <span style={{ fontWeight: "normal" }}>Geographic Restrictions</span>
                            </u>
                        </span>
                        . The Content and Services are based in the state of Delaware in the
                        United States and provided for access and use only by persons located in
                        the United States. You acknowledge that you may not be able to access all
                        or some of the Content and Services outside of the United States and that
                        access thereto may not be legal by certain persons or in certain
                        countries. If you access the Content and Services from outside the United
                        States, you are responsible for compliance with local laws.
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.17in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        7.
                        <span style={{ textDecoration: "none" }}>
                            <u>
                                <span style={{ fontWeight: "normal" }}>Updates</span>
                            </u>
                        </span>
                        . Company may from time to time in its sole discretion develop and provide
                        Application updates, which may include upgrades, bug fixes, patches, other
                        error corrections, and/or new features (collectively, including related
                        documentation, "<strong>Updates</strong>"). Updates may also modify or
                        delete in their entirety certain features and functionality. You agree
                        that Company has no obligation to provide any Updates or to continue to
                        provide or enable any particular features or functionality. Based on your
                        Mobile Device settings, when your Mobile Device is connected to the
                        internet either: (a) the Application will automatically download and
                        install all available Updates; or (b) you may receive notice of or be
                        prompted to download and install available Updates. You shall promptly
                        download and install all Updates and acknowledge and agree that the
                        Application or portions thereof may not properly operate should you fail
                        to do so. You further agree that all Updates will be deemed part of the
                        Application and be subject to all terms and conditions of this Agreement.
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.17in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        8.
                        <span style={{ textDecoration: "none" }}>
                            <u>
                                <span style={{ fontWeight: "normal" }}>Third-Party Materials</span>
                            </u>
                        </span>
                        . The Application may display, include, or make available third-party
                        content (including data, information, applications, and other products,
                        services, and/or materials) or provide links to third-party websites or
                        services, including through third-party advertising ("
                        <strong>Third-Party Materials</strong>"). You acknowledge and agree that
                        Company is not responsible for Third-Party Materials, including their
                        accuracy, completeness, timeliness, validity, copyright compliance,
                        legality, decency, quality, or any other aspect thereof. Company does not
                        assume and will not have any liability or responsibility to you or any
                        other person or entity for any Third-Party Materials. Third-Party
                        Materials and links thereto are provided solely as a convenience to you,
                        and you access and use them entirely at your own risk and subject to such
                        third parties' terms and conditions.
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.17in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        9.
                        <span style={{ textDecoration: "none" }}>
                            <u>
                                <span style={{ fontWeight: "normal" }}>Term and Termination</span>
                            </u>
                        </span>
                        .
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.08in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        (a)The term of Agreement commences when you acknowledge your acceptance
                        and will continue in effect until terminated by you or Company as set
                        forth in this <span style={{ background: "#c0c0c0" }}>4</span>
                        <span style={{ background: "#c0c0c0" }}>9.</span>.
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.08in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        (b)You may terminate this Agreement by deleting the Application and all
                        copies thereof from your Mobile Device.
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.08in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        (c)Company may terminate this Agreement at any time without notice if it
                        ceases to support the Application, which Company may do in its sole
                        discretion. In addition, this Agreement will terminate immediately and
                        automatically without any notice if you violate any of the terms and
                        conditions of this Agreement.
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.08in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        (d)Upon termination: (i) all rights granted to you under this Agreement
                        will also terminate; and (ii) you must cease all use of the Application
                        and delete all copies of the Application from your Mobile Device and
                        account. Termination will not limit any of Company's rights or remedies at
                        law or in equity.
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.17in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        10.
                        <span style={{ textDecoration: "none" }}>
                            <u>
                                <span style={{ fontWeight: "normal" }}>Disclaimer of Warranties</span>
                            </u>
                        </span>
                        . THE APPLICATION IS PROVIDED TO END USER "AS IS" AND WITH ALL FAULTS AND
                        DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED
                        UNDER APPLICABLE LAW, COMPANY, ON ITS OWN BEHALF AND ON BEHALF OF ITS
                        AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS,
                        EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY,
                        OR OTHERWISE, WITH RESPECT TO THE APPLICATION, INCLUDING ALL IMPLIED
                        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                        AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF
                        DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT
                        LIMITATION TO THE FOREGOING, COMPANY PROVIDES NO WARRANTY OR UNDERTAKING,
                        AND MAKES NO REPRESENTATION OF ANY KIND THAT THE APPLICATION WILL MEET
                        YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE, OR WORK
                        WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE
                        WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, OR BE
                        ERROR-FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        textIndent: "1in",
                        marginTop: "0.17in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        <span size={3} style={{ fontSize: 16 }}>
                            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON
                            IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS
                            OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS
                            MAY NOT APPLY TO YOU.&nbsp;
                        </span>
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.17in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        <span size={3}>
                            11.
                            <span style={{ textDecoration: "none" }}>
                                <u>
                                    <span style={{ fontWeight: "normal" }}>
                                        Limitation of Liability
                                    </span>
                                </u>
                            </span>
                            . TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
                            COMPANY OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS
                            OR SERVICE PROVIDERS, HAVE ANY LIABILITY ARISING FROM OR RELATED TO YOUR
                            USE OF OR INABILITY TO USE THE APPLICATION OR THE CONTENT AND SERVICES
                            FOR: (a) PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF
                            SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL, BUSINESS
                            INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION, OR ANY OTHER
                            CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE
                            DAMAGES, OR (b) DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED
                            THE AMOUNT ACTUALLY PAID BY YOU FOR THE APPLICATION. THE FOREGOING
                            LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF
                            CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE AND REGARDLESS OF
                            WHETHER SUCH DAMAGES WERE FORESEEABLE OR COMPANY WAS ADVISED OF THE
                            POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW CERTAIN
                            LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE ABOVE LIMITATIONS OF
                            LIABILITY MAY NOT APPLY TO YOU.&nbsp;
                        </span>
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.17in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        12.
                        <span style={{ textDecoration: "none" }}>
                            <u>
                                <span style={{ fontWeight: "normal" }}>Indemnification</span>
                            </u>
                        </span>
                        . You agree to indemnify, defend, and hold harmless Company and its
                        officers, directors, employees, agents, affiliates, successors, and
                        assigns from and against any and all losses, damages, liabilities,
                        deficiencies, claims, actions, judgments, settlements, interest, awards,
                        penalties, fines, costs, or expenses of whatever kind, including
                        [reasonable] attorneys' fees, arising from or relating to your use or
                        misuse of the Application or your breach of this Agreement, including but
                        not limited to the content you submit or make available through this
                        Application.
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.17in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        13.
                        <span style={{ textDecoration: "none" }}>
                            <u>
                                <span style={{ fontWeight: "normal" }}>Export Regulation</span>
                            </u>
                        </span>
                        . The Application may be subject to US export control laws, including the
                        Export Control Reform Act and its associated regulations. You shall not,
                        directly or indirectly, export, re-export, or release the Application to,
                        or make the Application accessible from, any jurisdiction or country to
                        which export, re-export, or release is prohibited by law, rule, or
                        regulation. You shall comply with all applicable federal laws,
                        regulations, and rules, and complete all required undertakings (including
                        obtaining any necessary export license or other governmental approval),
                        prior to exporting, re-exporting, releasing, or otherwise making the
                        Application available outside the US.
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.17in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        <span size={3}>
                            14.
                            <span style={{ textDecoration: "none" }}>
                                <u>
                                    <span style={{ fontWeight: "normal" }}>US Government Rights</span>
                                </u>
                            </span>
                            . The Application is a commercial product, consisting of commercial
                            computer software and commercial computer software documentation, as
                            such terms are defined in 48 C.F.R. § 2.101. Accordingly, if you are an
                            agency of the US Government or any contractor therefor, you receive only
                            those rights with respect to the Application as are granted to all other
                            end users under license, in accordance with (a) 48 C.F.R. § 227.7201
                            through 48 C.F.R. § 227.7204, with respect to the Department of Defense
                            and their contractors, or (b) 48 C.F.R. § 12.212, with respect to all
                            other US Government licensees and their contractors.&nbsp;
                        </span>
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.17in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        15.
                        <span style={{ textDecoration: "none" }}>
                            <u>
                                <span style={{ fontWeight: "normal" }}>Severability</span>
                            </u>
                        </span>
                        . If any provision of this Agreement is illegal or unenforceable under
                        applicable law, the remainder of the provision will be amended to achieve
                        as closely as possible the effect of the original term and all other
                        provisions of this Agreement will continue in full force and effect.
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.17in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        <span size={3}>
                            16.
                            <span style={{ textDecoration: "none" }}>
                                <u>
                                    <span style={{ fontWeight: "normal" }}>Governing Law</span>
                                </u>
                            </span>
                            . This Agreement is governed by and construed in accordance with the
                            internal laws of the State of Delaware without giving effect to any
                            choice or conflict of law provision or rule. Any legal suit, action, or
                            proceeding arising out of or related to this Agreement or the
                            Application shall be instituted exclusively in the federal courts of the
                            United States or the courts of the State of Delaware in each case
                            located in Wilmington and [New Castle county. You waive any and all
                            objections to the exercise of jurisdiction over you by such courts and
                            to venue in such courts.&nbsp;
                        </span>
                    </span>
                </p>
                <p
                    style={{
                        textAlign: "left",
                        background: "transparent",
                        fontFamily: '"Times New Roman", serif',
                        fontSize: 15,
                        lineHeight: "100%",
                        marginTop: "0.08in",
                        marginBottom: "0.14in"
                    }}
                >
                    <br />
                    &nbsp;
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.17in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        17.
                        <span style={{ textDecoration: "none" }}>
                            <u>
                                <span style={{ fontWeight: "normal" }}>
                                    Limitation of Time to File Claims
                                </span>
                            </u>
                        </span>
                        . ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO
                        THIS AGREEMENT OR THE APPLICATION MUST BE COMMENCED WITHIN ONE (1) YEAR
                        AFTER THE CAUSE OF ACTION ACCRUES OTHERWISE SUCH CAUSE OF ACTION OR CLAIM
                        IS PERMANENTLY BARRED.
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.17in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        <span size={3}>
                            18.
                            <span style={{ textDecoration: "none" }}>
                                <u>
                                    <span style={{ fontWeight: "normal" }}>Entire Agreement</span>
                                </u>
                            </span>
                            . This Agreement, our Terms of Use and our Privacy Policy constitute the
                            entire agreement between you and Company with respect to the Application
                            and supersede all prior or contemporaneous understandings and
                            agreements, whether written or oral, with respect to the
                            Application.&nbsp;
                        </span>
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.17in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        <span size={3}>
                            19.
                            <span style={{ textDecoration: "none" }}>
                                <u>
                                    <span style={{ fontWeight: "normal" }}>Waiver</span>
                                </u>
                            </span>
                            . No failure to exercise, and no delay in exercising, on the part of
                            either party, any right or any power hereunder shall operate as a waiver
                            thereof, nor shall any single or partial exercise of any right or power
                            hereunder preclude further exercise of that or any other right
                            hereunder. In the event of a conflict between this Agreement and any
                            applicable purchase or other terms, the terms of this Agreement shall
                            govern.&nbsp;
                        </span>
                    </span>
                </p>
                <p
                    style={{
                        lineHeight: "115%",
                        textAlign: "justify",
                        marginBottom: "0.17in",
                        background: "transparent",
                        marginTop: "0.17in"
                    }}
                >
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                        20.
                        <span style={{ textDecoration: "none" }}>
                            <u>
                                <span style={{ fontWeight: "normal" }}>Assignment</span>
                            </u>
                        </span>
                        <span style={{ textDecoration: "none" }}>
                            <span style={{ fontWeight: "normal" }}>
                                . You shall not assign this Agreement without the prior written
                                consent of the Company, which consent the Company shall not withhold
                                unreasonably. Any attempt by you to assign other than in accordance
                                with this provision shall be null and void. This Agreement is binding
                                upon and is for the benefit of the respective successors and assigns
                                of the parties hereto.
                            </span>
                        </span>
                    </span>
                </p>
                <p></p>
            </>

        </div>
    );
}

export default UserAgreement;