import React from 'react';
import ToastContainer from 'react-bootstrap/ToastContainer'
import Toast from 'react-bootstrap/Toast';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { hideNotification } from '../../redux/actions/notificationAction';

const ToastBox = () => {
    const dispatch = useDispatch();
    const notification = useSelector(state => get(state, 'notification.notificationOptions'));
    return (
        <ToastContainer className="d-inline-block m-1" position={'top-end'}>
            <Toast onClose={() => dispatch(hideNotification(false))} show={get(notification, 'show')} bg={get(notification, 'type')} autohide>
                <Toast.Body className={'Dark text-white'}>
                    {get(notification, 'message')}
                </Toast.Body>
            </Toast>
        </ToastContainer>)
}

export default ToastBox;