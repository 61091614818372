export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const DELETED_MESSAGE = "DELETED_MESSAGE";

export const showNotification  = (type, message) =>
{
    return {
    type: SHOW_NOTIFICATION,
        payload: { type, message }
    }
}

export const hideNotification  = () => {
    return {
    type: HIDE_NOTIFICATION
    }
}

export const updateMessage  = () =>
{
    return {
    type: UPDATE_MESSAGE
    }
}

export const deleteMessage  = () => {
    return {
    type: DELETED_MESSAGE
    }
}