import React from 'react';

const Privacy = () => {
    return (
        <div className="container mt5">
            <h1>LLC’s Privacy Policy</h1>
            <>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <br />
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <strong>Introduction</strong>&nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    360STITCH.COM, LLC (“360STITCH.COM, LLC”, “us”, “we”, or “our”) respects
                    your privacy and we are committed to protecting it through our compliance
                    with this Privacy Policy (the “Policy”). For the purposes of this Policy,
                    unless otherwise noted, all references to 360STITCH.COM, LLC, “us”, “we”, or
                    “our”, include (a) https://www.360stitch.com/ and/or (b) our platform in the
                    form of a mobile application (together, the “Site”). By accessing or using
                    the Site, you consent to the data practices described in this Policy.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <br />
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    This Policy describes the type of information we may collect from you or
                    that you may provide when you visit the Site and our practices for
                    collecting, using, maintaining, protecting, and disclosing that information.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <br />
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    This Policy applies to information we may collect:
                </p>
                <ul>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            On this Site;
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            In email, text, and other electronic messages between you and this Site.
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            Through mobile and/or desktop applications you purchase, download,
                            install, register with, access, or use, from this Site, which provide
                            dedicated non-browser-based interaction between you and this Site; and
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            When you interact with our advertising and applications on third-party
                            websites and services, if those applications and advertising include
                            links to this Policy.
                        </p>
                    </li>
                </ul>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <br />
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    This Policy does not apply to information collected by:
                </p>
                <ul>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            Us offline or through any other means, including any other website
                            operated by us or any third party (including our affiliates and
                            subsidiaries); or
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            Any third party (including our affiliates and subsidiaries), including
                            through any application or content (including advertising) that may link
                            to or be accessible from or on the Site.
                        </p>
                    </li>
                </ul>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <br />
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    Please read this Policy carefully to understand our policies and practices
                    regarding your information and how we will treat it. If you do not agree
                    with our policies and practices, your choice is to not use this Site. This
                    Policy may change from time to time as set forth herein. Your continued use
                    of this Site after we make changes is deemed to be acceptance of those
                    changes, so please check this Policy periodically for updates.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <strong>Collection and Use</strong>
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    In order to better provide you with products, services, and subscriptions
                    offered on our Site, we collect several types of information from and about
                    users of our Site (collectively, “Data”), including information:
                </p>
                <ul>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            By which you may be personally identified, such as name, birth date,
                            postal address, e-mail address, telephone number, photos and/or videos,
                            or any other identifier by which you may be contacted online or offline
                            (“Personal Information”);
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            That is about you but individually does not identify you(“Non-Personal
                            Information”);
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            About your internet connection and Internet Protocol address, the
                            equipment you use to access the Site, browser type and/or version, the
                            pages of our Site that you visit, the time and date of your visit, the
                            time spent on those pages, and other diagnostic data (“Usage
                            Information”); and/or
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            If you purchase 360STITCH.COM, LLC’s products, services, and/or
                            subscriptions we collect billing and credit card information to complete
                            the purchase transaction (“Payment Information”).
                        </p>
                    </li>
                </ul>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <em>Information You Provide to Us</em>&nbsp; &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    We do not collect any Personal Information, Non-Personal Information, or
                    Payment Information about you unless you voluntarily provide it to us.
                    However, you may be required to provide certain Personal Information,
                    Non-Personal Information, and/or Payment Information to us when you elect to
                    use certain products or services available on the Site. These may include:
                    (a) registering for an account on our Site; (b) entering a sweepstakes,
                    contest, or promotion sponsored by us or one of our partners; (c) signing up
                    for special offers from selected third parties; (d) records and copies of
                    your correspondence, including email addresses, if you contact us; and (e)
                    submitting your credit card or other Payment Information when ordering and
                    purchasing products, services, or subscriptions on our Site. We also may
                    gather additional Personal or Non-Personal Information in the future.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <br />
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <em>
                        Information Automatically Collected Through Data Collection
                        Technologies&nbsp;
                    </em>
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    We use Cookies and similar tracking technologies to track the activity on
                    our Site and hold certain information. This information is used for the
                    operation of the Site, to maintain the quality of the Site, and to provide
                    general statistics regarding the use of 360STITCH.COM, LLC.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <br />
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    Cookies are files with small amounts of data which may include an anonymous
                    unique identifier. Cookies are sent to your browser from a website and
                    stored on your device. Tracking technologies also use beacons, tags, and
                    scripts to collect and track information and to improve and analyze our
                    Site.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <br />
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    You can instruct your browser to refuse all Cookies or to indicate when a
                    Cookie is being sent. However, if you do not accept Cookies, you may not be
                    able to use some portions of our Site. Unless you have adjusted your browser
                    settings so that it will refuse Cookies, our system will issue Cookies when
                    you direct your browser to our Site.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <br />
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <strong>Use of Your Personal Information&nbsp;</strong>
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    360STITCH.COM, LLC collects and uses your Personal Information to operate
                    the Site and deliver the products, services, and/or subscriptions you have
                    requested. We may use your Personal Information to contact you with
                    newsletters, marketing or promotional materials, and other information that
                    may be of interest to you. You may opt out of receiving any, or all, of
                    these communications from us by following the unsubscribe link or
                    instructions provided in any email we send.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <strong>Use of Data</strong>
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    360STITCH.COM, LLC uses the collected Data for various purposes, including:
                </p>
                <ul>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            To provide and maintain our Site;
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            To notify you about changes to our Site;
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            To allow you to participate in interactive features of our Site when you
                            choose to do so;
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            To provide customer support;
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            To gather analysis or valuable information so that we can improve our
                            Site;
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            To monitor the usage of our Site;
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            To detect, prevent, and address technical issues;
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            To provide you with news, special offers, and general information about
                            other goods, services, and/or Subscriptions which we offer that are
                            similar to those that you have already purchased or inquired about
                            unless you have opted not to receive such information.
                        </p>
                    </li>
                </ul>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <strong>Sharing Data with Third Parties</strong>
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    360STITCH.COM, LLC does not sell, rent, or lease its customer lists to third
                    parties.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    360STITCH.COM, LLC may, from time to time, contact you on behalf of external
                    business partners about a particular offering that may be of interest to
                    you. In those cases, your unique Personal Information (e-mail, name,
                    address, telephone number) is transferred to the third party. 360STITCH.COM,
                    LLC may share Data with trusted partners to help perform statistical
                    analysis, send you email or postal mail, provide customer support, or
                    arrange for deliveries. All such third parties are prohibited from using
                    your Data except to provide these services to 360STITCH.COM, LLC, and they
                    are required to maintain the confidentiality of your Data.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    360STITCH.COM, LLC may disclose your Data, without notice, if required to do
                    so by law or in the good faith belief that such action is necessary to: (a)
                    conform to the edicts of the law or comply with legal process served on
                    360STITCH.COM, LLC or the Site; (b) protect and defend the rights or
                    property of 360STITCH.COM, LLC; and/or (c) act under exigent circumstances
                    to protect the personal safety of users of 360STITCH.COM, LLC, or the
                    public.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <br />
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <strong>Opt-Out of Disclosure of Data to Third Parties</strong>
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    In connection with any Personal Information we may disclose to a third party
                    for a business purpose, you have the right to know the categories of Data
                    that we disclosed about you for a business purpose.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    You have the right under the California Consumer Privacy Act of 2018 (CCPA)
                    and certain other privacy and data protection laws, as applicable, to
                    opt-out of the disclosure of your Data. If you exercise your right to
                    opt-out of the disclosure of your Data, we will refrain from disclosing your
                    Data, unless you subsequently provide express authorization for the
                    disclosure of your Data. To opt-out of the disclosure of your Data, visit
                    the Site or email us at the email address listed under the section below
                    titled “Contact Information”.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <strong>Tracking User Behavior</strong>
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    360STITCH.COM, LLC may keep track of the websites and pages our users visit
                    within the Site in order to determine what 360STITCH.COM, LLC services are
                    the most popular. This data is used to deliver customized content and
                    advertising within 360STITCH.COM, LLC to customers whose behavior indicates
                    that they are interested in a particular subject area.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <strong>Links</strong>
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    This Site may contain links to other sites. Please be aware that we are not
                    responsible for the content or privacy practices of such other sites. We
                    encourage our users to be aware when they leave our Site and to read the
                    privacy statements of any other site that collects personally identifiable
                    information.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <br />
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    We have no control and assume no responsibility for the content, privacy
                    policies, or practices of any third party sites or services.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <strong>Security of your Personal Information</strong>
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    360STITCH.COM, LLC secures your Data from unauthorized access, use, or
                    disclosure. 360STITCH.COM, LLC uses the Secure Sockets Layer Protocol (“SSL
                    Protocol”) for this purpose. When Data, such as Payment Information, is
                    transmitted to other websites, it is protected through the use of
                    encryption, such as the SSL Protocol.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    We strive to take appropriate security measures to protect against
                    unauthorized access to or alteration of your Data. Unfortunately, no data
                    transmission over the Internet or any wireless network can be guaranteed to
                    be 100% secure. As a result, while we strive to protect your Data, you
                    acknowledge that: (a) there are security and privacy limitations inherent to
                    the Internet which are beyond our control; and (b) security, integrity, and
                    privacy of any and all information and Data exchanged between you and us
                    through this Site cannot be guaranteed.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <br />
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <strong>Service Providers</strong>
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    We may employ or contract with third party companies and individuals to
                    facilitate our Site, to provide the Site on our behalf, to perform
                    Site-related-services, or to assist us in analyzing how our Site is used
                    (“Service Providers”).
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <br />
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    These Service Providers have access to your Data only to perform these tasks
                    on our behalf and are obligated not to disclose or use it for any other
                    purpose.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <strong>Right to Deletion</strong>
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    Subject to certain exceptions set out below, on receipt of a verifiable
                    request from you, we will:
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginLeft: "0.5in",
                        textIndent: "-0.25in",
                        marginBottom: "0in"
                    }}
                >
                    •Delete your Data from our records; and
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginLeft: "0.5in",
                        textIndent: "-0.25in",
                        marginBottom: "0in"
                    }}
                >
                    •Direct any Service Providers to delete your Data from their records.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    Please note that we may not be able to comply with requests to delete your
                    Data if it is necessary to:
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginLeft: "0.5in",
                        textIndent: "-0.25in",
                        marginBottom: "0in"
                    }}
                >
                    •Complete the transaction for which the Data was collected, fulfill the
                    terms of a written warranty or product recall conducted in accordance with
                    federal law, provide a good or service requested by you or reasonably
                    anticipated within the context of our ongoing business relationship with
                    you, or otherwise perform a contract between you and us;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginLeft: "0.5in",
                        textIndent: "-0.25in",
                        marginBottom: "0in"
                    }}
                >
                    •Detect security incidents, protect against malicious, deceptive,
                    fraudulent, or illegal activity, or prosecute those responsible for that
                    activity;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginLeft: "0.5in",
                        textIndent: "-0.25in",
                        marginBottom: "0in"
                    }}
                >
                    •Debug to identify and repair errors that impair existing intended
                    functionality;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginLeft: "0.5in",
                        textIndent: "-0.25in",
                        marginBottom: "0in"
                    }}
                >
                    •Exercise free speech, ensure the right of another consumer to exercise his
                    or her right of free speech, or exercise another right provided for by law;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginLeft: "0.5in",
                        textIndent: "-0.25in",
                        marginBottom: "0in"
                    }}
                >
                    •Comply with the California Electronic Communications Privacy Act;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginLeft: "0.5in",
                        textIndent: "-0.25in",
                        marginBottom: "0in"
                    }}
                >
                    •Engage in public or peer-reviewed scientific, historical, or statistical
                    research in the public interest that adheres to all other applicable ethics
                    and privacy laws, when our deletion of the information is likely to render
                    impossible or seriously impair the achievement of such research, provided we
                    have obtained your informed consent;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginLeft: "0.5in",
                        textIndent: "-0.25in",
                        marginBottom: "0in"
                    }}
                >
                    •Enable solely internal uses that are reasonably aligned with your
                    expectations based on your relationship with us;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginLeft: "0.5in",
                        textIndent: "-0.25in",
                        marginBottom: "0in"
                    }}
                >
                    •Comply with an existing legal obligation; or
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginLeft: "0.5in",
                        textIndent: "-0.25in",
                        marginBottom: "0in"
                    }}
                >
                    •Otherwise use your Data, internally, in a lawful manner that is compatible
                    with the context in which you provided the information.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <br />
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <strong>
                        Data Protection Rights Under General Data Protection Regulation (GDPR)
                    </strong>
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    If you are a resident of the European Economic Area (“EEA”), you have
                    certain data protection rights. 360STITCH.COM, LLC aims to take reasonable
                    steps to allow you to correct, amend, delete, or limit the use of your Data.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <br />
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    If you wish to be informed what Data we hold about you and if you want it to
                    be removed from our systems, please contact us.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <br />
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    In certain circumstances, you have the following data protection rights:
                </p>
                <ul>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            To access, update, or to delete the information we have on you;
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            To have your information rectified if that information is inaccurate or
                            incomplete;
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            To object to our processing of your Data;
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            To request that we restrict the processing of your Data;
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            To be provided with a copy of the information we have on you; and
                        </p>
                    </li>
                    <li>
                        <p
                            style={{
                                textAlign: "justify",
                                background: "transparent",
                                lineHeight: "100%",
                                marginBottom: "0in"
                            }}
                        >
                            To withdraw consent at anytime where we relied on your consent to
                            process your Data.
                        </p>
                    </li>
                </ul>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <strong>Children Under Thirteen</strong>
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    360STITCH.COM, LLC does not knowingly collect Data from children under the
                    age of thirteen. If you are under the age of thirteen, you must ask your
                    parent or guardian for permission to use this Site.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <strong>Opt-Out &amp; Unsubscribe from Third Party Communications</strong>
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    We respect your privacy and give you an opportunity to opt-out of receiving
                    announcements of certain information. Users may opt-out of receiving any or
                    all communications from third-party partners of 360STITCH.COM, LLC by
                    contacting us through the Site or by emailing us at the email address listed
                    under the section below titled “Contact Information”.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <strong>E-mail Communications</strong>
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    From time to time, 360STITCH.COM, LLC may contact you via email for the
                    purpose of providing announcements, promotional offers, alerts,
                    confirmations, surveys, and/or other general communication. In order to
                    improve our Site and/or services, we may receive a notification when you
                    open an email from 360STITCH.COM, LLC or click on a link therein.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    If you would like to stop receiving marketing or promotional communications
                    via email from 360STITCH.COM, LLC, you may opt out of such communications by
                    sending a request to the email address listed under the section below titled
                    “Contact Information”.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <strong>External Data Storage Sites</strong>
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    We may store your Data on servers provided by third party hosting vendors
                    with whom we have contracted.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <strong>Changes to this Statement</strong>
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    360STITCH.COM, LLC reserves the right to change this Privacy Policy from
                    time to time. We will notify you about significant changes in the way we
                    treat Data by sending a notice to the primary email address specified in
                    your account, by placing a prominent notice on our Site, and/or by updating
                    any privacy information within this Policy. Your continued use of the Site
                    and/or services available through this Site after such modifications will
                    constitute your: (a) acknowledgment of the modified Privacy Policy; and (b)
                    agreement to abide and be bound by that Policy.
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <strong>Contact Information</strong>
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    360STITCH.COM, LLC welcomes your questions or comments regarding this
                    Privacy Policy. If you believe that 360STITCH.COM, LLC has not adhered to
                    this Policy, please contact us at:
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    360STITCH.COM, LLC
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    <br />
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    Mailing Address: 360Stitch LLC, 1332 Naamans Creek Road, Garnet Valley, PA 609-435-0430
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    Email Address:
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    support@360stitch.com
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    Telephone number:
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    609-435-0430
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    Effective as of September 22, 2023
                </p>
                <p
                    style={{
                        textAlign: "justify",
                        background: "transparent",
                        fontSize: 16,
                        lineHeight: "100%",
                        marginBottom: "0in"
                    }}
                >
                    &nbsp;
                </p>
                <p></p>
            </>

        </div>
    );
}

export default Privacy;