import React, { useState, useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { doLoginUser, isUserExist } from '../redux/actions/authAction';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const dispatch = useDispatch();
    const [error, setError] = useState({});

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();
    const _isUserExist = isUserExist();
    useEffect(() => {
        if (_isUserExist)
            navigate("/tours")
    }, [])

    const validateForm = () => {
        const _error = {};

        if (isEmpty(email)) {
            _error["email"] = "This field is required";
        }
        else if (email.indexOf('@') < 0) {
            _error["email"] = "Valid email is required";
        }

        if (isEmpty(password)) {
            _error["password"] = "This field is required";
        }
        else if (password.length < 6) {
            _error["password"] = "Password (6 characters minimum)";
        }

        setError(_error);
        if (!isEmpty(_error)) {
            return false;
        }
        return true;
    };

    const login = () => {
        if (validateForm()) {
            const loginForm = {
                email: email,
                password: password
            }
            dispatch(doLoginUser({ ...loginForm }));
        }
    };

    return (
        <div className="container">
            <div className="account-pages my-5 pt-sm-5">
                <div className="row justify-content-center">
                    <div className="col-md-6 col-lg-6 col-xl-3">
                        <div className="login_heading">Login</div>
                        <input type="email" className="form-control rounded-bordered" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        {get(error, 'email') && <span className='requiredMsg'>{get(error, 'email')}</span>}
                        <br />
                        <input type="password" className="form-control rounded-bordered" placeholder="Password" minLength="6" value={password} onChange={(e) => setPassword(e.target.value)} />
                        {get(error, 'password') && <span className='requiredMsg'>{get(error, 'password')}</span>}
                        <br />
                        <button className="btn btn-primary btn-block btn-rounded mt-4" onClick={login}>Login</button>
                        <br />
                        <div className="text-center or_1">OR</div>
                        <br />
                        <div className="new_acc"><NavLink to="/signup">Create a new account</NavLink></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;