import React from 'react';
import { useLocation } from 'react-router-dom';

const Footer = () => {
    const location = useLocation();
    if (location.pathname == '/' || location.pathname == '/privacy' || location.pathname == '/cookies' || location.pathname == '/copyright' || location.pathname == '/terms' || location.pathname == '/useragreement' || location.pathname == '/services' || location.pathname == '/pricing' || location.pathname.indexOf('/admin/') > -1) {
        return (
            <footer className="landing-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 text-left">
                            <a href="/privacy" className="text-light">Privacy Policy</a><br />
                            <a href="/terms" className="text-light">Terms of Use</a><br />
                            <a href="/cookies" className="text-light">Cookies Policy</a>
                        </div>
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-3 text-left">
                            <a href="/copyright" className="text-light">Copyright DMCA Policy</a><br />
                            <a href="/useragreement" className="text-light">End User Agreement</a>
                        </div>
                        <div className="col-lg-12 mt-5 text-center">
                            <p className="mb-0">
                                © 2023 All rights reserved <a href="/" className="text-primary">360Stitch</a>
                            </p>
                        </div>
                    </div>
                </div>
            </footer >
        );
    }
    else
        return <></>
}

export default Footer;