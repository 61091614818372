import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import { services } from '../utils/services';
import { showLoader } from '../redux/actions/homeAction';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const TourExterior = (props) => {
    const dispatch = useDispatch();
    const params = useParams();
    const [error, setError] = useState({});
    const [addnewexterior, setAddNewExterior] = useState(false);
    const [addnewexteriorLocal, setAddNewExteriorLocal] = useState(false);
    const [addnewexteriorCloud, setAddNewExteriorCloud] = useState(false);
    const [areaname, setAreaName] = useState("");
    const [addExteriorText, setAddExteriorText] = useState("Add New Exterior Image");
    const [areaimage, setAreaImage] = useState("");
    const [exteriorID, setExteriorID] = useState(0);
    const [cloudimages, setCloudImages] = useState([]);
    const addExterior = () => {
        setAddNewExterior(true)
        setAreaName("")
        setAddExteriorText("Add New Exterior Image")
        setAreaImage("");
        setExteriorID(0)
        setError({})
    }
    const onChangeImage = (e, name) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            let inputData = reader.result;
            let replaceValue = inputData.split(",")[0];
            let base64String = inputData.replace(replaceValue + ".", "");
            setAreaImage(base64String);
        }
    }
    const onHide = () => {
        setAddNewExterior(false)
    }
    const onHideLocal = () => {
        setAddNewExteriorLocal(false)
    }
    const onHideCloud = () => {
        setAddNewExteriorCloud(false)
    }
    const validateForm = () => {
        const _error = {};

        if (isEmpty(areaname)) {
            _error["areaname"] = "This field is required";
        }
        if (isEmpty(areaimage)) {
            _error["areaimage"] = "This field is required";
        }

        setError(_error);
        if (!isEmpty(_error)) {
            return false;
        }
        return true;
    }
    const saveExterior = async () => {
        dispatch(showLoader(true))
        const exteriorForm = {
            areaName: areaname,
            areaImage: areaimage,
            tourID: parseInt(params.slug),
            iD: exteriorID
        }
        const response = await services.addExterior(exteriorForm);
        if (response) {
            setAddNewExteriorCloud(false)
            setAddNewExteriorLocal(false)
            setAreaName('')
            setAreaImage('')
            dispatch(showLoader(false))
            props.parentCallback(response, exteriorID == 0 ? 'add' : 'update');
        }
    }
    const onSubmitHandler = () => {
        if (validateForm()) {
            saveExterior()
        }
    }
    const onSubmitHandlerLocal = () => {
        setAddNewExterior(false)
        setAddNewExteriorCloud(false)
        setAddNewExteriorLocal(true)
    }
    const onSubmitHandlerCloud = () => {
        dispatch(showLoader(true))
        services.getImages().then(result => {
            if (result != undefined) {
                setCloudImages(result)
            }
            dispatch(showLoader(false))
        });
        setAddNewExterior(false)
        setAddNewExteriorLocal(false)
        setAddNewExteriorCloud(true)
    }
    const deleteExterior = (exterior) => {
        Swal.fire({
            title: 'Are you sure?',
            showCancelButton: true,
            confirmButtonColor: 'red',
            confirmButtonText: 'Delete',
            width: 300,
        }).then(async (result) => {
            if (result.isConfirmed) {
                dispatch(showLoader(true))
                const response = await services.deleteExterior(exterior.id);
                if (response) {
                    dispatch(showLoader(false))
                    props.parentCallback(exterior, 'delete');
                }
            }
        })
    }
    const editExterior = (exterior) => {
        if (exterior.areaImage.indexOf('http') == 0)
            onSubmitHandlerCloud()
        else
            onSubmitHandlerLocal()
        setAreaName(exterior.areaName)
        setAddExteriorText("Update Exterior Image")
        setAreaImage(exterior.areaImage);
        setExteriorID(exterior.id)
    }
    const setAreaImageCloud = (index, image) => {
        setAreaImage(image)
        setTimeout(() => {
            var elements = document.getElementsByClassName("custom_img_cld_v1");
            for (let i = 0; i < elements.length; i++) {
                elements[i].setAttribute("class", "custom_img_v1 col-md-3 custom_img_cld_v1");
            }
            if (document.getElementById("svi" + index) != undefined)
                document.getElementById("svi" + index).setAttribute("class", "custom_img_v1 col-md-3 custom_img_cld_v1 active");
        }, 100)
    }
    return (
        <>
            {props.exterior == null && <div id="sv0" className="custom_img_v_new" onClick={() => addExterior()}><span className="custom_img_v_span_new">{addExteriorText}</span></div>}
            {props.exterior != null && <span className="custom_img_border_span_3">{get(props.exterior, 'areaName')}<i className="st-trash st-trash-white st-pencil-trash" aria-hidden="true" onClick={() => deleteExterior(props.exterior)}></i><i className="st-pencil st-pencil-white st-pencil-trash" aria-hidden="true" onClick={() => editExterior(props.exterior)}></i></span>}
            <Modal show={addnewexterior} onHide={onHide}>
                <Modal.Body>
                    <h5 className="modal-title">Select Image</h5>
                    <div className="mdl-body">
                        <button onClick={() => onSubmitHandlerLocal()} className="btn btn-primary btn-rounded btn-block">From my Computer</button>
                        <button onClick={() => onSubmitHandlerCloud()} className="btn btn-primary btn-rounded btn-block">From my Cloud</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={addnewexteriorLocal} onHide={onHideLocal}>
                <Modal.Body>
                    <h5 className="modal-title">{addExteriorText}</h5>
                    <div className="mdl-body">
                        <input type="text" maxLength="100" className="form-control rounded-bordered mb-4" placeholder="Area Name" value={areaname}
                            onChange={(e) => setAreaName(e.target.value)} />
                        {
                            get(error, 'areaname') && isEmpty(areaname) &&
                            <span className='requiredMsg'>
                                {get(error, 'areaname')}
                            </span>

                        }
                        <div className="input-group custom-file-button  mb-3">

                            <input type="file" className="form-control rounded-bordered mb-4" type="file" accept="image/png, image/gif, image/jpeg" placeholder="Select Image"
                                onChange={e => onChangeImage(e, "picture")} />
                            {
                                get(error, 'areaimage') && isEmpty(areaimage) &&
                                <span className='requiredMsg'>
                                    {get(error, 'areaimage')}
                                </span>

                            }
                        </div>
                        <button onClick={() => onSubmitHandler()} className="btn btn-primary btn-rounded btn-block">{addExteriorText}</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={addnewexteriorCloud} onHide={onHideCloud}>
                <Modal.Body>
                    <h5 className="modal-title">{addExteriorText}</h5>
                    <div className="mdl-body">
                        <input type="text" maxLength="100" className="form-control rounded-bordered mb-4" placeholder="Area Name" value={areaname}
                            onChange={(e) => setAreaName(e.target.value)} />
                        {
                            get(error, 'areaname') && isEmpty(areaname) &&
                            <span className='requiredMsg'>
                                {get(error, 'areaname')}
                            </span>

                        }
                        <strong style={{ 'margin': '5px' }}>Select Image</strong>
                        <div className="row" style={{ textAlign: 'center', display: 'block', height: '350px', overflowX: 'hidden', marginTop: '10px' }}>
                            {
                                get(error, 'areaimage') && isEmpty(areaimage) &&
                                <span className='requiredMsg'>
                                    {get(error, 'areaimage')}
                                </span>

                            }
                            {
                                !isEmpty(cloudimages) &&
                                cloudimages.map((image, index) => {
                                    return <div key={index} id={"svi" + index} onClick={() => setAreaImageCloud(index, image.replace('thumb.png', '.png').replace('thumb.jpeg', '.jpeg'))} className="custom_img_v1 col-md-3 custom_img_cld_v1" style={{ padding: '0' }}><img src={image} style={{ width: 'inherit', height: 'inherit' }} /></div>
                                })
                            }
                        </div>
                        <button onClick={() => onSubmitHandler()} className="btn btn-primary btn-rounded btn-block">{addExteriorText}</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default TourExterior;