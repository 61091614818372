import { combineReducers } from 'redux';
import {
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION,
} from '../actions/notificationAction';
import get from 'lodash/get';

const initialState = {
    show: false,
    type: "success",
    message: ""
}

const notificationOptions = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_NOTIFICATION:
            return { ...state, show: true, type: get(action, 'payload.type', 'success'), message: get(action, 'payload.message', 'success') };
        case HIDE_NOTIFICATION:
            return { ...state, show: false, type: "", message: "" };
        default:
            return state;
    }
}


export default combineReducers({
    notificationOptions
});